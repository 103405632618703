import vkQr from '@vkontakte/vk-qr';
import { domain } from "config.json";

export const QR = (text) => {
  return vkQr.createQR(text, {
    qrSize: 250,
    isShowLogo: true,
    foregroundColor: 'currentColor',
    // TODO: иконка авторизации проверки
    logoData: `${domain}/preview.png?size=192&color=ffffff&src=https%3A%2F%2Fjusp.io%2Flogo-dark.svg&dev=true&mac=true`,
  })
}