import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import styles from './InputSimple.css';
import classNames from 'classnames';
import {handleKey} from "@/helpers/hooks";

const letters = e => {
  const isAnyFocused = (
    document.hasFocus() &&
    document.activeElement !== null &&
    document.activeElement !== document.body &&
    document.activeElement !== document.documentElement
  );

  if (isAnyFocused) return;

  return !(e.ctrlKey || e.metaKey) && e.keyCode >= 48 && e.keyCode <= 90;
}

export const InputSimple = forwardRef(({ tag: Tag = 'div', value, className, placeholder: placeholderProps = 'Заголовок', name, autoFocus, autoFocusAlways, onFocus, onBlur, disable, onChange, onEnter, ...other }, ref) => {
  const inputRef = ref || useRef<HTMLDivElement>(null);
  const focus = () => {}

  useEffect(() => {
    const input = inputRef.current || {};

    if (input.innerText !== value) input.innerText = value || '';

    input.contentEditable = !disable;

    const focus = () => {
      const range = document.createRange();
      range.selectNodeContents(input);
      range.collapse(false);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }

    input.focus = focus;

    if (autoFocus && !disable && document.activeElement !== input) {
      focus();
    }

    if (autoFocusAlways)
      return handleKey(letters, focus)();
  }, [value, disable, autoFocus, autoFocusAlways]);

  return (
    <Tag className={classNames(styles.wrapper, disable && styles.wrapperDisabled, className)}>
      <div
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
        onInput={e => onChange(e.target.innerText)}
        onFocus={onFocus}
        onBlur={onBlur}
        className={styles.input}
        ref={inputRef}
        data-name={name}
        style={{ '--placeholder': `"${placeholderProps}"` }}
        children={useMemo(() => value || '', [])}
        {...other}
      />
    </Tag>
  );
})
