import { useEffect, useRef, useState } from 'react';

const calls = new Map();

// with translate
const ro = new ResizeObserver((entries) => {
  for (const entry of Array.from(entries)) {
    const func = calls.get(entry.target)

    if (func) func();
  }
});

export const useMeasure = (node?) => {
  const ref = !node ? useRef() : node;

  const [{ ...bounds }, set] = useState({ left: 0, top: 0, width: null, height: null });

  useEffect(() => {
    if (!ref.current) return;
    const reset = () => {
      const { width = 1, height = 1, top = 0, left = 0 } = ref.current?.getBoundingClientRect() || {};
      set({ width, height, top, left });
    }

    calls.set(ref.current, reset);
    ro.observe(ref.current);

    reset();

    return () => {
      if (!ref.current) return;
      ro.unobserve(ref.current);
      calls.delete(ref.current);
    }
  }, [ref.current, set])

  return { ref, bounds }
}
