import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import { Helmet } from '@/modules/Helmet';
import styles from './AuthPage.css';
import { Link } from 'react-router-dom';
import { useSelector } from '@/react-redux';
import { selectAuth } from '@/store/selectors';
import { useLocation, useNavigate } from 'react-router';
import Yup from '@/lib/Validation';
import { Input } from '@/ui/Input';
import {InputPhone, phoneValidation} from '@/ui/Input/InputPhone';
import { useForm } from '@/hooks/useForm';
import {Button, mode, size} from '@/ui/Button';
import { groupType } from '@/data/groups';
import { onSend, userId, useSocket } from '@/hooks/useSocket';
import { ErrorTypeEnum } from '@/types';
import {store} from "@/store/client";
import {setItem} from "@/store/app/actions";
import {pickBy} from "@/helpers/dash";
import {QR} from "@/lib/Qr";
import {routesConfig} from "@/data/routes";
import {isMobile} from "@/components/Actions/Actions";
import {Timer} from "@/components/Timer/Timer";
import {addMinutes} from "date-fns";
import {v4 as genId} from "uuid";
import {Emoji} from "@/modules/Thumb/Thumb";

const initialValue = {
  title: '',
  phone: '7',
  password: '',
};

const validation = {
  title: Yup.string().min(3, 'Имя должно быть больше 3 символов').required(),
  phone: phoneValidation.required(),
  password: Yup.string()
    .min(6, 'Пароль должен быть больше 6 символов')
    .required(),
};

const Verification = ({ value, onStart }) => {
  const { phone , end  } = value || {};
  const link = `tel:${phone}`;
  const svg = QR(link);

  const handle = () => {
    if (isMobile)
      window.open(link)
  }

  return (
    <Timer key={end} end={end} format='s'>
      {(time) => {
        if (time == '0') onStart();

        return (
          <>
            <p className={styles.p}>{isMobile ? 'Кликните на QR-код' : 'Отсканируйте QR-код камерой телефона'} для подтверждения телефона</p>
            <div className={styles.qr} onClick={handle} dangerouslySetInnerHTML={{__html: svg}}/>
            <p className={styles.p}>Ожидаем звонка в течение {time}  сек. Звонок бесплатный и сбросится автоматически</p>
          </>
        );
      }}
    </Timer>
  )
}

const controls = {
  title: { component: Input, props: { placeholder: 'Имя' } },
  phone: { component: InputPhone, props: { placeholder: 'Телефон' } },
  password: { component: Input, props: { placeholder: 'Пароль', type: 'password' } },
  verification: { component: Verification }
};

const steps = {
  login: ['phone', 'password'],
  verification: ['verification'],
}

const titles = {
  login: <Emoji emoji='👋' autoplay />,
}

const btns = {
  login: 'Далее',
  verification: '',
}

const onSubmit = (step) => values => {
  let { phone, password } = values;

  const id = userId || genId();

  const user = { id, password, phone, $create: true };
  const project = { id, $create: true };
  const tag = { id, type: groupType.user, title: 'Новый пользователь', $create: true };

  const actions = {
    user: { [user.id]: user },
    project: { [project.id]: project },
    tag: { [tag.id]: tag },
  };

  const auth = { phone, password };

  // TODO: вынести в отдельную функцию
  onSend([step === 'registration' ? { actions } : { auth }])
};

export const AuthPage: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const id = useSelector(selectAuth);

  const [step, setStep] = useState('login');

  useEffect(() => {
    const hash = location.hash?.slice(1);

    if (hash[0] === 't') {
      store.dispatch(setItem(['token'], hash.slice(1)));
      window.location.replace('/');
    }

    if (id) history(hash?.length ? hash : `/`);

  }, [id]);

  const { title, contr, btn, valid } = useMemo(() => ({
    title: titles[step],
    contr: pickBy(controls, steps[step]),
    btn: btns[step],
    valid: Yup.object().shape(pickBy(validation, steps[step])),
  }), [step]);

  const { Control, dispatch, handleSubmit, getValues } = useForm({ initialValue, validation: valid });

  useSocket({
    onUpdate: (item) => {
      if (item.errors?.auth?.type === ErrorTypeEnum.userEmpty)
        onSubmit('registration')(getValues());

      if (item?.body?.verification) {
        dispatch(prev => ({ ...prev, ...item.body }));
        setStep('verification');
      }
    }
  })

  return (
    <div className={styles.root}>
      <Helmet title='Авторизация' />
      <div className={styles.header} children={title} />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit(step))}>
        {Object.keys(contr).map(name => (
          <Control key={name} name={name} props={{ onStart: () => setStep('login') }} {...controls[name]} />
        ))}
        {btn && (
          <>
            <Button isFull children={btn} />
            <Button
              mode={mode.outline}
              onClick={handleSubmit(
                ({ phone }) => {
                  onSend([{ remember: { phone } }]);
                },
                null,
                Yup.object().shape({ phone: phoneValidation.required() })
              )}
              isFull
              children="Забыл пароль"
            />
          </>
        )}
      </form>
      {step === 'verification' ? null : (
        <div className={styles.footer}>
          <p children={[
            'Нажимая кнопку «Далее», вы даете согласие на ',
            <Link to="/project/f537d37a-8d32-4df4-be4f-f1210a911d06#4a9348c0-d860-4fac-abf6-54f857c10654">обработку
              персональных данных</Link>, ' и ',
            <Link to="/project/f537d37a-8d32-4df4-be4f-f1210a911d06#22fee38a-83bf-4eb9-94f2-9853f649c532">соглашаетесь с
              договором-офертой</Link>, ' и ',
            <Link to="/project/f537d37a-8d32-4df4-be4f-f1210a911d06#22fee38a-83bf-4eb9-94f2-9853f649c532">пользовательским
              соглашением</Link>,
          ]}/>
        </div>
      )}
    </div>
  )
};