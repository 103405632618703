import React, { forwardRef, useEffect, useRef } from 'react';
import { useDispatch } from '@/react-redux';
import { setItem } from '@/store/app/actions';
import styles from './Board.css';
import { pathStore } from '@/store/client';
import classNames from 'classnames';
import {useBoard} from "@/modules/Board/BoardContext";
import { actions } from '@/hooks/actions';
import { sendMessage } from '@/components/Layout/LayoutMessages';
import { setSelection } from '@/components/TaskCard/TaskCardWrapper';
import { getPos } from '@/hooks/useLongPress';
import {AutoSize} from "@/lib/Autosizer";

const pathSelection = ['app', 'selection'];
let move = false;

export const BoardWrapper: React.FC = forwardRef(({ className, style, children }, re) => {
  const ref = re || useRef();
  const { id, filters } = useBoard(['id', 'filters']);
  const dispatch = useDispatch();
  const pos = useRef();

  useEffect(() => {
    const acts = {
      mousedown: (e) => {
        pos.current = getPos(e);
        move = false;
      },
      mousemove: (e) => {
        if (!pos.current) return;
        move = Object.entries(getPos(e)).some(([key, value]) => Math.abs(pos.current[key] - value) > 5);;
      },
      mouseup: () => {
        if (!move && pathStore(pathSelection, []).length) {
          dispatch(setItem(pathSelection, []))
        }
      },
      touchstart: (e) => acts.mousedown(e),
      touchmove: (e) => acts.mousemove(e),
      touchend: (e) => acts.mouseup(e),
      copy: () => {
        const entities = pathStore(pathSelection, [])
          .map(id => pathStore(['entity', id], {}))

        if (!entities.length) return;

        navigator.clipboard.writeText(JSON.stringify({ id, entities }));
        sendMessage({ text: 'Скопировано' });
      },
      paste: (e) => {
        // COPYPASTE
        try {
          const { id: from, entities } = JSON.parse(
            (e.clipboardData || window.clipboardData)
              .getData('Text')
          );

          const tags = Object.entries(filters)
            .map(([group, tags]) => Object.keys(tags).map(id => ({ group, id })))
            .flat();

          const next = entities.map(({ entities, text, type }) => ({
            entities: entities.map(item => item.id === from ? { id } : item).concat(tags),
            text,
            type
          }))

          const items = actions.entities.create(...next);
          setSelection(Object.keys(items))
        } catch (e) {
          console.log(e);
        }
      }
    }

    Object.entries(acts)
      .forEach(args => document.addEventListener(...args))

    return () => {
      Object.entries(acts)
        .forEach(args => document.removeEventListener(...args))
    };
  }, [id, filters]);

  // const handlres = useSwipeable({
  //   onSwipeStart: () => { is = !ref.current?.scrollTop },
  //   onSwipedDown: ( ) => { if (is) onClick() },
  //   ...swipe
  // })

  return (
    <AutoSize
      key={id}
      style={style}
      className={classNames(styles.root, className)}
      data-name='board'
      ref={ref}
      children={children}
    />
  );
})