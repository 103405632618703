import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { messageType, sendMessage } from '@/components/Layout/LayoutMessages';
import {pickBy, setTo} from '@/helpers/dash';
import genStore, {shallowEqual} from "@/react-redux";
import {getStateByStep} from "@/pages/MainPage/data";

const def = {};
const defLog = () => {}

export const useForm = ({ initialValue = def, validation, log = defLog } = {}) => {
  const ref = useRef<any>({
    initialValue: { ...initialValue },
    ...genStore(initialValue || {}),
  });

  ref.current.validation = validation;

  const handleSubmit = useCallback((submit, update, validate) => async e => {
    e?.preventDefault();
    const { getState, initialValue, validation } = ref.current;
    const values = getState();

    log('initialValue', initialValue)

    try {
      if (validate || validation)
        await (validate || validation).validate(values);

      if (submit) submit(values);
      if (update) {
        const next = {};

        for (const key in values) {
          if (values[key] !== initialValue[key])
            next[key] = values[key];
        }

        if (Object.keys(next).length)
          update(next);
      }
    } catch (err) {
      sendMessage({ text: err.message, type: messageType.error })
      console.log(err);
    }
  }, []);

  const args = useMemo(() => ({
    Control: (args) => <Control ref={ref} {...args} />,
    getValues: ref.current.getState,
    dispatch: ref.current.dispatch,
    reset: (initialValue) => {
      log('initialValue reset', initialValue)
      ref.current.initialValue = initialValue;
      ref.current.dispatch(() => initialValue);
    },
  }), []);

  useEffect(() => args.reset(initialValue), [initialValue]);

  return { ...args, handleSubmit };
}

const Control = forwardRef(({ component, props, name, proxy }, ref) => {
  const selector = useCallback((values) => {
    if (!values) values = {};
    if (proxy) return pickBy(values, proxy);
    return { value: values[name] };
  }, [name, proxy]);

  const { getState, selector: effect, dispatch } = ref.current;

  const [args, setArgs] = useState(selector(getState()));

  useEffect(() => {
    const set = () => setArgs(prev => {
      const next = selector(getState());

      return shallowEqual(prev, next) ? prev : next;
    })

    set();

    return effect(set);
  }, []);

  return React.createElement(component, {
    ...props,
    key: name,
    name,
    onChange: next => dispatch((prev) => ({ ...prev, [name]: next })),
    ...args,
  })
})
