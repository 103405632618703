// Routes

// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */

// We're using `react-router-dom` to handle routing, so grab the `RouteProps`
// type that we'll use to ensure our own types conform to the expected configuration
import { matchPath, RouteProps } from 'react-router-dom';

/* Local */

// Components

// By default, pull in the ReactQL example. In your own project, just nix
// the `src/components/example` folder and replace the following line with
// your own React components
import { IndexPage } from '@/pages/IndexPage';
import { AuthPage } from '@/pages/AuthPage/AuthPage';
import { InvitePage } from '@/pages/InvitePage/InvitePage';
import { Board } from '@/pages/BoardPage';
import { Empty } from '@/pages/EmptyPage/Empty';
import { useLocation, useParams } from 'react-router';
import { PreviewPage } from '@/pages/PreviewPage/PreviewPage';
import { AppPage } from '@/pages/AppPage/AppPage';

// ----------------------------------------------------------------------------

// Specify the routes. This is provided as an array of `RouteProp`, which is
// a type provided by `react-router-dom` for rendering a route. Typically, this
// will contain at least a component and a path
export const routesConfig = {
  main: {
    Component: IndexPage, // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: '/', // <-- ... and this is the actual path to match on
  },
  signup: {
    Component: AuthPage, // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: '/auth', // <-- ... and this is the actual path to match on
  },
  preview: {
    Component: PreviewPage, // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: '/preview', // <-- ... and this is the actual path to match on
  },
  invite: {
    Component: InvitePage, // <-- this is the component that'll be rendered
    exact: true, // <-- this says to ONLY match when the path is exactly '/'
    path: '/invite/:invite', // <-- ... and this is the actual path to match on
  },
  app: {
    Component: AppPage,
    exact: true,
    path: `/app/:id`
  },
  boardGroup: {
    Component: Board,
    exact: true,
    path: `/:type/:id/:group`
  },
  board: {
    Component: Board,
    exact: true,
    path: `/:type/:id/`
  },
  empty: {
    Component: Empty,
    path: '*',
  }
};

const routes: RouteProps[] = Object.values(routesConfig);
export default routes;
