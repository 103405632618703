import React from 'react';
import User from '@/ui/icons/user.svg';
import Project from '@/ui/icons/project.svg';
import Calendar from '@/ui/icons/calendar.svg';
import Invite from '@/ui/icons/link.svg';
import Tag from '@/ui/icons/tag.svg';
import Pin from '@/ui/icons/pin.svg';
import IconNumb from '@/ui/icons/numb.svg';
import { getDay } from 'date-fns';

export enum groupType {
  project = 'project',
  tag = 'tag',
  user = 'user',
  date = 'date',
  numb = 'numb',
  location = 'location',
}

const style = {
  position: 'absolute',
  fontSize: '.4em',
  margin: '0.4em 0 0.2em',
  marginBottom: '0.2em',
  fontWeight: 'bold',
  width: '1.9em',
  textAlign: 'center',
}

export const groups = {
  [groupType.tag]: {
    icon: Tag,
    name: 'Тег',
  },
  [groupType.user]: {
    icon: User,
    name: 'Пользователи',
  },
  [groupType.project]: {
    icon: Project,
    name: 'Проекты',
  },
  invite: {
    icon: Invite,
  },
  [groupType.numb]: {
    icon: IconNumb,
    name: 'Числа',
  },
  [groupType.location]: {
    icon: Pin,
    name: 'Геолокация',
  },
  [groupType.date]: {
    icon: props => <Calendar {...props} />,
    name: 'Даты',
  },
};