import * as React from 'react';
import { Error } from '@/components/Error';
import styles from './InputWrapper.css';
import classNames from 'classnames';

interface IInputWrapperProps {
  label?: string;
  error?: string;
  className?: string;
  children?: any;
  hidden?: boolean;
}

export const InputWrapper: React.FC<IInputWrapperProps> = ({ error, label, hidden, children, className }) => (
  <label className={classNames(styles.wrapper, error?.length && styles.error, className)} style={{ display: hidden && 'none' }}>
    <div className={classNames(styles.lbl)}>
      {label && <span className={styles.label} children={label} />}
      {children}
    </div>
    <Error error={error} />
  </label>
);
