import React from 'react';
import classNames from "classnames";
import styles from './Switch.css';
import {isEqual} from "@/helpers/dash";

const Def = ({ value }) => JSON.strigify(value);

export const Switch = ({ value, options = [], onChange, onDelete, onCreate, children }) => {

  return (
    <div className={styles.wrapper}>
      {options.map(item => (
        <div
          className={classNames(styles.option, isEqual(item, value) && styles.active)}
          onClick={() => onChange(item)}
          children={children(item)}
        />
      ))}
      {onCreate && <div className={styles.option} children={children()} />}
    </div>
  )
}