import React from 'react';
import { Error } from '@/components/Error';
import { Label } from '@/ui/Label/Label';
import styles from './Tumbler.css';
import classNames from 'classnames';

export enum tumblerSizeMode {
  big = 'big',
  small = 'small',
}

interface ITumbler {
  label?: string;
  disabled?: boolean;
  name?: string;
  size?: tumblerSizeMode;
  checked?: boolean;
  className?: string;
  error?: string;
  indeterminate?: boolean;
  revers?: boolean;
  id?: number;

  onBlur?(e: unknown): void;
  onChange?(e: unknown): void;
  onFocus?(e: unknown): void;
}

export const Tumbler: React.FC<ITumbler> = props => {
  const { size = tumblerSizeMode.big, label, className, error, ...other } = props;

  return (
    <Label label={label} className={className}>
      <input className={styles.input} type="checkbox" {...other} />
      <span className={classNames(styles.tumbler, styles[size])} />
      <Error error={error} />
    </Label>
  );
};
