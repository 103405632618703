import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { groupType } from '@/data/groups';
import {shallowEqual, useSelector} from '@/react-redux';
import { InputSimple } from '@/ui/Input/InputSimple';
import classNames from 'classnames';
import { selectItem } from '@/store/app/getters';
import Plus from '@/ui/icons/plus.svg';
import { useForm } from '@/hooks/useForm';
import styles from './Tag.css';
import { useParams } from 'react-router-dom';
import { actions } from '@/hooks/actions';
import { roleType } from '@/data/member';
import * as chrono from 'chrono-node';
import { TagContainer } from '@/components/Base/Tag';
import { addMinutes } from 'date-fns';
import {useBoard} from "@/modules/Board/BoardContext";
import {Thumb} from "@/modules/Thumb/Thumb";

const initialValue = { title: '' };

const Input = props => {
  const { data, group, className, value, onSelect } = props;
  const { id, type } = data;

  const initial = useMemo(() => ({ title: value || '' }), [value])

  const { Control, reset, handleSubmit } = useForm({ initialValue: initial });

  const onSubmit = data => {
    if (!data?.title?.length) return;
    if (type) data.type = type;

    const tag = actions.tags.create(group, { ...data });
    onSelect && onSelect({ group, id: tag.id });
    reset({ ...initialValue });
  };

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(initial);
  }, [value]);

  return (
    <div data-name='new' className={classNames(styles.wrapper, className)} onClick={onClick}>
      <Thumb className={styles.icon} />
      <div className={styles.title}>
        <Control
          name='title'
          component={InputSimple}
          props={{
            placeholder: 'Добавить',
            onEnter: handleSubmit(onSubmit),
          }}
        />
      </div>
    </div>
  );
}

const Invite = ({ data, className }) => {
  const { id } = useBoard(['id'])
  const onClick = (e) => {
    e.preventDefault();

    actions.invites.create({ fromId: id, role: roleType.guest, groupId: data?.id })
  }

  return (
    <div className={classNames(styles.wrapper, className)} onMouseDown={onClick}>
      <Plus className={styles.icon} />
      <div className={styles.title} children={'Пригласить'} />
    </div>
  )
}

const Date = ({ group, value, onSelect }) => {

  const [{ start, end } = {}] = chrono.ru.parse(value) || [];

  if (!start) return null;

  const args = [start.date(), end?.date()].filter(Boolean).map(time => ({
    id: JSON.stringify({ title: time.toISOString(), type: groupType.date }),
    group
  }))

  return (
    <div onMouseDown={() => onSelect(...args)}>
      {args.map(arg => <TagContainer {...arg} isLock />)}
    </div>
  );
}

const hashmap: Record<groupType | 'null', any> = {
  [groupType.tag]: Input,
  [groupType.numb]: Input,
  [groupType.project]: Invite,
  [groupType.date]: Date,
  null: () => null,
}

export const TagCreateContainer = ({ group, ...props }) => {

  const data = useSelector(selectItem(['group', group], {}), shallowEqual, [group]);

  const Component = hashmap[data?.type as groupType] || hashmap.null;

  // TODO предложения
  // const options = useSelector(selectItem(['project', 'tags', type]))

  return <Component data={data} group={group} {...props} />
}
