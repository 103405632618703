import React, { useMemo, useState } from 'react';
import {groupType} from "@/data/groups";
import {Tag} from "@/components/Base/Tag";
import {TagCreateContainer} from "@/components/Base/TagCreate";
import styles from "@/modules/Search/Search.css";
import {isNumber} from "@/helpers/isUuid";

export const byType = {
  [groupType.date]: () => null,
  [groupType.numb]: ({ isSelected, onSelect, group, value }) => {
    const next = (value || '').trim();

    console.log('next', next, isNumber(next))

    if (!isNumber(next)) return null;

    return (
      <div key={group.id} className={styles.items}>
        <TagCreateContainer
          key='create'
          className={styles.create}
          group={group.id}
          value={next}
          onSelect={onSelect}
        />
      </div>
    );
  },
  [groupType.location]: () => null,
  _: ({ isSelected, onSelect, value, group: { id, type, title }, items }) => {
    const tags = [];

    for (const item of items) {
      const isSelect = isSelected(id, item.id);
      const handle = e => {
        e?.preventDefault();
        e?.stopPropagation();
        onSelect({ id: item.id, group: id });
      };

      tags.push(
        <Tag
          key={item.id}
          isSelect={isSelect}
          onMouseUp={handle}
          parentId={id}
          data={item}
          isLock
        />);
    }

    tags.push(
      <TagCreateContainer
        key='create'
        className={styles.create}
        group={id}
        value={value}
        onSelect={onSelect}
      />
    )

    return <div key={id} className={styles.items}>{tags}</div>;
  }
}