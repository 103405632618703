import { betterstack } from '../../config.json';

// Функция для отправки лога
export async function sendLogs(logs = []) {
  try {
    const body = JSON.stringify(logs, (key, value) => {
      if (!Array.isArray(value) && typeof value === 'object' && value !== null && value.constructor !== Object) {
        return undefined;
      }
      return value;
    });

    const response = await fetch('https://in.logs.betterstack.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${betterstack}`
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error sending log:', error.message);
  }
}