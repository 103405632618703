import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { intervalToDuration, differenceInSeconds, isAfter } from 'date-fns';


interface ITimerProps {
  end?: string;
  style?: CSSProperties;
  className?: string;
  format?: string;
  children(time: string): any;
}

const declinationByNumber = (n, [first, second, third]: string[]) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) return third;
  if (n1 > 1 && n1 < 5) return second;
  if (n1 == 1) return first;
  return third;
};

const decl = {
  years: ['год', 'года', 'лет'],
  months: ['месяц', 'месяца', 'месяцев'],
  days: ['день', 'дня', 'дней'],
  hours: ['час', 'часа', 'часов'],
  minutes: ['минута', 'минуты', 'минут'],
  seconds: ['секунда', 'секунды', 'секунд'],
};

const names = {
  y: 'years',
  M: 'months',
  d: 'days',
  h: 'hours',
  m: 'minutes',
  s: 'seconds',
};

const formatExp = /(y+)|(M+)|(d+)|(m+)|(h+)|(s+)/g;

const get = (format, end) => {
  const dates: [Date, Date] = [new Date(end), new Date()];
  const seconds = isAfter(...dates) ? differenceInSeconds(...dates) : 0;

  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  return format.replace(formatExp, (match) => {
    const name = names[match[0]];

    let res = `${duration[name] || '0'}`;

    if (match.length === 2) res = res.padStart(2, '0');

    res = res
      .split('')
      .map((item) => `${item}`)
      .join('');

    if (match.length === 3)
      res += ` ${declinationByNumber(duration[name], decl[name]).slice(0, 1)}`;
    if (match.length === 4)
      res += ` ${declinationByNumber(duration[name], decl[name])}`;

    return res;
  });
};

export const Timer: React.FC<ITimerProps> = ({ end = '00:00', children, format = 'dddd hh:mm:ss' }) => {
  const getDeadline = () => get(format, new Date(end));

  const [state, setState] = useState(getDeadline());

  useEffect(() => {
    const interval = setInterval(() => {
      setState(getDeadline());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [end]);

  return children(state);
};
