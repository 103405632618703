import { copyToClipboard } from '@/helpers/copyToClipboard';
import React from 'react';
import { Actions } from '@/components/Actions/Actions';
import classNames from 'classnames';
import IconLink from '@/ui/icons/link.svg';
import styles from './Tag.css';
import { sendMessage } from '@/components/Layout/LayoutMessages';
import { Bubble } from '@/components/Bubble/Bubble';
import { actions } from '@/hooks/actions';
import { useSelector } from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import { history } from '@/index';

export const Invite = ({ data = {}, className }) => {
  const { id, infinity, role, tasks } = data;

  const onDelete = () => actions.invites.delete(id);

  const link = window.location.origin + '/invite/' + id;

  const options = [{
    title: 'Перейти',
    onClick: () => {
      history.push('/invite/' + id)
    }
  }, {
    title: 'Удалить',
    onClick: onDelete
  }];

  const children = () => (
    <Bubble count={tasks?.length}>
      <IconLink />
      <div className={styles.title} children={id} />
    </Bubble>
  )

  return (
    <Actions
      options={options}
      className={classNames(styles.wrapper, className)}
      onClick={async () => {
        if ('share' in navigator) {
          await navigator.share({
            title: 'Приглашение в проект',
            url: link,
          });
        } else {
          copyToClipboard(link);
          sendMessage({ text: 'Приглашение скопировано' });
        }
      }}
      children={children}
    />
  )
}

export const InviteContainer = ({ id }) => {
  const data = useSelector(selectItem(['invite', id], {}));

  return <Invite data={data} />
}