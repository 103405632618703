import React, {useEffect, useRef} from 'react';
import { InputWrapper } from '@/ui/Input/InputWrapper';
import styles from './Input.css';
import classNames from 'classnames';
import {regexReplace} from "@/components/Editor/helpers";
import {InputMask} from "@/ui/Input/InputMask";

export interface InputProps {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  error?: string;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  extraProps?: any;
  enterButton?: any;
  component?: any;

  mask?(e: any): any;

  onBlur?(e: any): any;
  onChange?(e: any): any;
  onFocus?(e: any): any;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    error,
    className,
    disabled,
    extraProps,
    type,
    step,
    name,
    autoComplete,
    autoCorrect,
    placeholder,
    required,
    onChange,
    onBlur,
    onFocus,
    hidden,
    label,
    children,
    mask,
    value,
  } = props;

  const Component = mask ? InputMask : 'input';

  return (
    <InputWrapper label={label} error={error} className={className} hidden={hidden}>
      <Component
        className={classNames(error?.length && styles.error, styles.field, disabled && styles.disabled)}
        min={props.min}
        max={props.max}
        type={hidden ? 'hidden' : type}
        step={step}
        name={name}
        mask={mask}
        value={value}
        autoComplete={autoComplete || ''}
        autoCorrect={autoCorrect || ''}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {children}
    </InputWrapper>
  );
};
