const event = async ({ type, selectors, timeout = 100, ...others }, document) => {
  await new Promise(res => setTimeout(res, timeout));

  if (!selectors) return;

  const item = document.querySelector(selectors[0]);

  try {
    item.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  } catch (e) {
    console.log(selectors[0], item);
    throw e;
  }


  if (type === 'selection') {
    const { text } = others;
    const start = item.innerText.indexOf(text);

    const range = document.createRange();
    range.setStart(item.firstChild, start);
    range.setEnd(item.firstChild, start + text.length);

    const selection = document.defaultView.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    return;
  }

  if (type) {
    const Class = type === 'keydown' ? KeyboardEvent : Event;

    item.dispatchEvent(
      new Class(type, { ...others, bubbles: true, composed: true })
    );
    return;
  }

  Object.assign(item, others)
}

export const stream = async (actions = [], name, selector) => {

  const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
    ? "video/webm; codecs=vp9"
    : "video/webm";

  const mediaRecorder = new MediaRecorder(
    await navigator.mediaDevices.getDisplayMedia({ video: { width: 1280, height: 1280 }, selfBrowserSurface: 'include', preferCurrentTab: true }),
    { mimeType: mime }
  );

  let chunks = [];

  Object.entries({
    dataavailable: e => { chunks.push(e.data) },
    stop: () => {
      let blob = new Blob(chunks, { type: chunks[0].type })
      let href = URL.createObjectURL(blob);

      const a = Object.assign(
        document.createElement('a'),
        { href, download: `${name}.webm` }
      );

      a.click();
    }
  })
    .forEach(args => mediaRecorder.addEventListener(...args))

  mediaRecorder.start();


  for (const action of actions.flat()) {
    if (typeof action === 'function') action();
    else await event(action, selector);
  }

  mediaRecorder.stop();
}