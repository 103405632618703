import React, {useEffect} from 'react';
import genStore, {useSelector} from "@/react-redux";
import {useBoard} from "@/modules/Board/BoardContext";
import {MessageEditor} from "@/components/Message/MessageEditor";
import {MessageStream} from "@/components/Message/MessageStream";
import {useMediaSoup} from "@/lib/WebRTC";
import {selectAuth, selectUser} from "@/store/selectors";
import {isEqual} from "@/helpers/dash";
import Calls from "@/ui/icons/calls.svg";
import styles from "./Room.css";
import {TagThumb} from "@/components/Base/Tag";
import {shareActions, useShareE} from "@/hooks/useShare";
import classNames from "classnames";

export const RoomUsers = () => {
  const user = useSelector(selectAuth);

  const { id, task, handleProject } = useBoard(['task', 'id', 'handleProject']);
  const { streams, consume, translation } = useMediaSoup(user, task?.id);

  const [projects] = useShareE({
    filter: { project: id },
    action: shareActions.project,
    data: { task: task?.id || 'null' },
  });

  const [tasks] = useShareE({
    filter: { project: id, task: task?.id || 'null' },
    action: shareActions.task,
    data: { video: translation?.video?._id, audio: translation?.audio?._id },
  });

  useEffect(() => {
    for (const id in tasks) {
      const { video, audio } = tasks[id] || {};

      if (video && !streams[id])
        consume({ user: id, video, audio })
    }
  }, [tasks]);

  const items = [];

  for (const id in tasks)
    items.push(id);

  for (const id in projects)
    if (!(id in tasks))
      items.push(id);

  return (
    <>
      {items.map(id => (
        <TagThumb
          key={id}
          id={id}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (projects[id].task !== 'null')
              handleProject({ task: { id: projects[id].task } })
          }}
          className={classNames(styles.thumb, task?.id && projects[id].task !== task?.id && styles.hide)}
          stream={streams[id]}
        />
      ))}
    </>
  );
}

export const Room = () => {
  const { task } = useBoard(['task']);
  const { id } = task || {};

  const user = useSelector(selectUser, isEqual)
  const { translation, update, produce, close } = useMediaSoup(user, id);

  if (!id) return null;

  const controls= <>{translation.video ? null : <Calls onClick={produce} />}</>

  return (
    <div className={styles.root}>
      <MessageEditor id={id} controls={controls} />
      <MessageStream {...translation} update={update} close={close} />
    </div>
  )
}