import React, { createElement } from 'react';

export const getStyle = (i, is) => ({
  position: 'absolute',
  opacity: is ? 1 : 0,
  transform: `rotate(${is ? 0 : 90}deg)`,
  zIndex: is ? 1 : 0,
  pointerEvents: is ? 'all' : 'none',
})

export const getIcons = (icons, current, other = {}) => (
  <>
    {Object.keys(icons).map(
      (key, i) => (
        icons[key]({ key, className: '', style: getStyle(i, key === current), ...other })
      )
    )}
  </>
)