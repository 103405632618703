import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { Editor } from '@/components/Editor/Editor';
import styles from './MessageEditor.css';
import { actions } from '@/hooks/actions';
import { convert } from '@/components/Task/Task';
import {useBoard} from "@/modules/Board/BoardContext";
import { TagText } from '@/components/Base/Tag';
import Send from '@/ui/icons/send.svg'
import Calls from '@/ui/icons/calls.svg'
import Mic from '@/ui/icons/mic.svg'
import Emoji from '@/ui/icons/emoji.svg'
import Spakle from '@/ui/icons/spakle.svg'
import { Actions } from '@/components/Actions/Actions';
import {selectMessages, selectTag, selectUser} from '@/store/selectors';
import genStore, { shallowEqual, useSelector } from '@/react-redux';
import { sideType } from '@/components/Portal/Portal';
import {Emojies} from "@/modules/Thumb/Thumb";
import {MessageText} from "@/components/Message/Message";
import {shareActions, useShare, useShareE} from "@/hooks/useShare";
import {useApp} from "@/hooks/useApp";
import {onSend} from "@/hooks/useSocket";
import {store} from "@/store/client";
import {selectItem} from "@/store/app/getters";
import {Indicator} from "@/components/Message/Indicator";
import {useMediaSoup} from "@/lib/WebRTC";
import {isEqual} from "@/helpers/dash";
import {data} from "@/pages/Offers/data";
import {MessageStream} from "@/components/Message/MessageStream";

const onChange = ({ id, tags }) => next => {
  const item = { ...convert.from(next, tags), type: 'message' };

  if (id) item.id = id;

  actions.entities[!id ? 'create' : 'update'](item);
};

enum viewType {
  text = "text",
  emoji = "emoji",
}

const custom = {
  media: () => <div style={{ opacity: .25 }}>🖼️ Файл</div>,
}

export const MessageEditor = ({ id, replyed, controls, onReply }) => {
  const ref = useRef(null);
  const { id: project } = useBoard(['id']);
  const timer = useRef();

  const user = useSelector(selectUser, isEqual)

  const [assist, setAssist] = useApp(['message', 'assistent']);

  const [value, setValue] = useState([]);
  const [toId, setToId] = useState(project);
  const [type, setType] = useState(viewType.text);

  const to = useSelector(selectTag(null, toId), shallowEqual, [toId])

  const base = { tags: [{ id: toId }, { id, type: 'task' }, replyed && { id: replyed[0], type: 'message' }, replyed && { id: id[1] }].filter(Boolean) };

  const [users, set] = useShareE({
    filter: { entity:  { type: 'message', entities: base.tags } },
    action: shareActions.print,
  });

  const ids = Object.keys(users).filter(id => users[id]?.print);

  const methods = {
    onChange: (...args) => {
      set({ print: true });
      if (timer.ref) clearTimeout(timer.ref);
      timer.ref = setTimeout(() => set({}), 1300);
      setValue(...args);
    },
    onEnter: (arg, { setValue }) => {
      if (assist)
        onSend([{
          gpt: {
            type: "message",
            task: selectItem(['entity', id])(store.getState()),
            messages: [
              ...selectMessages({ project, task: id })(store.getState()),
              { ...convert.from(arg, base.tags), type: 'message' }
            ]
          }
        }])

      onChange(base)(arg);

      setValue([]);
      onReply(null);
    },
  }

  const toText = id => id === user ? 'Себе' : <TagText id={id} />;

  const emoj = (
    <Emojies
      onChange={emoji => {
        onChange(base)([emoji]);
        setType(viewType.text);
        onReply(null);
      }}
      w={46} count={13}
    />
  );

  // TODO: parse all acessed
  const options = Array.from(new Set([project, user])).map(id => ({ title: toText(id), onClick: () => setToId(id) }));

  return (
    <>
      {ids.length ? (
        <div className={styles.typpers}>
          {ids.map(id => <TagText id={id} />)}
          {ids.length > 1 ? ' печатают' : ' печатает'}
          <Indicator />
        </div>
      ) : null}
      <div className={styles.root}>
        <div className={styles.mentions} ref={ref} />
        <div className={styles.tags}>
          {replyed ? <MessageText className={styles.replied} id={replyed[0]} onClick={() => onReply(null)} /> : (
            <Actions options={options} side={sideType.bottomLeft}>
              <span children={toText(to.id)} />
            </Actions>
          )}
        </div>
      </div>
      <Editor
        portal={ref.current}
        {...methods}
        className={styles.editor}
        placeholder='Написать сообщение...'
        contentEditable
        value={value}
        render={({ body, onEnter }) => (
          <>
            {({ [viewType.text]: body, [viewType.emoji]: emoj })[type]}
            <div className={styles.controls}>
              {controls}
              <Spakle style={{ color: assist ? 'var(--primary)' : null }} onClick={() => setAssist(!assist)} />
              <Emoji onClick={() => setType(prev => prev === viewType.emoji ? viewType.text : viewType.emoji)} />
              <Send onClick={onEnter} />
              {/*<Mic />*/}
            </div>
          </>
        )}
      />
    </>
  )
}
