import React, { useContext, useEffect, useState } from 'react';
import styles from '@/components/Editor/Editor.css';
import IconImage from '@/ui/icons/image.svg';
import IconPen from '@/ui/icons/edit.svg';
import { debounce } from '@/helpers/debounce';
import { caret } from '@/components/Editor/helpers';
import classNames from "classnames";
import {usePrevious} from "@/hooks/usePrevious";

const getPosSide = (container, inner) => {
  const c = container.getBoundingClientRect();
  const { top = 0 } = inner?.getBoundingClientRect() || {};

  return {
    top: top - c.top
  }
}

export const Side = ({ editor, focus, addBlocks }) => {
  const [{ pos, node }, setPos] = useState({});
  const prev = usePrevious(pos);

  useEffect(() => {
    let isCurrent = false;
    let close = () => {}

    const actions = {
      selectstart: (e) => {
        isCurrent = editor.current?.contains(e.target)

        if (!isCurrent) setPos(isCurrent);
      },
      blur: (e) => {
        isCurrent = editor.current?.contains(e.target)

        if (!isCurrent) setPos(isCurrent);
      },
      selectionchange: debounce(10, e => {
        close && close();
        const selection = window.getSelection();

        if (!isCurrent || !selection || !editor.current?.contains(selection.focusNode)) return null;

        const { isCollapsed = true, focusNode, focusOffset} = selection;

        const is =
          focusOffset === 0
          && isCollapsed
          && focusNode
          && !focusNode?.innerText?.length;

        setPos(is ? { pos: getPosSide(editor.current, focusNode), node: focusNode } : {});
      })
    };

    for (const name in actions) document?.addEventListener(name, actions[name]);

    return () => {
      for (const name in actions) document?.removeEventListener(name, actions[name]);
    };
  }, []);

  useEffect(() => {
    if (!node) return;

    node.classList.add(styles.empty);

    return () => {
      node.classList.remove(styles.empty);
    }
  }, [node])

  const a = e => {
    focus.current = true;
    caret.save(window.getSelection());
  };

  return (
    <div className={classNames(styles.side, pos && styles.active)} style={pos || prev} onClick={a}>
      <label>
        <input
          className={styles.input}
          type='file'
          onChange={async e => {
            caret.restore();
            addBlocks(...Array.from(e.target.files))
          }}
        />
        <IconImage />
      </label>
      {/*<IconPen onClick={() => addBlocks({ type: 'draw' })}/>*/}
    </div>
  )
}