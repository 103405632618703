import React from 'react';
import styles from './Error.css';

interface IErrorProps {
  error?: string;
}

export const Error: React.FC<IErrorProps> = ({ error }) => {
  if (!error?.length) return null;
  return <div className={styles.root} children={error} />;
};
