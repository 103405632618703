import React, { useCallback, useState } from 'react';
import { Board } from './Board';
import { useSelector } from '@/react-redux';
import { groupType } from '@/data/groups';
import { BoardCalendar } from '@/modules/BoardCalendar';
import { selectItem } from '@/store/app/getters';
import {useBoard} from "@/modules/Board/BoardContext";
import { selectTaskOrder } from '@/store/selectors';
import { ViewType } from '@/types';
import {BoardMapContainer} from '@/modules/BoardMap/BoardMap';
import {isMobile} from "@/components/Actions/Actions";
import {BoardStats} from "@/modules/Board/BoardStats";
import {isEqual, pathTo} from "@/helpers/dash";
import {Helper} from "@/modules/Helper/Helper";

const BoardHashmap = {
  [groupType.tag]: Board,
  [groupType.project]: Board,
  [groupType.numb]: Board,
  [groupType.date]: BoardCalendar,
  [groupType.location]: BoardMapContainer,
  null: () => null,
}

const BoardType = {
  user: BoardHashmap,
  project: BoardHashmap,
  stats: {
    null: BoardStats,
  },
}

export let order = {};

let heights;

export const defView = () => (global.isSub || isMobile) ? ViewType.list : ViewType.default;

export const BoardContainer: React.FC = (props) => {
  const { boardRef } = props;
  const { id, type, group, filters } = useBoard(['id', 'type', 'group', 'filters']);

  const gtype = useSelector(selectItem(['group', group, 'type'], groupType.tag));
  const view = useSelector(selectItem(['app', 'group', group, 'view'], defView()));
  const width = view === ViewType.default ? '250px' : 'calc(100vw - 2 * var(--gap-m))';

  order = useSelector(selectTaskOrder({ id, group, filters, width }), isEqual, [id, group, filters, width]);

  const [_, setHeights] = useState(heights);

  heights = _;

  const onSize = useCallback((args) => {
    setHeights((prev = {}) => (
      Object.keys(args).some(key => args[key] !== prev[key])
        ? { ...prev, ...args }
        : prev
    ))
  }, []);

  const Board = pathTo(BoardType, [type, gtype], BoardType[type]?.null);

  const opts = { id, view, group, order, heights: heights || {}, onSize };

  const over = {
    onOpen: () => {
      const div = document.querySelector('[data-name="search"]');
      div.focus();
    },
    onClose: () => {
      document.querySelector('[data-name="search"]').blur();
    }
  }


  return (
    <>
      <style>{`
      [data-name="board"] [id="${id}"], [data-name="board"] [data-type="editor"] [group="${group}"] { opacity: 0.2 !important; }
      `}</style>
      <Helper id={id} order={order} type={type} group={group} />
      <Board {...props} {...opts} {...over} />
    </>
  );
}
