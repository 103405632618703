import { useEffect } from 'react';
import { useDispatch } from '@/react-redux';
import { windowSizes } from '@/data/types';
import { setItem } from '@/store/app/actions';

const sizes = {
  320: windowSizes.xs,
  425: windowSizes.sm,
  768: windowSizes.s,
  1024: windowSizes.md,
  1440: windowSizes.lg,
}

const path = ['app', 'viewport'];

export const useViewport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      let next = windowSizes.xs;

      for (const size in sizes)
        if (+size < window.innerWidth)
          next = sizes[size];

      dispatch(setItem(path, prev => prev === next ? prev : next));
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
}
