import React from 'react';
import styles from './Footer.css';

export const Footer = () => (
  <>
    <div className="footer">
      {/*<div className="footer__menu" />*/}
      {/*<div className="flex-fill text-right">© {TITLE}</div>*/}
    </div>
  </>
);
