import { shallowEqual, useDispatch, useSelector } from '@/react-redux';
import { setItem, setItems } from '@/store/app/actions';
import {onSend, useSocket, yieldToMain} from '@/hooks/useSocket';
import { debounce } from '@/helpers/debounce';
import { selectFilters, selectMedias } from '@/store/selectors';
import { useEffect } from 'react';
import { mergeDeepReplace } from '@/helpers/mergeDeep';
import {isEqual, setTo} from '@/helpers/dash';
import {store} from '@/store/client';
import { selectItem } from '@/store/app/getters';
import {useBoard} from "@/modules/Board/BoardContext";

const outside = document?.getElementById('outside-provider');

const mo = new MutationObserver(async items => {
  for (const { target } of items) {
    const is = target.childNodes.length;

    if (!is) {
      target?.classList.remove('active');
      continue;
    }

    target?.classList.add('active');
  }
})

mo.observe(outside, { childList: true });

export const Actions = () => {
  const dispatch = useDispatch();

  const onUpdate = async ({ body = {}, errors, init, done }) => {
    const { sms: _sms, token, pushKey, ...other } = body as any;

    const reqs = [];

    if (init) {
      reqs.push([['app', 'loader'], false]);
      reqs.push([[], next => {
        // TODO: понимать какие картинки можно удалять а какие не надо

        for (const key in next) {
          if (['app', 'token', 'media', 'pushKey', 'next'].includes(key)) continue;

          delete next[key];
        }

        return next;
      }]);
    }

    if (token) {
      reqs.push([ ['token'], token ], [ ['next'], {} ]);
    }

    if (pushKey) {
      reqs.push([['pushKey'], pushKey]);
    }

    if (errors)
      reqs.push([ ['next'], (args) => {

        const next = Object.assign({}, args);

        for (const model in errors) {
          for (const id in errors[model]) {
            if (next[model])
              delete next[model][id];
          }
        }

        return next;
      } ]);

    if (done)
      reqs.push([ ['next'], (args) => {

        const next = Object.assign({}, args);

        for (const item of done) {
          if (!item) continue;

          const { model, id } = item;

          if (next && next[model] && next[model][id])
            delete next[model][id];
        }

        console.group('CLEAR')
        console.log(next);
        console.groupEnd();
        return next;
      } ]);

    if (Object.keys(other).length)
      reqs.push([ [], (prev = {}) => {
        for (const model in other)
          for (const id in other[model])
            setTo(prev, [model, id], other[model][id])

        return prev;
      }])

    if (reqs.length)
      dispatch(setItems(...reqs));

    // TODO: удаление задач которые удалились пока нас не было
  }

  useSocket({ onUpdate });

  return null;
}

export const Load = () => {
  const { task, type, id: project, invite } = useBoard(['task', 'type', 'id', 'invite']);

  const filters = useSelector(selectFilters({ project, type, task: task?.id, invite }), isEqual, [project, type, task?.id, invite]);

  useEffect(() => {
    if (!Object.keys(filters).length) return;
    onSend([{ filters }]);
  }, [filters]);

  const medias = useSelector(selectMedias, shallowEqual);

  useEffect(() => {
    if (!medias.length) return;

    fetch(`/api/media?${medias.map(id => `id=${id}`).join('&')}`, {
      headers: {
        token: selectItem(['token'])(store.getState())
      },
    })
      .then(res => res.json())
      .then(next => store.dispatch(setItem(['media'], prev => mergeDeepReplace(prev, next))))

  }, [medias]);

  // TODO: load all comments from task
  // TODO: load tasks from project

  return null;
}

const onSendDebounce = debounce(1500, actions => {
  onSend([{ actions }])
});

export const Sync = () => {
  const actions = useSelector(selectItem(['next']), shallowEqual, []);

  useEffect(() => {
    const next = {};

    for (const model in actions)
      if (Object.keys(actions[model]).length)
        next[model] = actions[model];

    if (Object.keys(next).length)
      onSendDebounce(next);

  }, [actions]);

  return null;
};