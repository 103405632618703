import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import styles from './Time.css';

export const Time = ({ times, height }) => {
  return (
    <div className={styles.root}>
      {times.map(time => (
        <div key={time.toISOString()} className={styles.head} style={{ height }}>
          <span>{format(time, 'HH:mm', { locale: ru })}</span>
        </div>
      ))}
      <div className={styles.head} style={{ height }}>00:00</div>
    </div>
  );
}