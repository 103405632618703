import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '@/data/routes'
import { Layout } from '@/components/Layout';
import { Header } from '@/modules/Header';
import '@/fonts/_fonts.css';
import '@/ui/root.css';
import {RTCContext} from "@/lib/WebRTC";

const parse = ({ Component, ...props }) => ({
  ...props,
  element: (
    <RTCContext>
      <Layout>
        <Header />
        <Component />
      </Layout>
    </RTCContext>
  )
})

const Root = () => (
  <Routes>
    {routes.map(props => <Route key={props.path as string} {...parse(props)} />)}
  </Routes>
);

export default Root;
