import {
  addDays,
  addMinutes,
  addMonths, differenceInDays,
  differenceInMinutes,
  eachDayOfInterval, endOfDay,
  endOfMonth,
  isSameDay,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { groupType } from '../../../types/group';
import { pathTo } from '@/helpers/dash';

export const getDates = (now = new Date()): Date[] => {
  const today = startOfDay(now);

  const res = []
  for (let i = 56; i > -56; i--) {
    const monthDay = addMonths(today, -i);
    const interval = eachDayOfInterval({ start: startOfMonth(monthDay), end: endOfMonth(monthDay) });

    for (let day of interval) res.push(day);
  }

  return res;
}

export const getTimes = (delta: number): Date[] => {
  const res = [];

  const now = new Date();
  let current = startOfDay(now);

  while (isSameDay(current, now)) {
    res.push(current);

    current = addMinutes(current, delta);
  }

  return res;
}

export const getCoords = ({ target, firstDay, offsetTop, minMin, dayW, minuteH  }) => (e): Date => {
  const { left, top } = target.getBoundingClientRect();

  const { changedTouches } = e.originalEvent || e;

  const x = e.pageX || changedTouches[0]?.pageX;
  const y = e.pageY || changedTouches[0]?.pageY;

  const days = ~~( -1 * (left - x) / dayW );
  const minutes = Math.round( -1 * (top + offsetTop - y) / minuteH / minMin );

  return addMinutes(addDays(firstDay, days), minutes * minMin);
}