import { EventHandler, useEffect } from 'react';

export const handleKey = (keyName, func) => () => {
  const handle = e => {
    if (typeof keyName === 'string' && keyName === e.key) func(e.key)
    else if (typeof keyName === 'function' && keyName(e)) func(e.key)
    else if (keyName?.test && keyName?.test(e.key)) func(e.key)
  };

  window.addEventListener('keydown', handle);

  return () => {
    window.removeEventListener('keydown', handle);
  }
}

export const useWindow = (
  handlers: Record<string, EventHandler<any>>,
  deps = [],
  { target, settings } = {}
): void => {
  useEffect(() => {
    const actions = Object.entries(handlers).filter((item) => item[1]);

    actions.forEach((args) => (target?.current || window).addEventListener(...args, settings));

    return () => {
      actions.forEach((args) => (target?.current || window).removeEventListener(...args));
    };
  }, deps);
};