import React, { useEffect, useState } from 'react';
import styles from './PreviewPage.css';
import classNames from 'classnames';
import steps from './steps';
import { handleKey } from '@/helpers/hooks';
import { useLocation } from 'react-router';
import { stream } from '@/lib/record';

export const PreviewPage = () => {
  let { hash } = useLocation();
  const [titles, setTitle] = useState([]);

  if (!hash.lentgh) hash = '#' + Object.keys(steps)[0];

  const key = hash.slice(1);

  const add = (item) => {
    setTitle(prev => [...prev, item].filter(Boolean));
    setTimeout(() => setTitle(next => [...next, null]), 100);
  };

  useEffect(
    handleKey('Enter', () => {
      const items = steps[key].map(item => typeof item === 'string' ? () => add(item) : item);

      stream(items, key, document.querySelector('iframe').contentDocument);
    }),
    []
  );

  return (
    <div className={classNames(styles.root, styles[key])}>
      <div className={styles.phone}>
        <div className={styles.header}>
          <svg width="36" height="14" viewBox="0 0 36 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.0166 2.72314C3.41846 2.72314 1.56738 4.49951 1.56738 6.93164V6.94824C1.56738 9.22266 3.17773 10.8994 5.47705 10.8994C7.12061 10.8994 8.1665 10.061 8.60645 9.11475H8.77246C8.77246 9.20605 8.76416 9.29736 8.76416 9.38867C8.67285 11.6797 7.86768 13.5391 5.9668 13.5391C4.9126 13.5391 4.17383 12.9912 3.8584 12.1528L3.8335 12.0698H1.7251L1.7417 12.1611C2.12354 13.9956 3.76709 15.2988 5.9668 15.2988C8.97998 15.2988 10.7979 12.9082 10.7979 8.87402V8.85742C10.7979 4.54102 8.57324 2.72314 6.0166 2.72314ZM6.0083 9.25586C4.64697 9.25586 3.65918 8.25977 3.65918 6.87354V6.85693C3.65918 5.52051 4.71338 4.46631 6.0332 4.46631C7.36133 4.46631 8.39893 5.53711 8.39893 6.90674V6.92334C8.39893 8.27637 7.36133 9.25586 6.0083 9.25586ZM14.0664 7.23877C14.8301 7.23877 15.3862 6.65771 15.3862 5.92725C15.3862 5.18848 14.8301 4.61572 14.0664 4.61572C13.311 4.61572 12.7466 5.18848 12.7466 5.92725C12.7466 6.65771 13.311 7.23877 14.0664 7.23877ZM14.0664 13.3979C14.8301 13.3979 15.3862 12.8252 15.3862 12.0864C15.3862 11.3477 14.8301 10.7749 14.0664 10.7749C13.311 10.7749 12.7466 11.3477 12.7466 12.0864C12.7466 12.8252 13.311 13.3979 14.0664 13.3979ZM23.1455 15H25.1958V12.7007H26.8062V10.9326H25.1958V3.02197H22.166C20.5391 5.49561 18.8374 8.25146 17.2852 10.9492V12.7007H23.1455V15ZM19.2773 10.9824V10.8579C20.4395 8.82422 21.8257 6.60791 23.0542 4.73193H23.1787V10.9824H19.2773ZM31.27 15H33.4116V3.02197H31.2783L28.1489 5.22168V7.23877L31.1289 5.13037H31.27V15Z" fill="white"/>
          </svg>
          <space/>
          <svg width="78" height="10" viewBox="0 0 78 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.971 2.84599C37.4376 2.8461 39.8098 3.81668 41.5975 5.55712C41.7321 5.69149 41.9472 5.68979 42.0798 5.55332L43.3666 4.2234C43.4337 4.15418 43.4711 4.06042 43.4706 3.96287C43.47 3.86531 43.4315 3.77201 43.3636 3.7036C38.6717 -0.901199 31.2697 -0.901199 26.5777 3.7036C26.5098 3.77196 26.4712 3.86523 26.4706 3.96279C26.47 4.06034 26.5073 4.15413 26.5744 4.2234L27.8615 5.55332C27.994 5.69 28.2094 5.6917 28.3439 5.55712C30.1317 3.81656 32.5042 2.84598 34.971 2.84599ZM34.971 7.17266C36.3262 7.17257 37.6331 7.68843 38.6377 8.61999C38.7735 8.7522 38.9876 8.74933 39.12 8.61353L40.4053 7.28361C40.473 7.21386 40.5105 7.11922 40.5096 7.02089C40.5086 6.92255 40.4691 6.82872 40.4001 6.76039C37.341 3.84629 32.6036 3.84629 29.5446 6.76039C29.4755 6.82872 29.436 6.9226 29.4351 7.02097C29.4342 7.11933 29.4719 7.21396 29.5397 7.28361L30.8247 8.61353C30.9571 8.74933 31.1711 8.7522 31.307 8.61999C32.3109 7.68905 33.6167 7.17323 34.971 7.17266ZM37.5457 10.084C37.5476 10.1826 37.5098 10.2777 37.441 10.3468L35.2178 12.6445C35.1526 12.712 35.0637 12.75 34.971 12.75C34.8783 12.75 34.7895 12.712 34.7243 12.6445L32.5006 10.3468C32.4319 10.2776 32.3942 10.1825 32.3962 10.0839C32.3982 9.98529 32.4399 9.89191 32.5114 9.82581C33.9313 8.59595 36.0108 8.59595 37.4307 9.82581C37.5021 9.89196 37.5437 9.98537 37.5457 10.084Z" fill="white"/>
            <g clip-path="url(#clip0_201_862)">
              <rect opacity="0.4" x="50.4706" y="0.5" width="24" height="12" rx="3.5" stroke="white"/>
              <path opacity="0.5" d="M75.9706 4.5V8.5C76.7765 8.16122 77.3006 7.37313 77.3006 6.5C77.3006 5.62687 76.7765 4.83878 75.9706 4.5Z" fill="white"/>
              <rect x="51.9706" y="2" width="17" height="9" rx="2" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_201_862">
                <rect width="27.33" height="13" fill="white" transform="translate(49.9706)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <iframe
          className={styles.iframe}
          src='http://localhost:6539/project/79ddbd9d-d208-41d3-8086-d884b2e6b9ac/'
        />
      </div>
      <div className={styles.textWrp}>
        {titles.slice(-3).reverse().map((title, i) => (
          <h1 key={title} className={classNames(styles.text, styles['text' + i])} children={title}/>
        ))}
      </div>
    </div>
  );
}