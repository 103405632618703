import {genKey, setTo} from "@/helpers/dash";

export default {
  setItems: (payload = []) => (state) => {
    for (const item of payload) {
      if (!item) continue;
      state = setTo(state, ...item);
    }

    state.key = genKey();

    return state;
  },
}