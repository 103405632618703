import React, { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Portal.css';
import { pickBy } from '@/helpers/dash';
import { usePrevious } from '@/hooks/usePrevious';

const provider = document.getElementById('outside-provider');

export enum sideType {
  topLeft,
  topRight,
  topCenter,
  bottomLeft,
  bottomRight,
}

const params = {
  [sideType.topLeft]: ["top", "left"],
  [sideType.topRight]: ["top", "right"],
  [sideType.topCenter]: ["top", "left", "center"],
  [sideType.bottomLeft]: ["bottom", "left"],
  [sideType.bottomRight]: ["bottom", "right"],
}
const stack = new Map();

export const Portal = props => {
  const { innerRef, side = sideType.topLeft, state, onClose, children, resize } = props;
  const [style, setStyle] = useState(null);
  const prev = usePrevious(state);

  useEffect(() => {
    const rect = innerRef?.current?.getBoundingClientRect();

    const sizes = {
      top: rect.top,
      left: rect.left,
      bottom: window.innerHeight - rect.bottom,
      right: window.innerWidth - rect.right,
    }

    if (prev === 0 && state === 1)
      provider.classList.add('blur');

    if (prev === 2 && state === 1)
      provider.classList.remove('blur');

    const trigger = e => {
      if (state && (e.target === provider || e.target.dataset.trigger)) {
        onClose();
      }
    };

    setStyle(rect && state && {
      ...pickBy(sizes, params[side]),
      margin: params[side].includes('center') ? '0 auto' : '',
      marginLeft: params[side].includes('right') && 'auto',
      marginTop: params[side].includes('bottom') && 'auto',
      alignItems: params[side].includes('center') && 'center',
      minWidth: rect.width,
      width: !resize && rect.width
    })

    // document.body.style.overflow = is ? 'hidden' : '';

    document.addEventListener('mousedown', trigger, { passive: true });

    return () => {
      document.removeEventListener('mousedown', trigger)
    };
  }, [state]);

  if (!(style && provider)) return null;

  return createPortal(
    <div className={styles.root} style={style}>
      {children}
    </div>,
    provider
  );
}
