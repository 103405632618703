import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useBoard} from "@/modules/Board/BoardContext";
import { shallowEqual, useDispatch, useSelector } from '@/react-redux';
import styles from './Groups.css';
import { groups, groupType } from '@/data/groups';
import { InputSimple } from '@/ui/Input/InputSimple';
import {Actions, isMobile, MenuItem} from '@/components/Actions/Actions';
import classNames from 'classnames';
import { selectItem } from '@/store/app/getters';
import { actions } from '@/hooks/actions';
import { stringToColour } from '@/helpers/color';
import { selectGroupOrder, selectMembers } from '@/store/selectors';
import { Drag } from '@/components/Dnd/Drag';
import { ViewType } from '@/types';
import { roleType } from '@/data/member';
import { TagContainer } from '@/components/Base/Tag';
import { sideType } from '@/components/Portal/Portal';
import {Thumb} from "@/modules/Thumb/Thumb";
import {defView} from "@/modules/Board/BoardContainer";
import {useApp} from "@/hooks/useApp";
import {useForm} from "@/hooks/useForm";
import {Switch} from "@/ui/Switch/Switch";
import {isEqual} from "@/helpers/dash";
import {Label} from "@/components/Label/Label";
import {Draggable} from "@/components/Dnd/Dragg";

export const GroupContainer = ({ id, ...other }) => {

  const data = useSelector(selectItem(['group', id]), shallowEqual, [id]);

  return <Group id={id} data={data} {...other} />
}

const useMembers = (project, groupId) => {
  const items = useSelector(selectMembers({ tagId: project }, { memberId: project }, { groupId }), isEqual, [project, groupId])
    .reduce((acc, { id, groupId: group, tagId, memberId }) => {
      const tag = memberId || tagId;

      if (tag === project) return acc;

      const checkedId = group === groupId && id;

      if (checkedId || !(tag in acc)) acc[tag] = checkedId;

      return acc;
    }, {
      [project]: false,
    });

  return Object.assign(
    Object.keys(items).map(id => ({
      title: <TagContainer id={id} isLock />,
      onClick: () => {
        if (items[id]) return actions.members.delete(items[id]);

        actions.members.create({
          groupId,
          memberId: id,
          role: roleType.admin,
        });
      },
      isActive: items[id]
    })),
    { title: 'Участники' }
  );
}

const Members = ({ className, project, groupId }) => {
  const items = useMembers(project, groupId);
  return (
    <div className={className}>
      <Label>{items.title}</Label>
      {items.map(({ title, onClick, isActive }) => (
        <div onClick={onClick} style={{ opacity: isActive ? 1 : .5 }}>
          {title}
        </div>
      ))}
    </div>
  )
}

const optionsType = [groupType.tag, groupType.project, groupType.numb, groupType.date, groupType.location].map(value => ({
  name: groups[value].name,
  value,
}))

const change = (memberId, prev, next) => {
  if (prev.id)
    return actions.groups.update({ id: prev.id, ...next });

  actions.groups.create({ groups: [{ ...prev, ...next }], members: [{ memberId, role: 'admin' }] });
};

const optionsViews = Object.entries({
  [ViewType.default]: 'Колонками',
  [ViewType.list]: 'Списком',
}).map(([key, title]) => ({
  name: title,
  value: key
}))

export const Group = ({ id: baseId, data, open, onClick }) => {
  const [view, viewChange] = useApp(['group', baseId, 'view'], defView());

  const { id: project } = useBoard(['id']);

  const initialValue = useMemo(() => ({ type: groupType.tag, title: '', ...(data || {}) }), [data]);
  const { Control, handleSubmit } = useForm({ initialValue, log: (...a) => !baseId && console.log(...a) })

  const style = { opacity: open ? 1 : .5 };

  const onUpdate = handleSubmit(null, (next) => change(project, initialValue, next));

  const children = ({ isOpen, isInner }) => {
    return (
      <div className={classNames(styles.body, isInner && styles.in, isOpen ? styles.open : styles.hide)}>
        <div className={styles.title}>
          <Control
            proxy={['type', 'thumb', 'title']}
            props={{ className: styles.icon }}
            component={Thumb}
          />
          <Control
            name='title'
            props={{
              autoFocus: isOpen && !isMobile,
              placeholder: baseId || !isOpen ? 'Добавить' : 'Заголовок',
              disable: !isOpen,
              onEnter: onUpdate,
            }}
            component={InputSimple}
          />
        </div>
        {isOpen && (
          <div className={styles.controls}>
            {baseId ? (
              <>
                <div className={styles.switch}>
                  <Switch label='Вид' options={optionsViews} value={view} onChange={viewChange}/>
                </div>
                <Members className={styles.members} project={project} groupId={baseId} />
                <Label className={styles.delete} onClick={() => actions.groups.delete(baseId)}>Удалить</Label>
              </>
            ) : (
              <div className={styles.switch}>
                <Control
                  name='type'
                  component={Switch}
                  props={{label: 'Тип', options: optionsType, className: styles.switch }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <Actions
      resize
      data-group={baseId || 'new'}
      onClick={onClick}
      className={classNames(styles.group, 'blur')}
      style={style}
      side={sideType.bottomLeft}
      children={children}
      onBlur={onUpdate}
    />
  )
}

const move = (from, to, [...next]) => {

  next.splice(to.index, 0, ...next.splice(from.index, 1));

  return next;
}

export const Groups = () => {
  const {id, type, group, handleProject} = useBoard(['id', 'type', 'group', 'handleProject']);

  const order = useSelector(selectGroupOrder(id), shallowEqual);
  const [state, setState] = useState(order);

  useEffect(() => { setState(order) }, [order]);

  const onChange = next => () => {
    if (group !== next)
      handleProject({ type, id, group: next })
  };

  const onDrop = (from, to) => {
    console.log('from, to',from, to)
    const next = move(from, to, order);
    actions.projects.update({ id, groups: next })
  };

  const onMove = useCallback((from, to) => {
    console.log('from, to',from, to)
    setState(prev => move(from, to, prev))
  }, []);

  const isSame = (p, n) => p.id === n.id;

  return (
    <div className={styles.groups} data-name='groups'>
      <div className={styles.groupsRow}>
        {state.map((id, index) => (
          <Draggable key={id} isSame={isSame} onDrop={onDrop} onMove={onMove} type='group' data={{ id, index }} className={styles.column}>
            <GroupContainer
              id={id}
              open={id === group}
              onClick={onChange(id)}
            />
          </Draggable>
        ))}
        <GroupContainer key={state?.length} id=''/>
      </div>
    </div>
  )
}