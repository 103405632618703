import {
  selectAuth,
  selectInvites,
  selectMembers,
  selectProjectsOrder,
  selectRole,
  selectTag,
  selectUser
} from '@/store/selectors';
import { useForm } from '@/hooks/useForm';
import { actions } from '@/hooks/actions';
import { roleType } from '@/data/member';
import { history, rerender } from '@/index';
import styles from './HeaderLeft.css';
import { InputSimple } from '@/ui/Input/InputSimple';
import { useSelector } from '@/react-redux';
import React, {useCallback, useEffect} from 'react';
import Select from '@/ui/icons/select.svg';
import classNames from 'classnames';
import { Actions, Menu } from '@/components/Actions/Actions';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { sendMessage } from '@/components/Layout/LayoutMessages';
import Link from '@/ui/icons/link.svg';
import Plus from '@/ui/icons/plus.svg';
import { Thumb, ThumbPicker } from '@/modules/Thumb/Thumb';
import Yup from '@/lib/Validation';
import {useBoard} from "@/modules/Board/BoardContext";
import {Project} from "@/components/Base/Project";
import {TagContainer, TagText} from "@/components/Base/Tag";
import {useInvites, useRole} from "@/components/Base/helpers";
import {isEqual} from "@/helpers/dash";
import {onSend, useSocket} from "@/hooks/useSocket";
import {assign} from "@/helpers/assign";

const ThumbInput = ({ type, value, className }) => <Thumb type={type} thumb={value} className={className} />;

export const Projects = ({ onClick }) => {
  const order = useSelector(selectProjectsOrder(), isEqual, []);

  return (
    <>
      <div className={styles.projects}>
        {[null, ...order].map(id => <Project key={id} id={id} onClick={onClick}/>)}
      </div>
    </>
  );
}

const Invite = () => {

}

export const HeaderLeft = ({id, handleProject}) => {
  const data = useSelector(selectTag(null, id), isEqual, [id]);
  const { Control, handleSubmit } = useForm({ initialValue: data });
  const { role, subscribe } = useSelector(selectRole(id), isEqual, [id]);

  const { type } = data;

  const user = useSelector(selectUser, isEqual, []);
  const members = useRole().filter(item => item.id !== 'public');
  const invites = useInvites();
  const pub = useRole('public');

  const inv = members
    .concat(pub)
    .concat(invites)
    .concat([
      {
        title: 'Добавить',
        icon: Plus,
        onClick: () => {
          actions.invites.create({
            fromId: id,
            role: roleType.guest,
            infinity: true,
          });
        },
      },
    ]);

  const isAdmin = (role === roleType.admin || id === user);

  Object.assign(inv, {
    title: 'Участники',
    view: 'list',
    hide: !isAdmin,
  })

  const settings = [
    assign([], {
      title: 'Аватарка',
      children: <Control name='thumb' component={ThumbPicker} props={{ count: 10 }} />,
      hide: !isAdmin
    }),
    inv,
    {
      title: role === roleType.admin ? 'Удалить' : 'Покинуть проект',
      confirm: true,
      hide: !role,
      onClick: () => {
        if (confirm('Вы уверены?')) {
          actions.projects[role === roleType.admin ? 'delete' : 'remove'](id);
          history.replace('/');
        }
      },
    }
  ];

  const onClick = ({ id, type }) => handleProject({ id, type, group: null })

  return (
    <Actions data-name="projects" resize onBlur={handleSubmit(null, args => actions.projects.update({ id, ...args }))}>
      {({ isOpen, isInner, onLongPress, from }) => {
        const isLong = from === 'click';
        return (
          <div className={classNames(styles.root, isInner && styles.in, isOpen ? styles.open : styles.hide)} data-trigger={isInner && isLong}>
            <Control name="thumb" component={ThumbInput} props={{ type, className: classNames(styles.avatar, isInner && styles.in) }}/>
            {!isInner && <Select className={styles.select} />}
            {isInner && <Control
                name="title"
                component={InputSimple}
                props={{ className: styles.title }}
            />}
            {isInner && isLong && <Projects onClick={onClick} />}
            {isInner && !isLong && (
                <Menu className={styles.projects} options={settings} open />
            )}
            </div>
        );
      }}
    </Actions>
  );
}

const validation = Yup.object().shape({
  title: Yup.string().min(3, 'Имя должно быть больше 3 символов').required('Имя должно быть больше 3 символов'),
})

const def = {};

export const Create = ({}) => {
  const id = useSelector(selectAuth);

  const { Control, reset, handleSubmit } = useForm({ initialValue: def, validation });

  const onSubmit = data => {
    actions.projects.create({ ...data, members: [{ memberId: id, role: 'admin' }] });
    reset({ title: '' });
  }


  return (
    <div className={classNames(styles.pro)}>
      <Control
        name='title'
        component={InputSimple}
        props={{
          placeholder: 'Добавить проект',
          onEnter: handleSubmit(onSubmit),
        }}
      />
    </div>
  );
}

export const HeaderLeftContainer = () => {
  const { id, ignore, handleProject } = useBoard(['id', 'handleProject', 'ignore']);
  if (ignore) return null;
  return <HeaderLeft key={id} id={id} handleProject={handleProject} />;
}