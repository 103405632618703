import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './MainPage.css';
import { Helmet } from '@/modules/Helmet';
import classNames from 'classnames';
import {shallowEqual, useDispatch, useSelector} from '@/react-redux';
import { setItem } from '@/store/app/actions';
import {Board} from '@/pages/BoardPage/Board';
import { blocks } from '@/pages/MainPage/data';
import { Transition } from '@/pages/MainPage/Transition';
import { selectUser } from '@/store/selectors';
import {store} from "@/store/client";
import {useBoard} from "@/modules/Board/BoardContext";
import {throttle} from "@/helpers/thtrottle";

const config = {
  title: 'Джасп — простой и удобный таск менеджер, в котором возможно все',
}

export const param = (from, to, val, k = 1) => (Math.max(from, Math.min(to, val)) - from) / (to - from) * k

const Bard = () => {
  const { task, handleProject } = useBoard(['task', 'handleProject']);
  const userId = useSelector(selectUser, shallowEqual);
  const [style, setStyle] = useState()

  const stepper = useCallback(throttle((step) => {
    console.log('stepper', step);
    let task = { id: entity1.id };

    if (step > 2) task = null;

    handleProject({ task });
    store.dispatch(setItem([], getStateByStep(userId, step)));
  }, 50), [handleProject])

  useEffect(() => {

    const handle = () => {
      const step = (window.scrollY) / (document.body.scrollHeight / blocks.length);

      setStyle({
        opacity: param(0.2, 1, step, 1),
      })

      stepper(step);
    };

    handle();

    window.addEventListener('scroll', handle);

    return () => {
      window.removeEventListener('scroll', handle);
    }
  }, []);

  return <div className={styles.board} style={style}><Board /></div>;
}

export const MainPage = () => {
  return (
    <>
      <Helmet {...config} />
      {
        blocks.map(({ key, classNameInner = styles.block, className = styles.b, children, other }) => (
          <Transition
            key={key}
            className={className}
            classNameInner={classNameInner}
            children={children}
            other={other}
          />
        ))
      }
      <div className={styles.wrapper} style={{ height: 200 + 'vh' }} />
    </>
  );
}
