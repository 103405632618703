import React from 'react';
import styles from './Device.css';
import Trash from '@/ui/icons/trash.svg'
import {selectUserData} from "@/store/selectors";
import {store} from "@/store/client";
import {actions} from "@/hooks/actions";
import classNames from "classnames";

const re = /\((?<device>\w+);.*\) (?<soft>.+)/

const onDelete = (state) => {
  const user = selectUserData(store.getState());

  actions.users.update({
    id: user.id,
    clients: [{ $pull: { endpoint: state.endpoint } }]
  })
}

export const Device = ({ userAgent = '', ...args }) => {

  const { groups: { device = 'Device', soft = 'Soft' } } = re.exec(userAgent);

  const isCurrent = userAgent === navigator.userAgent;

  return (
    <div className={classNames(styles.root, isCurrent && styles.active)}>
     <div></div>
      <div>
        <div className={styles.device}>{device}</div>
        <div className={styles.soft}>{soft}</div>
      </div>
      <div className={styles.trash}>
        <Trash onClick={() => onDelete(args)} />
      </div>
    </div>
  )
}