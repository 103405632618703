import {useSocket} from "@/hooks/useSocket";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "@/react-redux";
import {selectAuth} from "@/store/selectors";
import {throttle} from "@/helpers/thtrottle";
import {isEqual} from "@/helpers/dash";
import {v4 as genId} from 'uuid';


export enum shareActions {
  print  = 'print',
  project  = 'project',
  task  = 'task',
  video  = 'video',
}

export const useShare = ({ model, filter, action, data, time = 3000 }, handle = () => {}) => {
  const id = useSelector(selectAuth);
  const ref = useRef({});
  const args = useRef({});

  const [actors, setActors] = useState({});

  args.current = { model, filter, action, data, time, user: id };

  const onSend = useSocket({
    onUpdate: useCallback(({ share }) => {
      if (!share) return;
      const { user, data, model: m, action: a } = share;

      if (!(m === model && action === a)) return;

      handle(share);

      setActors(prev => {
        const next = {...prev}
        next[user] = data;
        return isEqual(prev, next) ? prev : next;
      })

      clearTimeout(ref.current[user]);

      ref.current[user] = setTimeout(() => {
        setActors(prev => {
          const next = {...prev}
          delete next[user];
          return isEqual(prev, next) ? prev : next;
        })
      }, time + 400);
    }, [handle, time])
  })

  return [actors, throttle((other = {}) => onSend([{ share: { ...args.current, ...other } }]), time)]
}

const useUniq = (data) => {
  const [state, setState] = useState(data);

  useEffect(() => {
    setState(prev => {
      if (isEqual(data, prev)) return prev;

      return data;
    });

  }, [data]);

  return state;
}

export const useShareE = ({ filter, action, data = {} }, handle = () => {}) => {
  const id = useMemo(genId, []);
  const ref = useRef(handle);

  ref.current = handle;

  const [state, setState] = useState({});
  const [actors, setActors] = useState({});

  const args = useUniq({ ...filter, action, data: { ...state, ...data } });

  // on off
  const onSend = useSocket({
    onUpdate: useCallback(({ share }) => {
      if (!share) return;

      for (const id in share) {
        const { action: a, data, user } = share[id];
        if (action !== a) return;

        if (ref.current) ref.current(share);

        setActors(prev => {
          const next = { ...prev }

          if (data === null)
            delete next[user];
          else
            next[user] = data;

          console.log('--', prev, next)

          return isEqual(prev, next) ? prev : next;
        })
      }
    }, [])
  })

  useEffect(() => {
    onSend([{ share: { [id]: args } }]);
  }, [args]);

  useEffect(() => {
    return () => {
      onSend([{ share: { [id]: null } }]);
    }
  }, []);

  return [
    actors,
    setState
  ]
}