import React, { Component } from 'react';
import styles from './Error.css';
import {HelmetContainer} from "@/modules/Helmet/HelmetContainer";
import {Emoji} from "@/modules/Thumb/Thumb";
import {sendLogs} from "@/lib/betterstack";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error: true, text: error };
  }

  componentDidCatch(error, errorInfo) {
    this.props?.onError && this.props?.onError(error);
    sendLogs([{ name: error, level: 'error', nested: { error, errorInfo }  }])
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return <>
        <HelmetContainer />
        <div className={styles.boundary}>
          <div className={styles.bound}>
            <div className={styles.img}>
              <Emoji autoplay emoji="🙈" />
            </div>
            <b className={styles.title}>Что-то пошло не так</b>
            <p>Мы уже отправили ошибку разработчикам и в скором времени поправим</p>
            <div>{this.state.text?.toString()}</div>
          </div>
        </div>
      </>;
    }

    return this.props.children;
  }
}
