import {selectItem} from "@/store/app/getters";
import {setItem} from "@/store/app/actions";
import {deleteTo} from "@/helpers/dash";
import {useDispatch, useSelector} from "@/react-redux";

export const useApp = (p, def, base = 'app') => {
  const path = [base, ...p];

  const dispatch = useDispatch();
  const val = useSelector(selectItem(path, def));

  return [val, next => {
    if (def === next)
      dispatch(setItem([], args => deleteTo(args, path)))
    else
      dispatch(setItem(path, next))
  }]
}