import * as React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Avatar } from '@/ui/Avatar';
import styles from './Message.css';
import { Editor } from '@/components/Editor/Editor';
import { Actions } from '@/components/Actions/Actions';
import { actions } from '@/hooks/actions';
import { convert } from '@/components/Task/Task';
import {shallowEqual, useSelector} from '@/react-redux';
import {emojis, Thumb} from "@/modules/Thumb/Thumb";
import {selectTag} from "@/store/selectors";
import {selectItem} from "@/store/app/getters";

export const Message: React.FC<any> = (props) => {
  const { id, createdAt, createdBy, isSelf, items = [], onReply } = props;

  if (!createdAt) return null;

  const by = useSelector(selectTag(null, createdBy), shallowEqual, [createdBy]);

  const thumb = by.thumb?.color;

  const time = format(createdAt, 'HH:mm');

  const [text, entities] = convert.to(props.text, props.entities);

  const cn = isSelf ? styles.self : styles.nself;

  const replied = entities.find(item => item?.type === 'message');

  const options = [
    { title: 'Ответить', onClick: () => onReply([id, createdBy]) },
    { title: 'Удалить', onClick: () => actions.entities.delete(id) }
  ];

  const type = text.length === 1 && emojis.includes(text[0]) ? 'emoji' : 'text';

  return (
    <div className={classNames(styles.group, cn)}>
      {!isSelf && <Thumb mode='avatar' className={styles.author} {...by} />}
      <Actions className={styles.actions} onClick={() => {}} options={options}>
        {({ isOpen, onTrigger }) => (
          <div className={styles.body}>
            <div className={classNames(styles.mes, styles[type], cn, isOpen && styles.active)}>
              {replied && (
                <MessageText id={replied.id} className={styles.replied} />
              )}
              {({
                emoji: <Thumb autoplay thumb={{ emoji: text[0] }} />,
                text: (
                  <>
                    {!isSelf && <div className={styles.name} style={{ color: thumb }} children={by.title} />}
                    <Editor
                      value={text}
                      className={styles.inner}
                      contentEditable={isOpen}
                      onEnter={items => {
                        actions.entities.update({ id, ...convert.from(items, entities) });
                        onTrigger();
                      }}
                    />
                  </>
                )
              })[type]}
              <span className={styles.time} children={time} />
            </div>
          </div>
        )}
      </Actions>
    </div>
  );
}

export const MessageText = ({ id, ...other }) => {
  const props = useSelector(selectItem(['entity', id], {}),  shallowEqual, [id]);

  const [text, entities] = convert.to(props.text, props.entities);

  return <Editor {...other} value={text} />;
}