import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLongPress} from '@/hooks/useLongPress';
import classNames from 'classnames';
import styles from './Actions.css';
import {Portal, sideType} from '@/components/Portal/Portal';
import {compose} from '@/react-redux';
import {Label} from '@/components/Label/Label';
import {handleKey} from '@/helpers/hooks';
import Close from '@/ui/icons/close.svg';
import {ErrorBoundary} from "@/components/Error/ErrorBoundary";
import {Select} from "@/ui/Select/Select";
import {Switch} from "@/ui/Switch/Switch";

export const isMobile = 'ontouchstart' in window || navigator?.msMaxTouchPoints;

const prevent = e => {
  e.preventDefault();
  e.stopPropagation();
  return e;
};

export const MenuItem = (props) => <div className={styles.item} {...props} />

export const Menu = ({ options, close, className, onTrigger = () => null }) => {

  // const { onKeyDown, selectorKeyWrapper } = useArrowSelector({ onChange: onSelect });

  const ren = useCallback((option, i) => {
    if (Array.isArray(option))
      return (
        <MenuItem key={i}>
          <Label children={option.title}/>
          {option.children}
          <div className={classNames(styles.sub, styles[option.view || 'inline'])}
               children={option.map((item, j) => !!item && ren(item, `${i}${j}`))}/>
        </MenuItem>
      );

    const {title, children, switch: swi, hide, icon: Icon, isActive, select, onClick, onClose} = option;

    if (hide) return null;

    const click = onClick && ((e) => {
      prevent(e);
      if (onClick(e)) return;
      onTrigger();
    });

    return (
      <div
        className={classNames(styles.item, isActive && styles.active)}
        key={title + i}
        onClick={click}
        children={[
          Icon && <Icon className={styles.icon}/>,
          swi ? <Switch label={title} {...swi} /> : title,
          onClose && <Close className={styles.close} onClick={onClose}/>,
          children
        ].filter(Boolean)}
      />
    );

  }, []);

  const menu = useMemo(() => options.map(item => !!item && ren(item)), [options]);

  if (!options?.length) return null;

  return (
    <div className={classNames(styles.block, className, !close && styles.open, isMobile && styles.blockMobile)}>
      {options.title && <Label className={styles.item} children={options.title}/>}
      {menu}
    </div>
  )
}

export const Actions = forwardRef((props, re) => {
  const {
    options = [],
    onClick,
    side = sideType.topLeft,
    children,
    onBlur,
    Tag = 'div',
    className,
    wrapperClassName,
    resize,
    ...other
  } = props;

  const data = useRef(props)
  data.current = props;

  const a = useRef();
  const [state, setState] = useState(0);

  const ref = re || useRef(null);

  const triggers = useMemo(() => ({
    open: () => {
      setState(prev => {
        if (prev === 2) return prev;
        setTimeout(() => setState(2), 250);
        return 1;
      });
    },
    close: () => {
      if (data?.current?.onBlur)
        data?.current?.onBlur();

      setState(prev => {
        if (prev === 0) return prev;
        setTimeout(() => setState(0), 250);
        return 1;
      });
    },
  }), []);

  useEffect(handleKey('Escape', () => state !== 0 && triggers.close()), [state]);

  const onClickPress = (e) => {
    (onClick || triggers.open)(e, ref.current);
    a.current = 'click';
  };
  const onLongPress = () => {
    triggers.open();
    a.current = 'long';
  };

  useLongPress(ref, {
    onClick: onClickPress,
    onLongPress: isMobile && onLongPress,
  });

  const child = useMemo(() => (
    typeof children === 'function' ? children({
      isOpen: false,
      onTrigger: triggers.close,
      onClick: onClickPress,
      onLongPress
    }) : children
  ), [children, onClickPress, onLongPress]);

  const inner = useMemo(() => (
    typeof children === 'function' ? children({
      isOpen: state === 2,
      isInner: true,
      onTrigger: triggers.close,
      from: a.current
    }) : children
  ), [children, state])

  const opt = options[a.current] || options;

  return (
    <>
      <Tag
        ref={ref}
        {...other}
        className={classNames(styles.actions, className, wrapperClassName, state && styles.hidden)}
        onContextMenu={isMobile ? prevent : compose(onLongPress, prevent)}
      >
        {child}
      </Tag>
      <Portal state={state} side={side} onClose={triggers.close} innerRef={ref} resize={resize}>
        {[
          <Tag key={1} {...other} style={null} className={classNames(styles.portalInner, state === 2 && 'open', className)}>
              {inner}
          </Tag>,
          state !== 0 && <Menu key={2} close={state === 1} options={opt} onTrigger={triggers.close}/>,
        ].sort(() => [sideType.topRight, sideType.topCenter, sideType.topLeft].includes(side) ? 1 : -1)}
      </Portal>
    </>
  );
})
