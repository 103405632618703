import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getCoords } from '@/modules/BoardCalendar/helpers';
import { addMinutes, differenceInDays, differenceInMinutes, eachDayOfInterval, endOfDay } from 'date-fns';
import styles from './Calendar.css';
import { TaskCard } from '@/components/TaskCard/TaskCard';
import { VirtualizedContext } from '@/lib/Virtualized';
import {v4 as genId} from "uuid";
import { useSelector } from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import { groupType } from '../../../types/group';
import { useLongPress } from '@/hooks/useLongPress';
import {useBoard} from "@/modules/Board/BoardContext";

export const Wrapper = ({ firstDay, minMin, dayW, minuteH, children, offsetTop, start, end }) => {
  const [state, setState] = useState({ type: 'end', start, end });
  const { container } = useContext(VirtualizedContext);

  const getCoordsD = getCoords({ target: container.current, offsetTop, firstDay, minMin, dayW, minuteH });

  useEffect(() => {
    const action = (type = 'move') => e => {
      setState(prev => {
        if (prev?.type === 'end') return prev;

        const next = getCoordsD(e);

        return { ...prev, type, end: next };
      })
    };

    const actions = {
      touchmove: action('move'), mousemove: action('move'),
      touchend: action('end'), mouseup: action('end')
    }

    for (const name in actions) window.addEventListener(name, actions[name], { passive: true });

    return () => {
      for (const name in actions) window.removeEventListener(name, actions[name]);
    }
  }, []);

  const init = useCallback(e => {
    e.preventDefault();
    const start = getCoordsD(e);
    setState({ start, end: end || addMinutes(start, 30), type: 'start' })
  }, [getCoordsD]);


  const [s, e] = [state.start, state.end].sort(sortDate);

  return children({ type: state.type, start: s, end: e, init, reset: () => setState({ type: 'end', start, end }) });
}

export const Card = ({ init, reset, ...state }) => {
  const { root, group, start, end } = state;
  const { handleProject } = useBoard();
  const { scrolling } = useContext(VirtualizedContext);

  const ref = useRef();

  const data = {
    id: genId(),
    text: [
      '',
      '\n',
      null,
      null,
    ],
    // TODO: нужно поменять указатель на текущие теги
    entities: [
      { group, id: start?.toISOString() },
      { group, id: end?.toISOString() },
      { group: null, id: root },
    ]
  };

  let opt;

  useLongPress(ref, {
    onStart: () => {
      const { scrollLeft, scrollTop } = scrolling.current;
      opt = { scrollLeft, scrollTop };
    },
    onLongPress: init,
    onMove: ({ x, y }) => {
      const { scrollLeft, scrollTop } = opt;
      scrolling.current.scrollLeft = scrollLeft + x;
      scrolling.current.scrollTop = scrollTop + y;
    },
    onEnd: () => {
      const target = document.querySelector(`[data-task="${data.id}"]`);

      if (!target) return;

      console.log('dataend', data)
      handleProject({ task: data, target });
      setTimeout(reset, 200);
    }
  });

  return (
    <>
      <View {...state} data={data} style={{ pointerEvents: 'none' }}/>
      <div ref={ref} onContextMenu={e => e.preventDefault()} className={styles.create} />
    </>
  )
};

const sortDate = (a, b) => (+a) - (+b);

export const Container = ({ id, ...state }) => {
  const data = useSelector(selectItem(['entity', id]));

  // TODO actions

  return <View {...state} data={data} />;
};

export const View = ({ offsetTop, dayH, minuteH, firstDay, dayW, data, start, end, style: styleProps = {} }) => {
  if (!start || !end) return null;

  const interval = eachDayOfInterval({ start, end });

  const res = [];

  for (const date of interval) {
    const [_, star] = [start, date].sort(sortDate);
    const [en] = [end, endOfDay(date)].sort(sortDate);

    const top = offsetTop + differenceInMinutes(star, date) * minuteH;
    const height = differenceInMinutes(en, star) * minuteH;

    const left = differenceInDays(date, firstDay) * dayW;

    const style = { top, height, left, width: dayW, zIndex: 2, ...styleProps };

    res.push(
      <div key={date.toISOString()} className={styles.add} style={style}>
        <TaskCard className={styles.item} data={data} />
      </div>
    );
  }

  return <>{res}</>
}