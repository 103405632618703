import { selectItem } from '@/store/app/getters';
import { keyBy, pathTo, setTo } from '@/helpers/dash';
import { DEFAULT_COLUMN } from '@/data/tags';
import Fuse from 'fuse.js';
import { addMinutes, addMonths, differenceInDays, differenceInMinutes, eachDayOfInterval, startOfDay } from 'date-fns';
import { IStore } from '@/store/app';
import { mergeDeep } from '@/helpers/dash/mergeDeep';
import { groupType } from '../../types/group';
import { calcSize } from '@/helpers/font';
import { TaskCard } from '@/components/TaskCard/TaskCard';
import { Tag } from '@/components/Base/Tag';
import { format } from '@/components/Base/formatter';
import { stringToColour } from '@/helpers/color';
import { fromUUID } from '@/modules/BoardMap/BoardMap';
import {GroupTypeEnum} from "@/types";
import {isSameFilter, mutation} from "@/helpers/mutate";
import {Error} from "@/components/Error";
import {isFuzzyMatch} from "@/helpers/fuzzy";
import {formatDate} from "@/helpers/date";


const createSelector = (selector) => (
  (...args) => (store) => {
    const res = selector(store, ...args);

    return res;
  }
)

export const selectParentsEntity = (id, type) => (store) => {
  const entity = selectItem(['entity'])(store);
  const parents = [];

  for (const key in entity) {
    const { entities = [], type: t } = entity[key] || {};

    if (!Array.from(entities).some(arg => arg.id === id) || t !== type) continue;

    parents.push(entity[key]);
  }


  return parents
}

export const selectAuth = ({ user = {}, token }) => {
  if (token)
    for (const { id } of Object.values(user))
      if (id && id !== 'user')
        return id;

};

export const selectUser = ({ user = {} }) => Object.keys(user)[0];

export const selectUserData = (state) => {
  const item = Object.values(state.user || {})[0] || {};

  return { ...item, ...selectTag(null, item.id)(state) };
};

export const selectGroupOrder = createSelector(
  (store, project) => {
    const order = selectItem(['project', project, 'groups'], [])(store);
    const members = selectMembers({ tagId: project }, { memberId: project })(store);
    const res = {};

    for (const groupId of order)
      res[groupId] = null;

    for (const { groupId } of members)
      if (groupId)
        res[groupId] = null;

    return Object.keys(res);
  }
);

export const selectProjectsOrder = (filters = [groupType.user, groupType.project]) => store => {
  const user = selectUser(store);
  const members = selectItem(['member'], {})(store);
  const tag = selectItem(['tag'], {})(store);
  const res = {};

  console.log('members', members);

  if (filters.includes(tag[user]?.type))
    res[user] = null;

  for (const id in members) {
    const { tagId, memberId } = members[id] || {};

    if (!tagId || memberId !== user || !filters.includes(tag[tagId]?.type)) continue;

    res[tagId] = null;
  }

  return Object.keys(res);
}

export const selectInvites = (from, group = 'null') => (state) => {
  const res = { [from]: { null: {} } };
  const invite = selectItem(['invite'], {})(state);

  for (const _id in invite) {
    if (!invite[_id]) continue;

    const { fromId, groupId = 'null', id, entities } = invite[_id];

    setTo(res, [fromId, groupId, id], invite[_id]);
  }

  return pathTo(res, [from, group].filter(Boolean));
};


const getById = (id, type) => {
  let data = { type };

  try {
    if (id[0] !== '{') throw true;
    mergeDeep(data, JSON.parse(id));
  } catch (e) {
    data.id = id;
  }

  if (data.type === groupType.user) {
    data.title = 'Неизвестный';
  }

  if (id === 'public') {
    mergeDeep(data, {
      title: 'Все',
      type: groupType.user
    })
  }

  if (id === 'gpt') {
    mergeDeep(data, {
      title: 'AI',
      type: groupType.user
    })
  }

  if (data.type === groupType.date) {
    data.title = formatDate(new Date(id));
    data.thumb = { color: '#999999' };
  }

  if (data.type === groupType.numb) {
    data.title = data.id;
  }

  if (data.type === groupType.location) {
    data.title = fromUUID(data.id).join(':')
  }

  return data;
}

const prepareTag = (data) => {
  if (!data) return data;

  if (!data?.thumb || typeof data?.thumb !== 'object') data.thumb = {};

  if (!data?.thumb?.color) setTo(data, ['thumb', 'color'], stringToColour(data.title));

  return data;
}

export const selectTag = createSelector((store, group, tag) => {
  if (!tag) return {};

  const de = {};
  const invite = selectItem(['invite', tag])(store);

  if (invite) {
    Object.assign(de, { type: 'invite', title: tag, })
  }

  const type = selectItem(['group', group, 'type'])(store);
  const dataByGroup = selectItem(['group', group, 'data', tag])(store)
  const data = selectItem(['tag', tag], de)(store);

  return mergeDeep({ title: 'Неизвестный' }, getById(tag, type), prepareTag(data), prepareTag(dataByGroup));
})

export const selectTags = (items, ignore, types = ['user', 'project']) => store => {
  const res = [];

  for (const item of items) {

    const { id } = item || {};

    if (!id || ignore.includes(id)) continue;

    const tag = selectTag(null, id)(store);

    if (types.includes(tag.type)) res.push(tag.id);
  }

  return res;
}

export const selectorSearch = (id, search) => store => {
    if (!search) search = '';

    const searchResult = pathTo(store, ['app', 'searchResult']);

    if (searchResult) return searchResult;

    const order = selectGroupOrder(id)(store);
    const tag = selectTag(null, id)(store);

    const members = selectMembers( { tagId: id }, { memberId: id })(store)
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.memberId]: selectTag(null, item.memberId)(store),
          [item.tagId]: selectTag(null, item.tagId)(store)
        }),
        // Если юзер, то добавлять его в мемберы
        tag?.type === 'user' ? { [id]: tag } : {},
      );

    delete members[id];
    delete members.null;

    const groups = [];

    for (const id of order) {
      const group = selectItem(['group', id], { id })(store);
      const items = [];
      let findItems = false;

      if (!group) continue;

      for (const tag in (group?.data || {})) {
        delete members[tag];
        const item = format(selectTag(id, tag)(store));

        // Если индексируется тег
        if (!isFuzzyMatch(search, item?.title)) continue;

        findItems = true;
        items.push(item);
      }

      // Если индексируется группа или индексируется тег
      // if (isFuzzyMatch(search, group?.title) || findItems)
        groups.push({ group, items });
    }

    // OTHER
    if (1) {
      const group = { id: null, title: 'Остальные', type: groupType.project };
      const items = [];

      const all = [
        { id: 'public', title: 'Все', type: groupType.user },
        ...Object.values(members),
      ];

      for (const item of all) {
        if (!isFuzzyMatch(search, item?.title)) continue;

        items.push(item);
      }

      groups.push({ group, items });
    }

    return { groups };
  };

export const selectorSearchTask = (id) => store => {
  const res = [];

  const entity = selectItem(['entity'], {})(store);

  for (const key in entity) {
    const item = entity[key];

    if (item && item.type === 'task' && isValidTags(item.entities, id))
      res.push(item)
  }

  return res;
}

const isValidTags = (is) => ({ id }) => id === is;

const withFilters = (tags, filters = {}) => {
  for (const group in filters) {
    for (const tag in filters[group]) {
      if (!tags?.some(item => item.group === group && item.id === tag))
        return false;
    }
  }
  return true;
};

export const selectTaskOrder = ({ id: refer, group = 'undefined', filters, width }, is = true) => store => {
  const nextStore = is ? selectItem([])(store) : store;

  const res = { [DEFAULT_COLUMN]: [] };
  const other = {};

  // GROUPS ITEMS
  const tags = pathTo(nextStore, ['group', group, 'data'], {}) as Record<string, any>;
  const order = pathTo(nextStore, ['group', group, 'order'], []) as [];

  // DEFAULT
  const entities = pathTo(nextStore, ['entity'], {});

  for (const id of (order || [])) {
    if (!tags[id]) continue;
    res[id] = [];
  }

  for (const id in tags) {
    if (!tags[id]) continue;

    const items = {};

    const { tasks = [] } = tags[id];

    for (const i of tasks) {
      const { entities: tags = [] } = entities[i] || {};

      if (tags.some(isValidTags(refer)) && withFilters(tags, filters)) {
        items[i] = null;
      }
      delete other[i];
    }

    res[id] = items;
  }

  for (const id in entities) {
    if (!entities[id]) continue;

    const { entities: tags = [], type, text = [], $delete } = entities[id];

    if ($delete || !text.length || type !== 'task') continue;

    if (!tags.some(isValidTags(refer)) || !withFilters(tags, filters)) continue;

    other[id] = null;

    for (const item of tags) {
      if (item.group === group && item.id) {
        setTo(res, [item.id, id], null);
        delete other[id];
      }
    }
  }

  for (const key in res)
    res[key] = Object.keys(res[key])

  // DEFAULT
  res[DEFAULT_COLUMN] = Object.keys(other);

  return res;
}

export const selectMessages = ({ project, task }) => store => {
  const res = [];

  const members = keyBy(
    selectMembers( { memberId: project }, { tagId: project })(store),
    'key',
    item => ({ ...item, key: [item.memberId, item.tagId].find(id => id !== project) }),
  );

  const nextStore = selectItem([])(store);

  const user = selectAuth(store);

  const entities = pathTo(nextStore, ['entity'], {});

  for (const id in entities) {
    const item = entities[id];
    if (!item) continue;

    const { entities: tags, text = [], $delete, type, createdAt, createdBy } = item;

    if ($delete || !text.length || type !== 'message') continue;

    if (!tags.some(isValidTags(task))) continue;

    const tm = + new Date(createdAt);

    res.push({
      ...item,
      createdAt: tm,
      project: tags.find(tag => members[tag.id])?.id,
      isSelf: createdBy === user
    });

  }

  return res.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1);
}

export const selectTaskOrderTime = ({ id: refer, group, filters, firstDay, dayH, dayW, minuteH }) => store => {
  const res = [], times = {};

  const nextStore = selectItem([])(store);

  const entities = pathTo(nextStore, ['entity'], {});

  for (const id in entities) {
    try {
      const { entities: tags, text = [] } = entities[id] || {};

      if (!text.length) continue;

      if (!(tags.some(isValidTags(refer)) && withFilters(tags, filters))) continue;

      let [start, end] = tags
        .filter(item => item.group === group)
        .map(item => (
          new Date(item.id)
        ));

      if (!start) continue;

      if (!end) end = addMinutes(start, 30);

      times[id] = { start, end };

      const y = 'current'; // dayH + differenceInMinutes(st, date);
      const h = differenceInMinutes(end, start);
      const x = differenceInDays(start, firstDay) * dayW;
      const days = eachDayOfInterval({ start, end });

      res.push({ id, x, y, h: h * minuteH, w: days.length * dayW });
    } catch (e) {
      console.error(e);
      continue;
    }
  }

  return [res, times];
}

export const selectSelection = store => {
  const selection = selectItem(['app', 'selection'], [])(store);
  const filters = {};
  const order = [];

  for (const id of selection) {
    const item = selectItem(['entity', id], {})(store);

    if (!item) continue;

    const { entities = [] } = item;

    order.push(id);

    for (const item of entities) {
      setTo(filters, [item.group, item.id], (prev = []) => [...prev, id]);
    }
  }

  for (const group in filters) {
    for (const tag in filters[group]) {
      const items = filters[group][tag];

      if (items.length === order.length)
        filters[group][tag] = true;
      else
        delete filters[group][tag];
    }

  }

  return [order, Boolean(order?.length) && filters]
}

const selectProjects = createSelector(
  (store, user) => {
    const member = selectItem(['member'], {})(store);
    const tags = selectItem(['tag'], {})(store);
    const res = [];

    for (const id in member) {
      const { memberId, groupId, tagId } = member[id] || {};

      if (memberId !== user || groupId) continue;

      res.push(tagId);
    }

    return res;
  }
)

export const selectFilters = ({ project, type, invite, task }) => (store) => {
  const user = selectAuth(store);
  const projects = selectProjects(user)(store);
  const members = selectMembers()(store);
  const history = selectItem(['history'], {})(store);
  const { groups = {} } = selectorSearch(project)(store);

  const tags = new Set(), group = new Set();

  const filters = {
    history: [
      { createdBy: { $not: [user] }, changes: { $create: true } },
      (type === 'stats') && { model: 'entity', entities: { $contained: [project] } },
    ],
    payment: [{ user, createdAt: { $gt: addMonths(startOfDay(new Date()), -1) } }],
    tag: [],
    invite: [],
  };

  for (const id of projects)
    filters.tag.push({ id });

  for (const key in history) {
    const ac = filters;
    const { model, ref } = history[key];

    if (!['invite', 'tag'].includes(model)) continue;

    if (!(model in ac)) ac[model] = [];

    ac[model].push({ id: ref });
  }

  if (project) {
    const member = [{ memberId: project }, { tagId: project }];

    for (const { group: g, items } of groups) {
      if (g?.id) {
        group.add(g.id);
        member.push({ groupId: g.id });
      }
      for (const item of items)
        tags.add(item?.id)
    }

    for (const { memberId, tagId, groupId } of members) {
      tags.add(memberId);
      tags.add(tagId);
      group.add(groupId);
    }

    mergeDeep(filters, {
      project: [{ id: project }],
      /* TODO: by members */
      group: Array.from(group).filter(Boolean).map(id => ({ id })),
      member,
      entity: [{ type: 'task', entities: { $contains: [{ id: project }] } }],
      tag: Array.from(tags).filter(Boolean).map(id => ({ id })),
      invite: [{ fromId: project }]
    });
  }

  if (task)
    mergeDeep(filters, {
      entity: [{ id: task }, {
        type: 'message',
        entities: { $contains: [{ id: project }, { type: 'task', id: task }] }
      }, {
        type: 'message',
        entities: { $contains: [{ id: user }, { type: 'task', id: task }] }
      }],
    })

  if (invite)
    filters.invite.push({ id: invite })

  return filters;
}

export const selectNotification = (id, type, filter) => store => {
  const items = selectItem(['history'], {})(store);

  let res = Object.values(items);

  if (id && type && filter)
    res = res.filter(history => {
      const { model, entities = [], changes } = history;
      const item = mutation({}, changes);

      return entities.includes(id) && isSameFilter(item, filter);
    })

  return res.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
}

export const selectRole = (id) => store => {
  const user = selectAuth(store);
  const member = selectItem(['member'])(store);

  for (const memberKey in member) {
    if (!member[memberKey]) continue;
    const item = member[memberKey];
    const { role, tagId, memberId } = item;
    if (tagId === id && memberId === user)
      return item;

  }

  return {};
}

export const selectMembers = (...args) => store => {
  const members = selectItem(['member'], {})(store);

  if (!args.length) args.push({});

  const res = new Set();

  for (const arg of args) {
    const keys = Object.keys(arg);

    for (const id in members) {
      const item = members[id];

      if (!item || res.has(item)) continue;

      if (!keys.every(key => arg[key] === item[key])) continue;

      res.add(item);
    }
  }

  return Array.from(res);
}

export const selectPayments = (...args) => store => {
  const payments = selectItem(['payment'], {})(store);

  if (!args.length) args.push({});

  const res = new Set();

  for (const arg of args) {
    const keys = Object.keys(arg);

    for (const id in payments) {
      const item = payments[id];

      if (!item || res.has(item)) continue;

      if (!keys.every(key => arg[key] === item[key])) continue;

      res.add(item);
    }
  }

  return Array.from(res);
}


export const selectMedias = store => {
  const res = [];

  const entity = selectItem(['entity'])(store);
  const invite = selectItem(['invite'], {})(store);
  const tag = selectItem(['tag'])(store);
  const media = selectItem(['media', 'items'], {})(store);

  for (const id in entity) {
    const { entities = [] } = entity[id] || {};

    for (const { id, type } of (Array.from(entities) || []))
      if (type === 'media' && !(id in media))
        res.push(id);
  }

  for (const id in invite) {
    const { thumb } = invite[id]?.from || {};

    res.push(thumb?.media);
  }

  for (const id in tag) {
    const { thumb } = tag[id] || {};

    res.push(thumb?.media);
  }

  return res.filter(Boolean);
}
