import React, { useEffect, useRef, useState } from 'react';
import {shallowEqual, useSelector} from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import styles from './Media.css';
import classNames from 'classnames';
import Skeleton from '@/ui/Skeleton/Skeleton';
import { Actions } from '@/components/Actions/Actions';
import { AudioComponent } from '@/components/Player/Audio';
import { getMediaPath, observers } from '@/components/Media/helpers';

const onLoad = (e) => {
  e.target.dataset.loaded = true;
};

const prev = {};

const Image = ({ sizes = {}, base64, baseUrl, width, type, id, ratio, className, style = {} }) => {
  const ref = useRef();
  const [state, setState] = useState(prev[id] || getMediaPath({ id, type, width }, baseUrl, 300, sizes));

  const preview = {
    '--ratio': ratio?.toFixed(2),
    '--src': base64 && `url("${base64 || id}")`,
  };

  useEffect(
    observers(() => {
      if (!type) return;
      const { width: max = 1 } = ref?.current?.getBoundingClientRect() || {};
      const next = getMediaPath({ id, type, width }, baseUrl, max, sizes);
      prev[id] = next;
      setState(next);
    }, ref)
    , [id, type]);

  return (
    <picture ref={ref} className={classNames(styles.root, className)} style={{...preview, ...(style || {})}}>
      <img loading="lazy" className={classNames(styles.img)} src={state} onLoad={onLoad} data-loaded={id in prev}/>
    </picture>
  )
}

const ext = {
  img: ['jpg', 'jpeg', 'png'],
  video: ['mov', 'avi', 'mp4'],
  audio: ['mp3', 'wav', 'ogg', 'flac'],
  gif: ['gif'],
  file: ['pdf', 'xlsx', 'xls', 'dfx', 'eps', 'psd', 'ps', 'svg', 'tif', 'ttf', 'ppt', 'pptx', 'doc', 'docx', 'odt', 'pdf', 'pages', 'xps'],
}

export const ImageContainer = ({ style, className, styles: exts = {}, id }) => {
  const baseUrl = useSelector(selectItem(['media', 'cdnPath'], ''), shallowEqual, []);
  const sizes = useSelector(selectItem(['media', 'sizes'], ''), shallowEqual, []);
  const data = useSelector(selectItem(['media', 'items', id]), shallowEqual, [id]);

  return <Image sizes={sizes} {...data} baseUrl={baseUrl} className={classNames(className, exts[data?.type])} style={style} />;
}

const def = {};

export const Media = ({ id, className, options = [] }) => {
  const baseUrl = useSelector(selectItem(['media', 'cdnPath'], ''));
  const sizes = useSelector(selectItem(['media', 'sizes'], def));
  const data = useSelector(selectItem(['media', 'items', id], def), shallowEqual, [id]);
  const { type = 'jpg', name } = data;

  if (!id) return <Skeleton />;

  let link = `${baseUrl}/${id}/src.${type}`, base = link;

  let body = (
    <a href={base} target='_blank' className={classNames(styles.root, styles.other, className)} children={name} />
  );

  if (ext.img.includes(type)) {
    body = <Image {...data} id={id} sizes={sizes} baseUrl={baseUrl} className={className} />
    base = `${baseUrl}/${id}/large.${type}`;
  }

  if (ext.gif.includes(type)) {
    body = (
      <div classNames={classNames(styles.root, styles.other, className)}>
        <img src={link} />
      </div>
    )
  }

  if (ext.file.includes(type)) {
    body = (
      <iframe
        src={`${baseUrl}/${id}/src.${type}`}
        className={classNames(styles.root, styles.iframe, className)}
      />
    )
  }

  if (ext.video.includes(type.toLowerCase())) {
    body = (
      <video controls className={classNames(styles.root, styles.video, className)}>
        <source src={`${baseUrl}/${id}/src.${type}`} />
      </video>
    )
    base = null;
  }

  if (ext.audio.includes(type)) {
    body = <AudioComponent src={`${baseUrl}/${id}/src.${type}`} {...data} />
    base = null;
  }

  return (
    <Actions
      onClick={() => null}
      options={[{ title: 'Скачать', onClick: () => window.open(link, '_blank') }, ...options]}
      children={body}
    />
  )
}