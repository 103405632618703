import * as pako from "pako";
import React, {memo, useEffect, useRef} from "react";
import styles from './Lottie.css';
import {yieldToMain} from "@/hooks/useSocket";

const stack = {};

const get = async (url) => {
    if (!stack[url]) {
        const res = await fetch(url).then(r => r.arrayBuffer());
        const data = await pako.inflate(res, { to: "string" });

        stack[url] = JSON.parse(data);
    }

    return stack[url];
}

export const Lottie = ({ hover, loop = false, autoplay, mode, src }) => {
    const ref = useRef();
    const anim = useRef();

    useEffect(() => {
        if (!ref.current) return;
        let arg = src + '.tgs';

        get(arg)
            .then(async animationData => {
                await yieldToMain();
                anim.current = lottie.loadAnimation({
                    name: arg,
                    container: ref.current,
                    animationData,
                    renderer: 'canvas',
                    loop,
                    mode,
                    autoplay,
                    rendererSettings: {},
                })
            })

        return () => {
            anim.current?.destroy();
        }
    }, []);

    return (
      <div ref={ref} onClick={() => { anim.current?.goToAndPlay(0) }} className={styles.root} />
    );
}