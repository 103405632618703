import React, {useEffect} from 'react';
import { Actions } from '@/components/Actions/Actions';
import { TagContainer } from '@/components/Base/Tag';
import { TaskCardContainer } from '@/components/TaskCard/TaskCard';
import { Media } from '@/components/Media/Media';
import {Link} from "@/components/Link/Link";
import {Code} from "@/components/Code/Code";
import {StatsContainer} from "@/modules/Stats/Stats";

const wrap = (Render) => (args) => {
  return (
    <Actions
      Tag='span'
      children={<Render {...args} onUpdate={(upd) => { Object.assign(args.node.dataset, upd) }}/>}
      onClick={() => {}}
      options={[{ title: 'Удалить', onClick: () => { args.node.remove(); } }]}
    />
  )
}

export const config = {
  tag: wrap(TagContainer),
  link: wrap(Link),
  code: (args) => <Code {...args} />,
  // draw: (args) => <Draw {...args} />,
  stat: () => <StatsContainer />,
  task: (args) => <TaskCardContainer {...args} {...{ options: [{ title: 'Удалить', onClick: () => { args.node.remove(); } }], isSub: true }} />,
  media: (args) => <Media {...args} options={[{ title: 'Удалить', onClick: () => { args.node.remove(); } }]} />
};