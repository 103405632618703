import React, {useEffect, useRef, useState} from 'react';
import { InputWrapper } from '@/ui/Input/InputWrapper';
import styles from './Input.css';
import classNames from 'classnames';
import {regexReplace} from "@/components/Editor/helpers";
import {resolve} from "@/components/Editor/Mention";
import {change, inject} from "@/ui/Input/mask";
import {compose} from "@/react-redux";

export interface InputProps {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  error?: string;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  extraProps?: any;
  enterButton?: any;
  component?: any;

  mask?(e: any): any;

  onBlur?(e: any): any;
  onChange?(e: any): any;
  onFocus?(e: any): any;
}

export const InputMask: React.FC<InputProps> = (props) => {
  const { className, name, value, mask, onChange, onFocus, onBlur } = props;
  const ref = useRef();

  const { masked, placeholder: maskPlaceholder } = inject({ mask, value });

  const onInput = compose(value => onChange({ target: { value } }), change(mask, masked));
  const onSelect = (e) => {
    e.target.prevStart = e.target.selectionStart;
    e.target.prevEnd = e.target.selectionEnd;
  }

  return (
    <>
      <input
        ref={ref}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        onSelect={onSelect}
        onInput={onInput}
        value={masked}
        className={styles.input}
      />
      <span className={styles.placeholder}>
         <span className={styles.hidden}>{masked}</span>
        {maskPlaceholder}
      </span>
    </>
  )
};
