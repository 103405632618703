import React from 'react';
import styles from './Label.css';
import classNames from 'classnames';

export const Label: React.FC = ({ className, ...other }) => (
  <div
    className={classNames(styles.root, className)}
    {...other}
  />
)
