import React from 'react';
import { Footer } from '@/modules/Footer/Footer';
import { LayoutMessages } from './LayoutMessages';
import { useViewport } from './useViewport';
import { Sync, Load, Actions } from './hooks';
import {Context} from "@/modules/Board/BoardContext";
import {Loader} from "@/ui/Loader/Loader";
import {useSelector} from "@/react-redux";
import {selectItem} from "@/store/app/getters";

const LoaderContainer = () => {
  const is = useSelector(selectItem(['app', 'loader']));

  return <Loader style={{ opacity: Number(is) }} />;
}

export const Layout: React.FC = ({ children }) => {

  useViewport();

  return (
    <>
      <LoaderContainer/>
      <Context />
      <Actions />
      <Load />
      <Sync />
      <LayoutMessages />
      {children}
      <Footer />
    </>
  );
};
