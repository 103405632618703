import {useBoard} from "@/modules/Board/BoardContext";
import {selectInvites, selectMembers} from "@/store/selectors";
import {actions} from "@/hooks/actions";
import {roles} from "@/data/member";
import {shallowEqual, useSelector} from "@/react-redux";
import React from "react";
import Close from '@/ui/icons/close.svg';
import {TagContainer} from "@/components/Base/Tag";
import {copyToClipboard} from "@/helpers/copyToClipboard";
import {sendMessage} from "@/components/Layout/LayoutMessages";

const options = [{ name: <Close />, value: null }]
    .concat(
        Object.entries(roles).map(([key, name]) => ({
            value: key,
            name,
        }))
    );

export const useRole = (memberId?) => {
    const { id } = useBoard(['id']);
    const members = useSelector(selectMembers(memberId ? { tagId: id, memberId } : { tagId: id }), shallowEqual, [memberId, id]);

    if (!members.length && memberId === 'public')
        members.push(
            {
                tagId: id,
                memberId,
                role: null,
            }
        )

    const items = members.map(member => ({
        id: member.memberId,
        title: <TagContainer id={member.memberId} isLock />,
        switch: {
            value: member?.role,
            options,
            onChange: role => actions.members.update({ ...member, role }),
        }
    }))

    if (!memberId || memberId === 'public') return items;

    return ({ ...items[0], title: 'Роль' })
}

export const useInvites = () => {
    const { id } = useBoard(['id']);
    const invites = useSelector(selectInvites(id), shallowEqual, [id]);

    return (
        Object.values(invites || {})
            .map((invite) => ({
                title: (
                    <TagContainer
                        isLock
                        id={invite.id}
                        onClick={() => {
                            copyToClipboard(window.location.origin + '/invite/' + invite.id);
                            sendMessage({ text: 'Приглашение скопировано' });
                        }}
                    />
                ),
                switch: {
                    value: invite.role,
                    options,
                    onChange: role => actions.invites.update({ id: invite.id, role }),
                },
            }))
    )
}