import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './LayoutMessages.css';
import classNames from 'classnames';

let setMessages = () => {};
export let sendMessage = () => {};

export enum messageType {
  error = 'error',
  info = 'info',
  success = 'success'
}

const icons = {
  [messageType.info]: '🙃️',
  [messageType.success]: '😃',
  [messageType.error]: '😞',
}

const Message: React.FC<any> = ({ type = messageType.info, text, onClick }) => {

  const icon = icons[type];

  return (
    <div className={styles.itemWrapper} onClick={onClick}>
      <div className={classNames(styles.item, styles[type])}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {text && <span className={styles.text}>{text}</span>}
      </div>
    </div>
  );
}

export const LayoutMessages = () => {
  const ref = useRef(null);
  const [items, set] = useState([]);

  setMessages = set;
  sendMessage = useCallback(item => set((items = []) => [...items, { ...item, id: + new Date() }]), []);

  const pop = useCallback(() => setMessages(([_, ...other] = []) => other), []);

  useEffect(() => {
    if (items.length) {
      if (ref.current) clearTimeout(ref.current);
      ref.current = setTimeout(pop, 3000);
    }
  }, [items]);

  const next = items.slice(0 , 2);

  return (
    <div
      className={styles.wrapper}
      children={next.map(item => <Message key={item.id} {...item} onClick={pop} />)}
    />
  );
}
