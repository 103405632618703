import { useSelector } from '@/react-redux';
import { useEffect, useMemo } from 'react';
import { Actions } from '@/components/Actions/Actions';
import classNames from 'classnames';
import * as React from 'react';
import { setItem } from '@/store/app/actions';
import {state, useBoard} from "@/modules/Board/BoardContext";
import Trash from '@/ui/icons/trash.svg'
import Widget from '@/ui/icons/widget.svg'
import Tick from '@/ui/icons/tick.svg'
import { order } from '@/modules/Board/BoardContainer';
import styles from './TaskCard.css';
import { pathStore, store } from '@/store/client';
import { pathTo } from '@/helpers/dash';
import { actions } from '@/hooks/actions';
import { useMeasure } from '@/hooks/useMeasure';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { domain } from "config.json";
import { sendMessage } from '@/components/Layout/LayoutMessages';

export const setSelection = args => store.dispatch(setItem(['app', 'selection'], args));

const onClick = (id, e) => {
  const selection = pathStore(['app', 'selection'], []);

  if (!(e.ctrlKey || e.metaKey || e.shiftKey || selection?.length)) return true;

  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();

  if (e.ctrlKey || e.metaKey || selection?.length) {
    setSelection((prev = []) => {
      const selected = prev.includes(id);

      return selected ? prev.filter(i => i !== id) : [...prev, id];
    });
  }

  if (e.shiftKey) {
    setSelection((selection = []) => {
      if (!selection.length) return [id];

      const has = selection.reduce((acc, item) => { acc[item] = true; return acc; }, {})

      let from = null, to = null, res = [];

      for (const key in order) {
        for (const item of order[key]) {
          if (from && to) continue;

          from = from || has[item];
          to = to || item === id;

          if (!(from || to)) continue;

          res.push(item);
        }
      }

      return res;
    });
  }
}

const isSelected = id => store => {
  const selection = pathTo(store, ['app', 'selection'], []);

  return selection.includes(id);
}

export const TaskCardWrapper = props => {
  const { id, data, children, isSub, style, className, onSize } = props;

  const { ref, bounds } = useMeasure();

  useEffect(() => {
    if (!bounds.height) return;

    const scale = bounds.height / ref.current.offsetHeight;

    if (onSize) onSize({ [id]: bounds.height / scale });
  }, [bounds.height])

  const { handleProject } = useBoard();

  const selected = useSelector(isSelected(id));

  const options = useMemo(() => (
    [
      {
        title: 'Выбрать',
        icon: Tick,
        onClick: e => {
          e.preventDefault();
          setSelection((selection = []) => selected ? selection.filter(i => i !== id) : [...selection, id])
        }
      },
      // {
      //   title: 'Поделиться',
      //   icon: Share,
      //   onClick: e => {
      //     e.preventDefault();
      //     actions.invites.create()
      //     setSelection((selection = []) => selected ? selection.filter(i => i !== id) : [...selection, id])
      //   }
      // },
      {
        title: 'Создать виджет',
        icon: Widget,
        onClick: e => {
          const project = pathStore(['tag', state.id]);
          const { entities, text } = data;

          console.log(data);

          copyToClipboard(`
            <link rel="stylesheet" href="${domain}/widget.css">
            <script>window.juspWidget = [${JSON.stringify({ project, task: { entities, text } })}]</script>
            <script type="module" src="${domain}/widget.js" defer=""></script>
          `)

          sendMessage({ text: 'Виджет скопирован' })
        }
      },
      {
        title: 'Удалить',
        icon: Trash,
        onClick: (e) => {
          e.preventDefault();
          if (confirm('Вы уверены?')) {
            actions.entities.delete(id);
          }
        }
      }
    ]
  ), [selected, data, id])

  return (
    <Actions
      ref={ref}
      options={props.options || options}
      onClick={(e, target) => {
        if (!onClick(id, e))
          return;
        else if (props.onClick)
          props.onClick(data, target);
        else
          handleProject({ task: data, target });
      }}
      data-task={id}
      style={style}
      wrapperClassName={styles.wrapper}
      className={classNames(styles.root, selected && styles.selected, isSub && styles.sub, className)}
      children={children}
    />
  );
}
