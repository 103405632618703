import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from '@/react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Workbox } from 'workbox-window';

import Root from '@/components/root';
import { store } from '@/store/client';
import { Placeholder } from '@/components/Dnd/Dragg';
import { setItem } from '@/store/app/actions';
import { ws } from '@/hooks/useSocket';
import { ErrorBoundary } from '@/components/Error/ErrorBoundary';

global.isSub = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})()

if ('clearAppBadge' in navigator) {
  navigator.clearAppBadge();
}

let wb;

if ('serviceWorker' in navigator) {
  wb = new Workbox('/sw.js', { type: 'module' });

  wb.addEventListener('waiting', () => {
    wb.messageSW({ type: 'SKIP_WAITING' });
  });

  wb.addEventListener('controlling', () => {
    window.location.reload();
  });

  wb.addEventListener('message', event => {
    if (event.data && event.data.type === 'UPDATE_AVAILABLE') {
      window.location.reload();
    }
  })

  wb?.register();
}

const node = document.getElementById('root');
let root;

export const history = createBrowserHistory({ window });

export const rerender = async (clean = false) => {
  if (clean) {
    wb?.update();
    navigator.serviceWorker?.controller?.postMessage('clearCache');
    store.dispatch(setItem([], ({ token }) => ({ token })));
    ws.close();
  }

  root?.unmount();

  root = createRoot(node);

  root.render(
      <HistoryRouter history={history}>
        <Provider store={store}>
          <ErrorBoundary>
            <Root />
            <Placeholder />
          </ErrorBoundary>
        </Provider>
      </HistoryRouter>
  );
}

void rerender();

if (!global.isSub) {
  window.juspWidget = [{
    project: {
      title: '?',
      thumb: {
        color: '#666666',
      },
      ticket: '',
      type: 'project',
      id: '888589e2-714b-4138-9f28-638f35f1a2a2',
    }
  }]
}