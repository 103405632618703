import { groupType } from '@/data/groups';
// 'мес', 30 * 24 * 60,
const regExps = ['д', 'ч', 'м'].map(name => new RegExp(`(?<n>[0-9]+)\\s+?${name}`, 'i'));
const count = [24 * 60, 60, 1];

// [\p{Alpha}\p{M}\p{Pc}\p{Join_C}]
const regs = [
  // TODO: вырезать время
  /(?<d>\d{1,2})(\/|\.|-|\s*)?(?<M>\p{Alpha}+|\d{1,2})(\/|\.|-)?\s*(?<y>\d{2,4})?/iu, // 7 янв. 2022 | 07.01.2022
  /(?<h>\d{1,2}):(?<m>\d{2})/i, // 00:00
  /(?<h>\d+)\s*[hч]/i, // 12 часов
  /(?<m>\d+)\s*[mм]/i, // 20 минут
]

const months = [/янв|jan/i, /фев|feb/i, /мар|mar/i, /апр|apr/i, /май|may/i, /июн|jun/i, /июл|jul/i, /авг|avg/i, /сен|sep/i, /окт|oct/i, /ноя|nov/i, /дек|dec/i];

export const parser = {
  [groupType.date]: str => {
    const res: any = {};

    for (const reg of regs) {
      const { groups } = reg.exec(str) || {};

      if (groups) Object.assign(res, groups);
    }
    const { M, d, y, h, m } = res;

    const before = `${months.findIndex(reg => reg.exec(M)) + 1 || M} ${d} ${y} ${h}:${m}`;

    const date = new Date(before);
    if (date.getTime() !== date.getTime()) throw Error('Не верный формат');

    return '' + (+date) / 1000;
  },
  [groupType.duration]: str => {
    let res = 0;

    for (let i = 0; i < regExps.length; i++) {
      const { groups: { n } } = regExps[i].exec(str) || { groups: {} };
      if (typeof n !== 'undefined') res += n * count[i];
    }

    if (res === 0) throw Error('Не верный формат');

    return '' + res;
  },
}