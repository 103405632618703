import genStore from '@/react-redux';
import { setItem,  } from "@/store/app/actions";
import { selectItem } from '@/store/app/getters';

export const CACHE_NAME = 'state';

export const store = genStore(
  JSON.parse(localStorage.getItem(CACHE_NAME) || '{}')
  // (window as any).__REDUX_STORE__
);

const { setItems: setItemsAction } = store.actions;

store.dispatch(setItemsAction([[['app', 'loader'], true]]));

window.store = store;
window.setNext = (args) => store.dispatch(setItemsAction([[['next'], args]]));

store.subscribe(() => {
  const { messages, history, entity, ...data } = store.getState() || {};

  try {
    localStorage.setItem(CACHE_NAME, JSON.stringify(data));
  } catch (e) {
    console.error(e, data);
  }
})

export const pathStore = (...args) => selectItem(...args)(store.getState());
