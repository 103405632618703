import React, {useCallback, useEffect, useRef, useState} from 'react';
import IconPlay from '@/ui/icons/play.svg';
import IconStop from '@/ui/icons/stop.svg';
import IconOpen from '@/ui/icons/open.svg';
import styles from './Video.css';
import {shareActions, useShare, useShareE} from "@/hooks/useShare";
import {useBoard} from "@/modules/Board/BoardContext";

const players = {};

const timer = () => {
  const minutes = Math.floor(media.currentTime / 60);
  const seconds = Math.floor(media.currentTime - minutes * 60);

  const minuteValue = minutes.toString().padStart(2, "0");
  const secondValue = seconds.toString().padStart(2, "0");

  return `${minuteValue}:${secondValue}`;
}

const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

export const Video = ({ src }) => {
  const ref = useRef();

  const [{ player, current = 0 }, setState] = useState({});
  const { task } = useBoard(['task'])
  const [_, share] = useShareE({ filter: { entity: { id: task?.id } }, action: shareActions.video },
    ({ paused = '', current }) => {
      if (typeof paused === 'boolean' && player?.paused !== paused)
        player[paused ? 'pause' : 'play']()

      if (typeof current === 'number' && Math.abs(player.currentTime - current) > 15)
        player.currentTime = current;
    });

  useEffect( () => {
    let act = {};

    if (!(src in players)) {
      players[src] = document.createElement('video');
      players[src].preload = 'none';
      players[src].src = src;
    }

    const player = players[src];
    ref.current.appendChild(player);

    act = {
      play: () => {
        share({ paused: false, current: player.currentTime })
      },
      pause: () => {
        share({ paused: true, current: player.currentTime })
      },
      timeupdate: () => {
        setState(prev => ({ ...prev, current: player.currentTime }));
      },
      ended: () => {
      }
    }

    for (const actKey in act) player.addEventListener(actKey, act[actKey]);

    setState({ player });

    return () => {
      for (const actKey in act) player.removeEventListener(actKey, act[actKey]);
    }
  }, []);

  const paused = player?.paused;
  const Icon = paused ? IconPlay : IconStop;
  const onFull = () => openFullscreen(player);

  return (
    <div className={styles.root}>
      <div ref={ref} onDoubleClick={onFull}/>
      <div className={styles.controls}>
        <Icon
          className={styles.icon}
          onClick={() => player[paused ? 'play' : 'pause']()}
        />
        <div className={styles.line}>
          <div className={styles.active} style={{ width: `${current / (player?.duration || 1) * 100}%` }}></div>
        </div>
        <IconOpen
          className={styles.icon}
          onClick={onFull}
        />
      </div>
    </div>
  )
}