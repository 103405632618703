import React from 'react';
import styles from './Day.css';
import { format, getDay, isToday } from 'date-fns';
import { ru } from 'date-fns/locale';
import classNames from 'classnames';

const names = {
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
  0: 'Вс',
}

const months = ['', 'Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сент', 'Окт', 'Ноя', 'Дек']

export const Day: React.FC = ({ day, style }) => {

  const name = names[getDay(day)];
  const d = format(day, 'd');
  const m = format(day, 'M');
  const alt = format(day, 'dd MMMM', { locale: ru });
  const isCurrent = isToday(day);

  return (
    <div className={styles.root} style={style}>
      <div className={classNames(styles.day, isCurrent && styles.current)} title={alt}>
        <span>{name}<b>{d} {months[m]}</b></span>
      </div>
    </div>
  );
}
