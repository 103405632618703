import { useSelector } from '@/react-redux';
import * as React from 'react';
import { Helmet } from './Helmet';
import { selectItem } from '@/store/app/getters';
import { themeMode } from '@/data/types';
import { useParams } from 'react-router';
import {themeDef} from "@/modules/Theme/Theme";

interface IHeaderProps {
  title?: string;
  description?: string;
}

export const themePath = ['app', 'theme'];
const animationPath = ['app', 'animation'];

export let theme = null;

export const HelmetContainer: React.FC<IHeaderProps> = props => {
  const { type, id } = useParams();
  theme = useSelector(selectItem(themePath, themeDef[0]));
  const animation = useSelector(selectItem(animationPath, true));

  return <Helmet theme={theme} animation={animation} id={!type && id} {...props} />
}
