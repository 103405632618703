import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import styles from './AppPage.css';

export const AppPage = () => {
  const { id } = useParams();
  const [state, setState] = useState({});

  const { name, icons = [] } = state;

  useEffect(() => {
    fetch(`/manifest.json?id=${id}`)
      .then(args => args.json())
      .then(setState);
  }, []);

  return (
    <div className={styles.root}>
      <style>{`#header { display: none; }`}</style>
      <img className={styles.logo} src={icons[0]?.src}/>
      <h1 className={styles.h1}>{name}</h1>
    </div>
  );
}