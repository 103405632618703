export enum roleType {
  admin = 'admin',
  partner = 'partner',
  guest = 'guest',
}

export const roles = {
  [roleType.guest]: 'Гость',
  [roleType.partner]: 'Участник',
  [roleType.admin]: 'Админ',
}