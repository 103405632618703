import React, { useMemo, useRef } from 'react';
import {shallowEqual, useSelector} from '@/react-redux';
import { TaskCardWrapper } from '@/components/TaskCard/TaskCardWrapper';
import { selectItem } from '@/store/app/getters';
import styles from '@/components/Task/Task.css';
import stylesCard from './TaskCard.css';
import { Editor } from '@/components/Editor/Editor';
import { convert } from '@/components/Task/Task';
import {useBoard} from "@/modules/Board/BoardContext";
import Plus from '@/ui/icons/plus.svg';
import {v4 as genId} from "uuid";
import { Media } from '@/components/Media/Media';
import {selectParentsEntity, selectTags} from '@/store/selectors';
import classNames from 'classnames';
import {TagContainer} from "@/components/Base/Tag";

interface ITaskCardProps {
  task: ITask;
  tags: any[];

  loading?: boolean;

  onCreate?(args?: any): void;
  onUpdate?(args?: any): void;
  onDelete?(args?: any): void;
}

export const Create = ({ style, className, tags }) => {
  const { id: project, handleProject } = useBoard(['id', 'handleProject']);
  const node = useRef();

  const onOpen = () => {
    handleProject({ task: { id: genId(), text: ['', '\n', ...tags.map(() => null)], entities: [...tags, { id: project, group: null }] }, target: node.current })
  }

  return (
    <div data-name='task-new' style={style} className={classNames(stylesCard.btn, className)} ref={node} onClick={onOpen}>
      <Plus /> Добавить тикет
    </div>
  )
}

const custom = {
  media: () => <div style={{ opacity: .25 }}>🖼️ Файл</div>,
  task: () => <div style={{ opacity: .25 }}>Подзадача</div>,
  link: () => <div style={{ opacity: .25 }}>Ссылка</div>,
}

export const TaskCard = ({ data, parents, ...other }) => {
  const { id: project, handleProject } = useBoard();
  const { id, text, entities } = data;

  const { value, hidden, thumbId } = useMemo(() => {
    const [value, hidden] = convert.to(text, entities);

    const thumbId = value.find(item => item?.type === 'media');

    return { value, hidden, thumbId };
  }, [text, entities]);

  const projects = useSelector(selectTags([...value, ...hidden], [project]), shallowEqual)

  const thumb = thumbId && <div className={styles.thumb}><Media {...thumbId} /></div>;

  const openTask = id => e => {
    e.stopPropagation();
    handleProject({ task: { id }, target: document.querySelector(`[data-task="${id}"]`) || e.target });
  }

  return (
    <TaskCardWrapper {...other} id={id} data={data}>
      {/*<div style={{ height: 400 }}></div>*/}
      {parents?.slice(0, 1).map(item => (
        <div
          className={styles.parents}
          onMouseUp={openTask(item.id)}
          onTouchEnd={openTask(item.id)}
          children={item.text}
        />
      ))}
      {thumb}
      <Editor
        value={value}
        className={styles.task}
        placeholder='Заголовок'
        contentEditable={false}
        custom={custom}
      />
      {projects.length ? (
        <div className={styles.projects}>
          {projects?.map(id => <TagContainer id={id} />)}
        </div>
      ) : null}
    </TaskCardWrapper>
  )
};

export const TaskLine = ({ data, isSub, ...other }) => {
  const { id, text, entities } = data;

  const { value, thumbId } = useMemo(() => {
    const [value, hidden] = convert.to(text, entities);

    const thumbId = value.find(item => item?.type === 'media');

    return { value, thumbId };
  }, [text, entities]);

  return (
    <div className={classNames(styles.taskLine, isSub && styles.sub)} {...other}>
      <Editor
        value={value}
        className={styles.task}
        placeholder='Заголовок'
        contentEditable={false}
        custom={custom}
      />
    </div>
  )

}

export const TaskCardContainer = ({ id, ...other }) => {

  let task = useSelector(selectItem(['entity', id]), shallowEqual);
  const parents = useSelector(selectParentsEntity(id, 'task'), shallowEqual);

  if (id === null) task = { text: ['Задача'], entities: [] }

  if (!task) return;

  if (other.isSub) return <TaskLine data={task} {...other} />

  return (
    <TaskCard data={task} {...other} parents={!other.isSub ? parents : []} />
  )
}
