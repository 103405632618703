import styles from "@/modules/Header/Header.css";
import Logo from "@/ui/icons/logo.svg";
import React from "react";
import {Link} from "react-router-dom";
import {useBoard} from "@/modules/Board/BoardContext";
import {useSelector} from "@/react-redux";
import {selectItem} from "@/store/app/getters";
import {Search} from "@/modules/Search/Search";

export const HeaderSearch = () => {
  const { ignore, task, id } = useBoard(['task', 'ignore', 'id']);
  const title = useSelector(selectItem(['tag', id, 'title'], ''));

  const isHide = !(global.isSub || !ignore);

  return (
    <>
      {isHide
        ? <Link to='/'><Logo className={styles.logo} /></Link>
        : <Search placeholder={`Найти в ${title}`}/>}
    </>
  );
}