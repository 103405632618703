import { domain } from '../../../config.json';
import React, { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import { Bubble } from '@/components/Bubble/Bubble';
import { InputSimple } from '@/ui/Input/InputSimple';
import { Tumbler } from '@/ui/Tumbler/Tumbler';
import { setItem } from '@/store/app/actions';
import { Notifications } from '@/components/Notifications';
import styles from './HeaderRight.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useForm } from '@/hooks/useForm';
import { store } from '@/store/client';
import { selectAuth, selectSelection, selectTag, selectUserData } from '@/store/selectors';
import Finger from '@/ui/icons/finger.svg';
import Trash from '@/ui/icons/trash.svg';
import Close from '@/ui/icons/close.svg';
import { actions } from '@/hooks/actions';
import { getIcons } from '@/modules/Header/helpers';
import { rerender } from '@/index';
import { useSubscribe } from '@/lib/push';
import {Actions, Menu} from '@/components/Actions/Actions';
import { sideType } from '@/components/Portal/Portal';
import { pickBy } from '@/helpers/dash';
import { Subscribe } from '@/modules/Header/Subscribe';
import {Thumb, ThumbPicker} from '@/modules/Thumb/Thumb';
import {handleKey} from "@/helpers/hooks";
import {QR} from "@/lib/Qr";
import {routesConfig} from "@/data/routes";
import {Button, size} from "@/ui/Button";
import {Switch} from "@/components/Switch/Switch";
import {Theme} from "@/modules/Theme/Theme";
import {useBoard} from "@/modules/Board/BoardContext";
import {Device} from "@/components/Device/Device";
import {shareActions, useShare, useShareE} from "@/hooks/useShare";
import {TagThumb} from "@/components/Base/Tag";
import {useLocation} from "react-router";
import {useMediaSoup} from "@/lib/WebRTC";
import {assign} from "@/helpers/assign";
import {RoomUsers} from "@/components/Room/Room";

const ThumbInput = ({ value, ...props }) => <Thumb thumb={value} {...props} />;

export const Users = ({ isOpen, isInner, Control }) => {
  const user = useSelector(selectAuth);
  const { task } = useBoard(['task']);
  const { streams } = useMediaSoup(user, task?.id);

  return (
    <>
      {isOpen ? null : (
        <div className={styles.online}>
          <RoomUsers />
        </div>
      )}
      <Control
        name="thumb"
        component={ThumbInput}
        props={{ type: 'user', className: classNames(styles.avatar, isInner && styles.in), style: { transform: 'scaleX(-1)' }, stream: streams[user] }}
      />
    </>

  )
}

export const Changes = ({ children }) => {
  const actions = useSelector(selectItem(['next'], {}), shallowEqual, []);

  const count = Object.values(actions).map(a => Object.values(a)).flat(3).filter(Boolean).length;

  return <Bubble color={'var(--card)'} side={['bottom', 'left']} count={count}>{children}</Bubble>;
}

const icons = {
  auth: ({ selection, user, ...args }) => {
    const l = useLocation();

    return <Button {...args} to='/auth' size={size.small} children={l.pathname === '/' ? 'Попробовать' : <Finger />} />
  },
  trash: ({ selection, user, ...args }) => {
    const deleteSelection = () => {
      if (!selection.length) return;
      actions.entities.delete(...selection);
    }

    useEffect(handleKey('Backspace', deleteSelection), [selection])

    return <Trash {...args} onMouseDown={deleteSelection} onTouchEnd={deleteSelection}/>
  },
  user: ({ selection, user, ...args }) => <User {...args} user={user} />,
}

export const HeaderRight: React.FC = () => {
  const id = useSelector(selectAuth);
  const { task, handleProject } = useBoard(['task', 'handleProject']);
  const user = useSelector(selectUserData, shallowEqual);
  const [selection] = useSelector(selectSelection, shallowEqual);

  return (
    <div className={styles.wrapper}>
      <Changes>
        <Bubble count={selection.length || user?.notification?.unread}>
          {getIcons(icons, (selection.length ? 'trash' : (id ? 'user' : 'auth')), {
            user,
            handleProject,
            selection,
            // close: <Close onClick={() => handleProject({ task: null })} />,
          })}
        </Bubble>
      </Changes>
    </div>
  )
}

const config = {
  title: { placeholder: 'Заголовок', className: styles.title },
  // phone: { placeholder: 'Телефон', className: styles.phone, Component: InputPhone },
}

const logout = () => {
  store.dispatch(setItem([], {}));
  rerender(true);
};

const Qr = () => {
  const [open, setOpen] = useState(false);
  const token = useSelector(selectItem(['token']));
  const svg = QR(domain + `${routesConfig.signup.path}#t${token}`);

  return <div onClick={() => setOpen(true)} className={classNames(styles.qr, open && styles.open)} dangerouslySetInnerHTML={{ __html: svg }} />;
}

export const User = ({ user, ...args }) => {

  const { Control, handleSubmit } = useForm({ initialValue: user });

  const onSubmit = actions.users.update;

  const [isSubscribed, onSubscribe] = useSubscribe(user.clients);

  const settings = [
    assign([], {
      title: 'Аватарка',
      children: <Control name='thumb' component={ThumbPicker} props={{ count: 10 }} />
    }),
    { title: 'Тема', children: <Theme /> },
    { title: 'Устройства', hide: !user.clients?.length, children: <>{user.clients?.map((item, i) => <Device key={i} {...item}/>)}</> },
    { title: 'Перезапустить', onClick: () => location.reload() },
    { title: 'Сбросить кэш', onClick: () => rerender(true) },
    { title: 'Выйти', onClick: () => logout() }
  ]

  return (
    <Actions
      data-name="user"
      style={pickBy(args.style, ['opacity', 'zIndex', 'pointerEvents'])}
      side={sideType.topRight}
      resize
      onBlur={handleSubmit(null, args => actions.projects.update({ id: user.id, ...args }))}
    >
      {({ isOpen, isInner, from, onTrigger }) => (
        <div className={classNames(styles.root, isInner && styles.in, isOpen ? styles.open : styles.hide)} data-trigger={isInner}>
          <div data-trigger={isInner}>
            <Users isOpen={isOpen} isInner={isInner} Control={Control} />
          </div>
          {isInner && (
            <>
              {Object.keys(config).map(name => (
                <Control
                  key={name}
                  name={name}
                  props={{
                    ...config[name],
                    onEnter: handleSubmit(onSubmit),
                  }}
                  component={config[name].Component || InputSimple}
                />
              ))}
              {/*<Subscribe />*/}
              {from === 'click' && (
                <>
                  <div className={styles.notifications}>
                    <span className={styles.ti}>Уведомления</span>
                    <Tumbler checked={isSubscribed} onChange={onSubscribe} />
                  </div>
                  <div className={styles.noti} data-trigger>
                    <Notifications onClose={onTrigger} />
                  </div>
                </>
              )}
              {from === 'long' && (
                <Menu className={styles.noti} options={settings} open />
              )}
            </>
          )}
        </div>
      )}
    </Actions>
  );
}


