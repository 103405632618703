import React, {useCallback, useEffect, useState} from 'react';
import styles from './Helper.css';
import classNames from "classnames";
import {useSocket} from "@/hooks/useSocket";
import {Editor} from "@/components/Editor/Editor";
import Send from "@/ui/icons/send.svg";
import Sparcle from "@/ui/icons/spakle.svg";
import Plus from "@/ui/icons/plus.svg";
import {Message} from "@/components/Message/Message";
import {Menu} from "@/components/Actions/Actions";
import {order} from "@/modules/Board/BoardContainer";
import {actions} from "@/hooks/actions";
import {useBoard} from "@/modules/Board/BoardContext";
import {groupType} from "../../../types/group";
import {Create} from "@/components/TaskCard";
import Skeleton from "@/ui/Skeleton/Skeleton";
import {Indicator} from "@/components/Message/Indicator";
import {useSelector} from "@/react-redux";
import {selectRole, selectUser} from "@/store/selectors";
import {isEqual} from "@/helpers/dash";
import {roleType} from "@/data/member";

const items = [
  { target: '[data-name="groups"]', children: <>Группы тегов, по которым объдиняются задачи</> },
  { target: '[data-name="projects"]', children: <>Клик - открыть проекты, <br/>Пкм - настройки текущего проекта</> },
  { target: '[data-name="user"]', children: <>Клик - открыть уведомления, <br/>Пкм - настройки пользователя</> },
  { target: '[data-name="widget"]', children: <>Уменый помощник, если возникнут вопросы</> },
]

const calls = new Map();

// with translate
const ro = new ResizeObserver((entries) => {
  for (const entry of Array.from(entries)) {
    const func = calls.get(entry.target)

    if (func) func();
  }
});

const SmoothArrow = ({ from = [100, 0], to = [0, 100] }) => {

  const max = Math.max(...from, ...to) + 5;

  return (
    <svg viewBox={`0 0 ${max} ${max}`}>
      <defs>
        <marker id="arrow" markerWidth="10" markerHeight="10" refX="7" refY="5" orient="auto">
          <path d="M0,0 L7,5 L0,10" fill="none" stroke="currentColor" strokeWidth="1"/>
        </marker>
      </defs>
      <path
        id='arrow-line'
        marker-end='url(#arrow)'
        stroke-width='2'
        fill='none'
        stroke='currentColor'
        d={`M${from[0]} ${from[1]} Q${from[0]} 50 ${to[0]} ${to[1]}`}
      />
    </svg>
  );
};

export const Helper = ({ id, order, type, group }) => {
  const [state, setState] = useState({});
  const user = useSelector(selectUser, isEqual, []);
  const { role, subscribe } = useSelector(selectRole(id), isEqual, [id]);

  const isAdmin = (role === roleType.admin || id === user);

  useEffect(() => {
    const res = {};

    for (const {target} of items) {
      res[target] = document.querySelector(target)?.getBoundingClientRect()
    }

    setState(res);
  }, []);

  const hide = (!isAdmin  || group || Object.values(order).flat().length);

  return (
    <div className={classNames(styles.root, hide && styles.hide )}>
      {items.map(({target, children}) => {
        if (!state[target]) return null;

        const {left, top} = state[target];
        const bottom = window.innerHeight - top;
        const right = window.innerWidth - state[target].right;

        const style = {};

        if (left < right) style.left = left;
        else style.right = right;

        if (top < bottom) style.top = top;
        else style.bottom = bottom;

        let arrow = { from: [], to: [] }

        if (style.bottom && style.left)
          arrow = { from: [100, 0], to: [0, 100] }

        if (style.top && style.left)
          arrow = { from: [100, 100], to: [0, 10] }

        if (style.bottom && style.right)
          arrow = { from: [10, 0], to: [100, 100] }

        if (style.top && style.right)
          arrow = { from: [10, 100], to: [100, 10] }

        if (left - right === 0 && style.top)
          arrow = { from: [50, 100], to: [50, 0] }

        if (left - right === 0 && style.bottom)
          arrow = { from: [50, 0], to: [50, 100] }

        return (
          <div className={classNames(styles.item, ...Object.keys(style).map(key => styles[key]))} style={style}>
            {children}
            <SmoothArrow {...arrow} />
          </div>
        )
      })}
      {!hide && <style>{'[data-name="columns"] { display: none; }'}</style>}
      <Center />
    </div>
  );
}

const Center = () => {
  const { id } = useBoard();
  const [loading, setLoading] = useState(false);

  const onUpdate = useCallback((res) => {
    const args = res?.body?.gpt?.project;

    if (args) {
      setLoading(false);
      const {clusters, tasks} = args;

      // Cоздаем группы
      const as = actions.groups.create({
        groups: clusters.map(({ group }) => ({ ...group, type: groupType.tag })),
        members: [{ memberId: id, role: 'admin' }],
      })

      const gTitle = Object.values(as).reduce((ac, it) => ({ ...ac, [it.title]: it }), {})
      const tTitle = {}

      // for (const { group, tags } of clusters) {
      //   const g = gTitle[group.title];
      //   for (const tag of tags) {
      //     tTitle[tag.title] = actions.tags.create(g.id, { ...tag, type: g.type });
      //   }
      // }

      actions.entities.create(
        ...tasks.map(({ text }) => {
          const res = { text: [], entities: [] }

          for (const t of text) {
            if (typeof t === 'string')
              res.text.push(t);
            else {
              res.text.push(null);
              res.entities.push({ group: gTitle[t.group].id, id: JSON.stringify({ title: t.tag, type: groupType.tag }) })
            }
          }

          res.entities.push({ group: null, id })

          return res;
        })
      )

    }
  }, []);
  const onSend = useSocket({onUpdate});

  if (loading)
    return <div className={styles.center}>
    <h1>Думаю <Indicator/></h1>
  </div>

  return (
    <div className={styles.center}>
      <h1>Это твое пространство</h1>
      <div>
        Тут выводятся все задачи связанные с проектом, где отображение зависит от выбранной группы снизу и фильтрам
        сверху
      </div>
      <Gen
        onSend={text =>  {
          setLoading(true);
          onSend([{ gpt: { type: "project", text } }])
        }}
      />
      <Create className={styles.btn} tags={[]}/>
    </div>
  )
}

const Gen = ({ onSend }) => {
  const [value, setValue] = useState([]);

  return (
    <Editor
      addons={{}}
      contentEditable
      placeholder="Опиши, чем ты занимаешься и мы настроим проект"
      className={styles.area}
      value={value}
      onEnter={() => onSend(value.join(' '))}
      onChange={value => setValue(value)}
      render={({ body, onEnter }) => (
        <>
          <Sparcle
            className={styles.itop}
          />
          {body}
          {value.length ? (
            <Send
              className={styles.ibottom}
              onClick={onEnter}
            />
          ) : null}
        </>
      )}
    />
  )
}