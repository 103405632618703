import React from 'react';
import styles from './Notifications.css';
import {shallowEqual, useSelector} from '@/react-redux';
import { formatDateOf } from '@/helpers/date';
import IconMessage from '@/ui/icons/message.svg';
import IconPlus from '@/ui/icons/plus.svg';
import IconUser from '@/ui/icons/user.svg';
import { selectNotification } from '@/store/selectors';
import { useNavigate } from 'react-router';
import { getHistory } from '@/components/Notifications/helpers';
import { stringToColour } from '@/helpers/color';
import {emojis, Thumb} from "@/modules/Thumb/Thumb";
import {AutoSize} from "@/lib/Autosizer";
import {Virtualized, VirtualizedItems} from "@/lib/Virtualized";

const icons = {
  message: <IconMessage />,
  task: <IconPlus />,
  member: <IconUser />,
}

const Item: React.FC = ({ data, onClose }) => {
  const history = useNavigate();
  let { by = {}, createdAt } = data;

  const time = formatDateOf(new Date(createdAt));

  const { title, type, body, link, } = getHistory(data);

  const onClick = () => {
    onClose();
    history(link);
  }

  return (
    <div className={styles.item} onClick={onClick}>
      <span className={styles.user}>
        <Thumb mode='avatar' className={styles.icon} {...by} title={title} />
        <div className={styles.shield} style={{ color: stringToColour(title) }}>{icons[type]}</div>
      </span>
      <div className={styles.body}>
       <div className={styles.header}>
         <span className={styles.title}>{title}</span>
         <span className={styles.date}>{time}</span>
       </div>
        <div className={styles.content}>
          {body}
        </div>
      </div>
    </div>
  );
}

export const Notifications: React.FC = ({ onClose }) => {
  const notifications = useSelector(selectNotification(), shallowEqual, []);

  const items = notifications.map((item, i) => ({
    value: item,
    children: <Item key={item.id} data={item} onClose={onClose} />,
    w: '100%',
    h: 70,
    y: i * (70 + 16),
    x: 0,
  }));

  const children = (args) => <div {...args} />;

  return (
    <AutoSize
      className={styles.notification}
      children={dem => (
        <Virtualized
          items={items}
          {...dem}
          children={other => (
            <VirtualizedItems {...other} items={items} children={children}/>
          )}
        />
      )}
    />
  );
};
