const createStyles1 = (baseColor = "#176be8", secondaryColor = "#ffffff") => {
  const generateColorShades = (color, factor) => {
    const colorInt = parseInt(color.slice(1), 16);
    const r = Math.min(255, Math.max(0, ((colorInt >> 16) + factor))).toString(16).padStart(2, '0');
    const g = Math.min(255, Math.max(0, (((colorInt >> 8) & 0x00FF) + factor))).toString(16).padStart(2, '0');
    const b = Math.min(255, Math.max(0, ((colorInt & 0x0000FF) + factor))).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`;
  };

  const labelStylers = (color = "#0e408b") => ([
    {
      "elements": "label.icon",
      "stylers": [
        { "color": baseColor },
        { "secondary-color": secondaryColor },
        { "tertiary-color": secondaryColor }
      ]
    },
    {
      "elements": "label.text.fill",
      "stylers": [{ "color": color }]
    },
    {
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    }
  ]);

  return [
    {
      "tags": { "any": "landcover", "none": "vegetation" },
      "stylers": [{ "hue": generateColorShades(baseColor, 50) }]
    },
    {
      "tags": "geographic_line",
      "elements": "geometry",
      "stylers": [{ "color": generateColorShades(baseColor, -50) }]
    },
    ...[
      "poi", "outdoor", "park", "cemetery", "beach",
      "medical", "shopping", "commercial_services",
      "food_and_drink"
    ].flatMap(tag => (
      labelStylers(generateColorShades(baseColor, -70)).map(styler => ({ "tags": tag, ...styler }))
    )),
    {
      "tags": "road",
      "elements": "label.icon",
      "types": "point",
      "stylers": [
        { "color": baseColor },
        { "secondary-color": secondaryColor },
        { "tertiary-color": secondaryColor }
      ]
    },
    {
      "tags": "road",
      "elements": "label.text.fill",
      "types": "point",
      "stylers": [{ "color": secondaryColor }]
    },
    {
      "tags": "road",
      "elements": "label.text.fill",
      "types": "polyline",
      "stylers": [{ "color": generateColorShades(baseColor, -20) }]
    },
    {
      "tags": "road",
      "elements": "label.text.outline",
      "types": "polyline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "road",
      "elements": "geometry.fill.pattern",
      "types": "polyline",
      "stylers": [
        { "scale": 1 },
        { "color": generateColorShades(baseColor, 15) }
      ]
    },
    {
      "tags": "entrance",
      "elements": "label.icon",
      "stylers": [
        { "color": baseColor },
        { "secondary-color": secondaryColor },
        { "hue": baseColor }
      ]
    },
    {
      "tags": "locality",
      "elements": "label.icon",
      "stylers": [
        { "color": baseColor },
        { "secondary-color": secondaryColor }
      ]
    },
    {
      "tags": "country",
      "elements": "label.text.fill",
      "stylers": [
        { "opacity": 0.8 },
        { "color": generateColorShades(baseColor, -40) }
      ]
    },
    {
      "tags": "country",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "region",
      "elements": "label.text.fill",
      "stylers": [
        { "color": generateColorShades(baseColor, -40) },
        { "opacity": 0.8 }
      ]
    },
    {
      "tags": "region",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "district",
      "elements": "label.text.fill",
      "stylers": [
        { "color": generateColorShades(baseColor, -40) },
        { "opacity": 0.8 }
      ]
    },
    {
      "tags": "district",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": { "any": "admin", "none": ["country", "region", "locality", "district", "address"] },
      "elements": "label.text.fill",
      "stylers": [{ "color": generateColorShades(baseColor, -40) }]
    },
    {
      "tags": { "any": "admin", "none": ["country", "region", "locality", "district", "address"] },
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "locality",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "structure",
      "elements": "label.text.fill",
      "stylers": [
        { "color": generateColorShades(baseColor, -70) },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "structure",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "address",
      "elements": "label.text.outline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.5 }
      ]
    },
    {
      "tags": "water",
      "elements": "label.text.fill",
      "stylers": [
        { "color": generateColorShades(baseColor, -60) },
        { "opacity": 0.8 }
      ]
    },
    {
      "tags": "water",
      "elements": "label.text.outline",
      "types": "polyline",
      "stylers": [
        { "color": secondaryColor },
        { "opacity": 0.2 }
      ]
    },
    {
      "tags": { "all": "path", "none": "is_tunnel" },
      "elements": "geometry.fill",
      "stylers": [{ "color": generateColorShades(baseColor, 70) }]
    },
    {
      "tags": "road_construction",
      "elements": "geometry.fill",
      "stylers": [{ "color": secondaryColor }]
    },
    {
      "tags": { "any": "ferry" },
      "stylers": [{ "color": generateColorShades(baseColor, 50) }]
    },
    {
      "tags": "transit_location",
      "elements": "label.icon",
      "stylers": [
        { "hue": baseColor },
        { "saturation": -0.18 }
      ]
    },
    {
      "tags": "transit_location",
      "elements": "label.text.fill",
      "stylers": [{ "color": generateColorShades(baseColor, -30) }]
    },
    {
      "tags": "transit_location",
      "elements": "label.text.outline",
      "stylers": [{ "color": secondaryColor }]
    },
    {
      "tags": "transit_schema",
      "elements": "geometry.outline",
      "stylers": [{ "opacity": 0 }]
    },
    {
      "tags": "bathymetry",
      "elements": "geometry",
      "stylers": [{ "hue": generateColorShades(baseColor, 100) }]
    },
    {
      "tags": { "all": ["is_tunnel", "path"] },
      "elements": "geometry.fill",
      "stylers": [
        { "color": generateColorShades(baseColor, 50) },
        { "opacity": 0.3 }
      ]
    },
    {
      "tags": { "all": ["is_tunnel", "path"] },
      "elements": "geometry.outline",
      "stylers": [{ "opacity": 0 }]
    }
  ];
}

const generateColorShades = (baseColor, targetColor, steps) => {
  const base = parseInt(baseColor.slice(1), 16);
  const target = parseInt(targetColor.slice(1), 16);
  const shades = [];

  const blendChannel = (baseChannel, targetChannel, ratio) =>
    Math.round((baseChannel * (1 - ratio) + targetChannel * ratio)).toString(16).padStart(2, '0');

  for (let i = 0; i < steps; i++) {
    const ratio = i / (steps - 1);
    const r = blendChannel((base >> 16) & 0xFF, (target >> 16) & 0xFF, ratio);
    const g = blendChannel((base >> 8) & 0xFF, (target >> 8) & 0xFF, ratio);
    const b = blendChannel(base & 0xFF, target & 0xFF, ratio);
    shades.push(`#${r}${g}${b}`);
  }

  return shades;
};

const createStyles2 = (baseColor = "#176be8", secondaryColor = "#ffffff") => {
  const colorShades = generateColorShades(secondaryColor, baseColor, 22);

  return [
    {
      "tags": "country",
      "elements": "geometry.fill",
      "stylers": [{ "color": colorShades[5] }, ...generateZoomStylers(0.8, 1)]
    },
    {
      "tags": "country",
      "elements": "geometry.outline",
      "stylers": [{ "color": colorShades[10] }, ...generateZoomStylers(0.15, 0.25)]
    },
    {
      "tags": "region",
      "elements": "geometry.fill",
      "stylers": [
        ...generateColorStylers(colorShades.slice(0, 12)),
        ...generateZoomStylers(0.5, 1)
      ]
    },
    {
      "tags": "region",
      "elements": "geometry.outline",
      "stylers": [{ "color": colorShades[15] }, ...generateZoomStylers(0.15, 0.25)]
    },
    {
      "tags": {
        "any": "admin",
        "none": ["country", "region", "locality", "district", "address"]
      },
      "elements": "geometry.fill",
      "stylers": [{ "color": colorShades[5] }, ...generateZoomStylers(0.5, 1)]
    },
    {
      "tags": {
        "any": "admin",
        "none": ["country", "region", "locality", "district", "address"]
      },
      "elements": "geometry.outline",
      "stylers": [{ "color": colorShades[15] }, ...generateZoomStylers(0.15, 0.25)]
    },
    {
      "tags": "vegetation",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(10, 14))
      ]
    },
    {
      "tags": "park",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(12, 16)),
        ...generateZoomStylers(0.1, 1)
      ]
    },
    {
      "tags": "national_park",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(12, 16)),
        ...generateZoomStylers(0.1, 1)
      ]
    },
    {
      "tags": "sports_ground",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(14, 19)),
        ...generateZoomStylers(0, 1)
      ]
    },
    {
      "tags": "terrain",
      "elements": "geometry",
      "stylers": [{ "hue": baseColor, "opacity": 0.4 }]
    },
    {
      "tags": "land",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(0, 14))
      ]
    },
    {
      "tags": "water",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(7, 21))
      ]
    },
    {
      "tags": "transit_line",
      "elements": "geometry.fill",
      "stylers": [
        { "color": colorShades[15], "scale": 0.4 },
        ...generateZoomStylers(0, 1, 13, 21)
      ]
    },
    {
      "tags": "fence",
      "elements": "geometry.fill",
      "stylers": [
        { "color": colorShades[6] },
        ...generateZoomStylers(0.75, 0.45)
      ]
    },
    // Using the same pattern to add more styles...
    {
      "tags": "road",
      "elements": "geometry.fill",
      "stylers": [
        { "color": colorShades[20] },
        ...generateZoomStylers(0, 1)
      ]
    },
    {
      "tags": "road",
      "elements": "geometry.outline",
      "stylers": [
        { "color": colorShades[21] },
        ...generateZoomStylers(0.1, 1)
      ]
    },
    {
      "tags": "building",
      "elements": "geometry.fill",
      "stylers": [
        { "color": colorShades[8] },
        ...generateZoomStylers(0.7, 0.6)
      ]
    },
    {
      "tags": "building",
      "elements": "geometry.outline",
      "stylers": [
        { "color": colorShades[9] },
        ...generateZoomStylers(0.5, 1)
      ]
    },
    {
      "tags": "locality",
      "elements": "label.text.fill",
      "stylers": [
        ...generateColorStylers(colorShades.slice(5, 21))
      ]
    },
    {
      "tags": "industrial",
      "elements": "geometry",
      "stylers": [
        ...generateColorStylers(colorShades.slice(10, 21))
      ]
    }
  ];
}

export const gen = (baseColor, to = "#ffffff") => {

  const steps = generateColorShades(baseColor || '#000000', to, 20);
  return [
    {
      "elements": "label.text.fill",
      "stylers": [
        {
          "color": steps[8]
        }
      ]
    },
    {
      "elements": "geometry.outline",
      "stylers": [
        {
          "color": steps[6]
        }
      ]
    },
    {
      "elements": "label.icon",
      "stylers": [
        {
          "color": steps[4]
        },
        {
          "secondary-color": steps[14]
        },
        {
          "tertiary-color": steps[8]
        }
      ]
    },
    {
      "elements": "label.text.outline",
      "types": "polyline",
      "stylers": [
        {
          "color": steps[9]
        },
        {
          "opacity": 0.2
        }
      ]
    },
    {
      "tags": {
        "any": [
          "water"
        ]
      },
      "elements": "geometry",
      "stylers": [
        {
          "color": steps[0]
        }
      ]
    },
    {
      "tags": {
        "any": [
          "landscape",
          "admin",
          "land",
          "transit"
        ]
      },
      "elements": "geometry",
      "stylers": [
        {
          "color": steps[2]
        }
      ]
    },
    {
      "tags": {"any": ["road"]},
      "elements": "geometry",
      "stylers": [
        {
          "color": steps[4]
        }
      ]
    },
    {
      "tags": {"any": ["building"]},
      "elements": "geometry",
      "stylers": [
        {
          "color": steps[6]
        }
      ]
    }
  ]
}


// Использование
console.log('gen', gen());

