import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Virtualized.css';
import {shallowEqual} from "@/react-redux";
import {useOverScroll} from "@/lib/Scroll";

interface IVirtualizedProps {
  items: any[],
  width: number,
  height: number,
  getPosition(): { x: number; y: number; w: number; h: number; },
  children: any,
}


export const VirtualizedContext = React.createContext({});

export const Virtualized: React.FC<IVirtualizedProps> = ({ wrapper: wrapperProps, sizer = item => item, className, style: styleProps = {}, width, height, position, children, top, ...other }) => {

  const scrolling = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const stack = useRef({
    pos: { x: 0, y: 0 },
    items: [],
    call: (pos) => {
      stack.current.pos = pos;
      for (const func of stack.current.items)
        func(pos)
    },
    add: (func) => {
      func(stack.current.pos)
      stack.current.items.push(func);
    },
    delete: (func) => {
      stack.current.items = stack.current.items.filter(item => item !== func);
    },
  });

  useEffect(() => {
    if (!scrolling.current) return;

    const scroll = e => {
      if (e.target !== scrolling.current) return;
      const { scrollLeft, scrollTop } = e.target;
      stack.current.call({ x: scrollLeft, y: scrollTop })
    };

    scrolling.current.addEventListener('scroll', scroll, { passive: true })

    return () => {
      scrolling.current?.removeEventListener(scroll);
    }
  }, [])

  const [wrapper, setWrapper] = useState({ width, height, position: 'relative' });

  useEffect(() => {
    if (scrolling.current && position) {
      scrolling.current.scrollLeft = position.scrollLeft || 0;
      scrolling.current.scrollTop = position.scrollTop || 0;
    }
  }, [position, wrapper])

  const setMax = useCallback(body => {
    setWrapper(prev => {
      const next = { ...prev, width: body.w, height: body.h };

      return shallowEqual(next, prev) ? prev : next;
    })
  }, [setWrapper])

  const style = Object.assign({ width, height }, wrapperProps?.style);

  useOverScroll(scrolling, other);

  return (
    <div className={styles.root} style={style} ref={scrolling} {...other}>
      <div className={className} style={sizer({ ...styleProps, ...wrapper })} ref={container}>
        <VirtualizedContext.Provider value={{ scrolling, container }}>
          {children({ stack, w: width, h: height, setMax })}
        </VirtualizedContext.Provider>
      </div>
    </div>
  )
}

const getPositionDefault = item => item;

export const VirtualizedItems = ({ items, getPosition = getPositionDefault, gap = 300, stack, setMax, children, w, h }) => {
  const [current, setCurrent] = useState([]);

  // Get max and poses
  const { max, poses } = useMemo(() => {
    // TODO: get
    const max = { w, h: 0 };

    const poses = [];

    for (let i = 0; i < items.length; i++) {
      const pose = getPosition(items[i], i);
      poses.push(pose);

      const posexw = pose.x + pose.w;
      const poseyh = pose.y + pose.h;

      if (pose.x !== 'current' && posexw > max.w) max.w = posexw;
      if (pose.y !== 'current' && poseyh > max.h) max.h = poseyh;
    }

    return { max, poses }
  }, [items, getPosition, w, h]);

  // Set max
  useEffect(() => { setMax(max) }, [max]);

  // Get next indexes
  useEffect(() => {
    const func = ({ x, y }) => {
      const next = getCurrentIndexes({ x, y, w, h, gap }, poses);

      setCurrent(prev => isEqual(prev, next) ? prev : next);
    };

    stack.current.add(func);

    return () => {
      stack.current.delete(func)
    }
  }, [w, h, gap, poses]);

  const res = {};

  for (const i of current) {
    if (i in poses) {
      const { x, y, w: width, h: height } = poses[i];

      const transform = Object.entries({ translateX: x, translateY: y })
        .map(([key, val]) => typeof val === 'number' && `${key}(${val}px)`)
        .filter(Boolean)
        .join(' ')

      const next = { ...items[i], style: Object.assign({ transform, width, height, position: 'absolute' }, items[i]?.style) };
      const component = children(next);

      res[next.key || i]= component;
    }
  }

  return <>{Object.values(res)}</>;
}

Virtualized.defaultProps = {
  items: [1],
  width: 0,
  height: 0,
  getPosition: () => ({ x: 20, y: 20, w: 700, h: 20, }),
}

const isEqual = (prev: number[], next: number[]) => {
  if (prev.length !== next.length) return false;

  let sum = 0;

  for (let i = 0; i < prev.length; i++) sum += prev[i];
  for (let i = 0; i < next.length; i++) sum -= next[i];

  return sum === 0;
}

const getCurrentIndexes = (settings, poses) => {
  const res = [];
  const { w, h, gap } = settings;

  const x = settings.x - gap;
  const y = settings.y - gap;
  const xw = x + w + gap;
  const yh = y + h + gap;

  for (let i = 0; i < poses.length; i++) {
    const pose = poses[i];

    const posex = pose.x - 300;
    const posey = pose.y - 300;
    const posexw = pose.x + pose.w + 300;
    const poseyh = pose.y + pose.h + 300;

    if (
    (pose.x === 'current' || (posex >= x && posex <= xw || posexw >= x && posexw <= xw || posex <= x && posexw >= xw)) &&
    (pose.y === 'current' || (posey >= y && (posey <= yh || pose.ymax) || poseyh >= y && (poseyh <= yh || pose.ymax) || posey <= y && poseyh >= yh))
    ) {
      res.push(i);
    }
  }

  return res;
}
