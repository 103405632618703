import React, {memo, useContext, useEffect, useState} from 'react';
import { TaskBoard } from '@/modules/Board';
import { TaskContainer } from '@/components/Task/Task';
import { BoardWrapper } from '@/modules/Board/BoardWrapper';
import { Bar } from '@/modules/Bar/Bar';
import {Room} from "@/components/Room/Room";

const def = (a) => a;

export const Board = ({ }) => (
  <>
    <BoardWrapper children={(dem, boardRef) => <TaskBoard {...dem} boardRef={boardRef} />} />
    {global.isSub ? null : <Bar />}
    <TaskContainer />
    <Room />
  </>
);
