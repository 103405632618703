import * as React from 'react';
import { shallowEqual, useSelector } from '@/react-redux';
import styles from './MessagesList.css';
import { Message } from '@/components/Message/Message';
import { selectMessages } from '@/store/selectors';
import {useContext, useState} from 'react';
import {useBoard} from "@/modules/Board/BoardContext";
import { convert } from '@/components/Task/Task';
import {MessageEditor} from "@/components/Message/MessageEditor";
import {MessageShare} from "@/components/Message/MessageShare";

const delta = 1000 * 60 * 5; // 5 min

export const MessagesList = ({ items = [], ...other }) => {

  const children = [];

  // for (const cur of items) {
  //   const { id, text, entities, createdAt, createdBy, ...item } = cur;
  //
  //   const [t] = convert.to(text, entities)
  //
  //   const lst = children[children.length - 1] || { time: 0 };
  //
  //   if (!children.length || lst?.createdBy !== createdBy || (createdAt - lst.createdAt) >= delta) {
  //     children.push({
  //       ...item,
  //       createdAt,
  //       createdBy,
  //       items: [{ id, text: t }],
  //     });
  //     continue;
  //   }
  //
  //   lst.createdAt = createdAt;
  //   lst.items.push({ id, text: t });
  // }

  return (
    <div
      className={styles.root}
      children={items.map(item => <Message {...other} {...item} key={item.createdAt}/>)}
    />
  );
}

export const MessagesListContainer = ({ id, ...other }) => {
  const { id: project } = useBoard(['id']);
  const [replyed, onReply] = useState(null);

  const items = useSelector(selectMessages({ project, task: id }), shallowEqual);

  return (
    <MessagesList {...other} onReply={onReply} items={items} />
  )
}
