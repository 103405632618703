import {createContext, useContext, useEffect, useRef, useState} from 'react';
import * as mediasoupClient from 'mediasoup-client';
import React from 'react';

const Context = createContext({});

export const RTCContext = ({ children }) => {
  const [streams, setStreams] = useState({});
  const [translation, setTranslation] = useState({});
  const data = useRef({});

  return (
    <Context.Provider value={{ data, streams, setStreams, translation, setTranslation }}>
      {children}
    </Context.Provider>
  )
}

export const useMediaSoup = (id, room) => {
  const { data, streams, setStreams, translation, setTranslation } = useContext(Context);
  const api = (body) => fetch('/api/stream', { headers: { 'content-type': 'application/json' }, method: 'POST', body: JSON.stringify({ room, id, ...body }) })
    .then(res => res.json())

  const initDevice = async () => {
    if (data.current.device) return;

    const newDevice = new mediasoupClient.Device();
    const rtpCapabilities = await api({ type: 'routerRtpCapabilities' });
    await newDevice.load({ routerRtpCapabilities: rtpCapabilities });

    data.current.rtpCapabilities = rtpCapabilities;
    data.current.device = newDevice;
  };

  const createSendTransport = async () => {
    if (data.current.send) return data.current.send;

    await initDevice();
    // Присылает опшены транспорта
    const transportOptions = await api({ type: 'create' })

    const transport = data.current.device.createSendTransport(transportOptions);

    transport.on('connect', async ({ dtlsParameters }, callback, errback) => (
      api({ type: 'connect', room, dtlsParameters })
        .then(callback)
        .catch(errback)
    ));

    transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
      api({ type: 'produce', kind, rtpParameters })
        .then(callback)
        .catch(errback)
    });

    data.current.send = transport;
    return transport;
  }

  const createRecvTransport = async () => {
    if (data.current.recv) return data.current.recv;

    await initDevice();
    const transportOptions = await api({ id: id, type: 'create' })

    const transport = data.current.device.createRecvTransport(transportOptions);

    transport.on('connect', ({ dtlsParameters }, callback, errback) => (
      api({ type: 'connect', dtlsParameters })
        .then(callback)
        .catch(errback)
    ));


    data.current.recv = transport;

    return transport;
  }

  return {
    data,
    streams,
    translation,

    update: (func) => () => {
      setTranslation(prev => ({ ...(func() || prev) }));
    },

    // Отправляем
    produce: async () => {
      const send = await createSendTransport();
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 320, height: 180 },
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
      });
      const video = stream.getVideoTracks()[0];
      const audio = stream.getAudioTracks()[0];
        const str = new MediaStream([video, audio]);

      setStreams(prev => ({ ...prev, [id]: str }))

      await send.produce({ room, id, track: video })
        .then(a => {
          setTranslation(prev => ({ ...prev, video: a }));
        })

      await send.produce({ room, id, track: audio })
        .then(a => {
          setTranslation(prev => ({ ...prev, audio: a }));
        })
    },

    // Получаем
    consume: async ({ user, video, audio }) => {
      const recv = await createRecvTransport();
      const stream = new MediaStream([]);

      for (const producerId of [video, audio]) {
        const { id: consumerTransportId, kind, rtpParameters } = await api({
          type: 'consume',
          room,
          producerId,
          id: id,
          rtpCapabilities: data.current.rtpCapabilities
        });

        // Create the consumer on the receiving transport
        const consumer = await recv.consume({
          id: consumerTransportId,
          producerId,
          kind,
          rtpParameters
        });

        stream.addTrack(consumer.track);

        // You might want to handle different kinds of tracks in a more sophisticated way
        consumer.on('transportclose', () => {
          setStreams(prevStreams => {
            const newStreams = { ...prevStreams };
            delete newStreams[id];
            return newStreams;
          });
        });

        consumer.on('producerclose', () => {
          setStreams(prevStreams => {
            const newStreams = { ...prevStreams };
            delete newStreams[user];
            return newStreams;
          });
        });

        recv.on('connectionstatechange', (state) => {
          console.log('Transport connection state: ', state);
          if (state === 'connected') {
            console.log('Transport is connected');
          } else if (state === 'failed' || state === 'disconnected') {
            console.error('Transport connection failed or disconnected');
          }
        });
      }

      // Set the received stream into the state
      setStreams(prevStreams => ({ ...prevStreams, [user]: stream }));

      stream.addEventListener('ended', () => {
        setStreams(prev => ({ ...prev, [id]: null }))
      });
    },

    close: () => {
      console.log('translation', translation)
      for (const key in translation) {
        translation[key].track.stop();
      }

      data.current.send.close();
    }
  };
};
