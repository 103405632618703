import { groupType } from '@/data/groups';
import { formatDate, formatDuration } from '@/helpers/date';
import { parser } from '@/components/Base/parser';

export const formatter = {
  [groupType.date]: title => {
    try {
      return formatDate(new Date(title));
    } catch (e) {
      return title;
    }
  },
  // [groupType.duration]: formatDuration,
};

export const format = ({ title, type, ...other } = {}) => ({
  ...other,
  type,
  title: type in formatter ? formatter[type](title) : title,
})

export const prepare = ({ _id, title, type, thumb }) => ({
  thumb,
  type,
  title: type in parser ? parser[type](title) : title
});