import React from 'react';
import {StatsContainer} from "@/modules/Stats/Stats";
import {useSelector} from "@/react-redux";
import {selectNotification} from "@/store/selectors";
import {addMinutes, format, subHours} from "date-fns";
import {setTo} from "@/helpers/dash";
import {mutation} from "@/helpers/mutate";

const mapper = (array, group) => {
  const res = { max: 0 };

  for (const { createdAt, changes } of array) {
    const item = mutation({}, changes);

    setTo(res, [format(new Date(createdAt), "yyyy-MM-dd HH"), item.entities?.find(i => i.group === group)?.id], (prev = 0) => {
      res.max = Math.max(res.max, prev + 1);
      return prev + 1;
    })

  }

  return res;
}

const getDates = (items) => {
  const data = [], labels = [];
  let time = new Date();

  for (let i = 10; i > -1; i--) {
    const key = format(time, "yyyy-MM-dd HH")

    labels[i] = key;
    data[i] = Object.values(items[key] || {}).reduce((acc, i) => acc + i, 0) / items.max * 100 || 0;

    time = subHours(time, 1);
  }

  return { data, labels };
}

export const BoardStats = ({ id, group }) => {

  const items = useSelector(selectNotification(id, 'entity', { entities: { $contained: [{ group }] } }))

  console.log('items', items)

  const hash = mapper(items, group);

  return (
    <>
      <StatsContainer {...getDates(hash)} />
    </>
  );
}