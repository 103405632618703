import React from 'react';
import {AutoSize} from "@/lib/Autosizer";
import styles from './Stats.css';

const SmoothCurveGraph = ({ data, h = 1000, w = 1000 }) => {
  const maxDataPoint = Math.max(...data);
  const padding = 20;

  const points = data.map((value, index) => {
    const x = (index / (data.length - 1)) * (w - padding * 2) + padding;
    const y = h - (value / maxDataPoint) * (h - padding * 2) - padding;
    return [x, y];
  });

  const pathData = points.map((point, i) => {
    const command = i === 0 ? 'M' : 'C';
    const [prevX, prevY] = points[Math.max(i - 1, 0)];
    const [nextX, nextY] = point;
    return `${command} ${(prevX + nextX) / 2},${prevY} ${prevX} ${(prevY + nextY) / 2} ${nextX},${nextY}`;
  }).join(' ');

  const areaData = `${pathData} L${w-padding},${h-padding} L${padding},${h-padding} Z`;

  return (
    <svg width={w} height={h} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: 'yellow', stopOpacity: 0.4 }} />
          <stop offset="100%" style={{ stopColor: 'yellow', stopOpacity: 0 }} />
        </linearGradient>
      </defs>
      <path className="area" d={areaData} fill="url(#gradient)" />
      <path className="line" d={pathData} fill="none" stroke="yellow" strokeWidth="3" />
      {points.map(([x, y], index) => (
        <circle key={index} cx={x} cy={y} r="4" fill="yellow" />
      ))}
    </svg>
  );
};

export const Stats = ({ data = [40, 30, 60, 80, 50], h = 1000, w = 1000 }) => {

  const ww = w / (data.length - 1);
  const render = (item, i) => `${ww * i},${(100 - item) / 100 * h}`;

  const line = data.map(render);

  return (
    <svg viewBox={`0 0 ${w} ${h}`} width={w} height={h} className={styles.root}>
      <defs>
        <linearGradient id="fill" gradientTransform="rotate(90)">
          <stop offset="0%" stopOpacity="0.3" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
        </linearGradient>
      </defs>
      <path
        d={`M0,${h} L${line.join(' ')} ${w},${h}`}
        fill="url('#fill')"
        strokeWidth="0"
      />
      <path
        d={`M-${w},${h / 2} L${line.join(' ')}`}
        fill="none"
        strokeWidth="4"
        strokeDasharray="none"
        stroke="currentColor"
      />
      {data.map((item, i) => <circle stroke="currentColor" fill="currentColor" strokeWidth="4" cx={ww * i} cy={(100 - item) / 100 * h} r="6" />)}
    </svg>
  )
}

export const StatsContainer = (props) => (
  <AutoSize className={styles.wrapper}>
    {({ width: w, height: h }) => <SmoothCurveGraph {...props} w={w} h={h} />}
  </AutoSize>
)