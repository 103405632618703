export const debounce = (
  delay: number,
  fn: (args?: unknown) => void
): ((args?: unknown) => void) => {
  let timer;
  return (args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(args), delay);
  };
};
