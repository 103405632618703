import React, { useEffect, useState } from 'react';
import styles from './Audio.css';
import { _base64ToArrayBuffer, calculateWaveData } from '@/components/Player/helpres';
import classNames from 'classnames';
import IconPlay from '@/ui/icons/play.svg';
import IconStop from '@/ui/icons/stop.svg';

const context = new (window.AudioContext || window.webkitAudioContext);


const getDuration = (seconds) => {
  const date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return  date.toISOString().substring(11, 19).replaceAll('00:', '');
}

const players = {};

export const AudioComponent = ({ src, base64, content = '', ...args }) => {
  const [{ player, current = 0 }, setState] = useState({});

  const [dataStr, duration = 0] = base64.split(':');
  const data = dataStr.split(',');

  useEffect( () => {
    let act = {};

    if (!(src in players)) players[src] = new Audio(src);

    const player = players[src];

    act = {
      play: () => {
        for (const player in players)
          players[player].stop()
      },
      timeupdate: () => {
        setState(prev => ({ ...prev, current: player.currentTime }));
      },
      ended: () => {
        setState(prev => ({ ...prev, current: 0 }));
      }
    }

    for (const actKey in act) player.addEventListener(actKey, act[actKey]);

    setState({ player });

    return () => {
      for (const actKey in act) player.removeEventListener(actKey, act[actKey]);
    }
  }, []);

  const paused = player?.paused;
  const Icon = paused ? IconPlay : IconStop;

  return (
    <>
      <div className={styles.audio}>
        <Icon
          className={styles.audioIcon}
          onClick={() => player[paused ? 'play' : 'pause']()}
        />
        {data.map((item, i) => (
          <div
            key={i}
            className={classNames(styles.audioRisk, current > +duration / data.length * i && styles.active)}
            style={{ height: item + '%' }}
          />
        ))}
        <div className={styles.audioDuration}>{getDuration(+duration || 0)}</div>
      </div>
    </>
  );
}