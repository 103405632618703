const earth = 6378.137,  //radius of the earth in kilometer
  pi = Math.PI,
  m = (1 / ((2 * pi / 360) * earth)) / 1000,
  cos = Math.cos;

export const getRect = ([lat, long], metr) => {
  return [
    [
      lat + (metr * m),
      long + (metr * m) / cos(metr * (pi / 180)),
    ],
    [
      lat - (metr * m),
      long - (metr * m) / cos(metr * (pi / 180)),
    ]
  ]
}

export const isSimilar = (args, [lat, long], metr) => {
  const [[rla, rlo], [lla, llo]] = getRect(args, metr);

  return (lla < lat && lat < rla) && (rlo > long && long > llo);
}