import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from '@/react-redux';
import { InputSimple } from '@/ui/Input/InputSimple';
import { pathTo, setTo } from '@/helpers/dash';
import { Label } from '@/components/Label/Label';
import { Tag, TagContainer } from '@/components/Base/Tag';
import SearchIcon from '@/ui/icons/search.svg';
import Edit from '@/ui/icons/edit.svg';
import styles from './Search.css';
import { selectorSearch, selectSelection } from '@/store/selectors';
import {TaskLine} from '@/components/TaskCard/TaskCard';
import { store } from '@/store/client';
import { setItem } from '@/store/app/actions';
import { mergeDeep } from '@/helpers/mergeDeep';
import { actions } from '@/hooks/actions';
import { v4 as genId } from 'uuid';
import { getIcons } from '@/modules/Header/helpers';
import {state, useBoard} from "@/modules/Board/BoardContext";
import {byType} from "@/modules/Search/byType";
import classNames from "classnames";

const icons = {
  search: SearchIcon,
  pen: Edit,
}

const context = {
  filters: {},
  selection: [],
  isSelected: (...path) => pathTo(context.filters, path, false),
  clear: () => {},
  onSelect: ({ id: tag, group }) => {
    const isSelect = context.isSelected(group, tag);

    const { filters, selection } = context;

    if (!selection.length) {
      context.clear();
      return state.handleProject({
        filters: mergeDeep({}, filters, { [group]: { [tag]: !isSelect }})
      })
    }

    const entity = selection.reduce((acc, id) => ({
      ...acc,
      [id]: {
        // TODO: мб прибавлять текстовое поле
        entities: [{
          $find: {
            group,
          },
          $body: isSelect ? null : {
            group,
            id: tag,
          },
          $else: isSelect ? null : {
            $push: {
              group,
              id: tag,
            }
          },
          $delete: isSelect,
        }]
      }
    }), {});

    store.dispatch(setItem(['next', 'entity'], p => mergeDeep(p, entity)))
  },
};

const prevent = e => {
  e.stopPropagation();
  e.nativeEvent.stopPropagation();
}

const isVisible = ({ type = '' } = {}) => ['project', 'user', 'date', 'tag', 'invite'].includes(type);

export const Search: React.FC = ({ placeholder }) => {
  const { id, filters } = useBoard(['id', 'filters']);

  const [value, onChange] = useState(null);
  const [selection, filtersSea] = useSelector(selectSelection, shallowEqual);

  Object.assign(context, {
    value,
    filters: filtersSea || filters,
    selection,
    clear: () => {
      onChange('');
    }
  })

  const items = useMemo(() => {
    const res = [], items = context.filters;

    for (const group in items)
      for (const tag in items[group])
        if (id !== tag)
          res.push(
            <TagContainer
              isLock
              key={`${tag}-${group}`}
              group={group}
              id={tag}
              isVisible={isVisible}
              onMouseUpCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                context.onSelect({ id: tag, group });
              }}
            />
          );

    return res;
  }, [filtersSea, filters]);

  const icon = getIcons(icons, selection.length ? 'pen' : 'search');
  const isOpen = typeof value === 'string';

  return (
    <>
      <div className={classNames(styles.wrapper, isOpen && styles.open)}>
        <div className={styles.root} onMouseUp={prevent} onTouchEnd={prevent}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.result} children={items}/>
          <InputSimple
            data-name="search"
            onFocus={() => {
              onChange('');
            }}
            onBlur={() => {
              setTimeout(() => {
                onChange(null);
              }, 300);
            }}
            className={styles.input}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
      <div className={classNames(styles.bg, isOpen && styles.open)}>
        <SearchBody {...context} />
      </div>
    </>
  );
}

export const SearchBody: React.FC = ({ value, isSelected = () => false, onSelect = () => {}, onCreate = () => {}}) => {
  const {id} = useBoard(['id']);

  const { projects = [],  tasks = [], groups = []} = useSelector(selectorSearch(id, value), shallowEqual, [id, value]);

  if (value === null) return null;

  const res = [];

  for (const re of projects) {

  }

  for (const {group, items} of groups) {
    if (!group) continue;
    const render = byType[group.type] || byType._;
    res.push(
      <Label onClick={() => onSelect({group: group.id, view: 'button'})} key={`label/${group.id}`}
             className={styles.label} children={group.title}/>,
      render({isSelected, onSelect, group, items, value })
    )
  }

  res.push(
    // <Label key={`label/new`} className={styles.label} children={<Create id={id} />} />,
    tasks.length ? <Label key={`tasks`} className={styles.label} children='Задачи' /> : null,
  )

  for (const task of tasks)
    res.push(
      <div>
        <TaskLine onClick={() => onSelect({ id: task.id, type: 'task' })} data={task} />
      </div>
    )

  if (value) {
    const act = e => {
      e.preventDefault();
      e.stopPropagation();
      const tasks = value.split('\n').map(text => ({
        id: genId(),
        text: [text, '\n'],
        type: 'task',
        entities: [{ id }],
      }));

      actions.entities.create(...tasks);
      onSelect(...tasks.map(({ id }) => ({ type: 'task', id })));
    }
    res.push(<div className={styles.btn} children={`+ ${value}`} onMouseDown={act} onTouchEnd={act}/>,);
  }

  return <>{res}</>;
}

