import styles from './Editor.css';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { SearchBody } from '@/modules/Search/Search';
import { caret } from '@/components/Editor/helpers';

const getPos = (container, block, inner) => {
  const c = container.getBoundingClientRect();
  const b = block?.getBoundingClientRect() || { width: c.width };
  const { left, width, top, height } = inner.getBoundingClientRect();

  return {
    left: 0,
    top: top + height - c.top
  }
}

export const resolve = (actions, from = document) => {
  for (const name in actions) from?.addEventListener(name, actions[name]);

  return () => {
    for (const name in actions) from?.removeEventListener(name, actions[name]);
  };
}

const prevent = e => {
  e.preventDefault();
  e.stopPropagation();
};


export const Mention = ({ editor, addBlocks, portal }) => {
  const ref = useRef(), callback = useRef({});

  const [state, setState] = useState(null);

  global.mentionState = state;

  const { pos, range, search = '' } = state || {};

  useEffect(() => {
    let isCurrent = false;

    const handle = (e) => {
      setState((prev) => {
        const selection = window.getSelection();

        if (!isCurrent || !selection) return null;

        const { isCollapsed = true, focusOffset, anchorNode, anchorOffset } = selection;

        // SAFARI
        if (anchorNode === null) {
          if (prev?.range) prev?.range();
          return prev;
        }

        let size = -1, range, search, is;

        if (isCollapsed) {
          const [symbol, ...text] = (anchorNode?.textContent)?.split(/\s/).find(item => {
            const min = size + 1, max = min + item.length;

            size = max;

            return min <= anchorOffset && anchorOffset <= max;
          }) || '';

          is = ['@', '#'].includes(symbol);
          search = ['@', '#'].includes(symbol) ? text.join('') : null;

          range = () => {
            try {
              const selection = window.getSelection();

              selection.removeAllRanges();
              const range = document.createRange();
              const index = anchorNode?.textContent?.indexOf(symbol + text.join(''));

              if (index === -1) return;

              range.setStart(anchorNode, index);
              range.setEnd(anchorNode, index + text.length + 1);

              selection.addRange(range);
            } catch (e) {
              console.error(e);
            }
          }
        } else {
          search = selection.toString();
          caret.save(selection);
          range = caret.restore
        }

        return {
          search,
          range,
          pos: (is || search) && getPos(editor.current, ref.current, selection.getRangeAt(0)),
        };
      })
    };

    const actions = {
      selectstart: (e) => {
        (isCurrent = editor.current?.contains(e.target))
      },
      focusout: (e) => {
        isCurrent = false;
        setState({})
      },
      selectionchange: handle,
    };

    return resolve(actions);
  }, [])

  callback.current.onSelect = (...items) => {
    if (range) range();
    addBlocks(...items);
  }

  const result = useMemo(() => (
    <SearchBody value={search} onSelect={(...args) => callback.current.onSelect(...args)} />
  ), [search])

  if (portal) return pos ? createPortal(result, portal) : null;

  return (
    <div
      data-type='mention'
      ref={ref}
      onMouseDown={prevent}
      onTouchStart={prevent}
      className={classNames(styles.mention, pos && styles.active)}
      style={pos || {}}
      children={pos && result}
    />
  );
}
