import {useRef, useState} from 'react';
import {throttle} from "@/helpers/thtrottle";
import {useWindow} from "@/helpers/hooks";
import {assign} from "@/helpers/assign";

const isDisable = () => !!global.mentionState?.pos

export const useOverScroll = (ref, { onOpen, onClose }) => {
  const ctx = useRef({ isScroll: false, isWheel: false, scroll: null, wheel: null, canOverScroll: true, scrollTop: 0 });
  const events = useRef({});

  assign(events.current, {
    onOpen: throttle(onOpen, 300),
    onClose: throttle(onClose, 300),
  })

  useWindow({
    scroll: () => {
      if (isDisable()) return;
      const { isScroll, scroll } = ctx.current;
      const { scrollTop } = ref.current;
      const { onOpen } = events.current;

      // Для телефона
      if (scrollTop < 0 && !isScroll) onOpen();

      if (!isScroll) {

        setTimeout(() => {
          ctx.current.isScroll = true;
          ctx.current.scrollTop = scrollTop;
        }, 10)
      }

      clearTimeout(scroll);
      ctx.current.scroll = setTimeout(() => {
        ctx.current.isScroll = false;
        ctx.current.scrollTop = scrollTop;
      }, 100);
    },
    // Для десктопа
    wheel: (event) => {
      if (isDisable()) return;

      const { onOpen } = events.current;
      const { scrollTop, isWheel, wheel } = ctx.current;

      const isTop = scrollTop === 0;

      if (isTop && !isWheel && event.deltaY < 0) onOpen();

      ctx.current.isWheel = true;

      clearTimeout(wheel);
      ctx.current.wheel = setTimeout(() => {
        ctx.current.isWheel = false;
      }, 100);
    },
  },[], { target: ref })
}

export const OverScroll = ({ containerRef, children }) => {
  const [state, setState] = useState(false);

  useOverScroll(containerRef, {
    onOpen: () => setState(true),
    onClose: () => setState(false)
  });

  return children(state)
}