import React, { createElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Editor.css';
import {
  addBlocks,
  domToValue,
  listenMutation,
  onPaste,
  render,
} from '@/components/Editor/helpers';
import classNames from 'classnames';
import { Mention } from '@/components/Editor/Mention';
import { Side } from '@/components/Editor/Side';
import { Component } from '@/components/Editor/EditorComponent';
import { config } from '@/components/Editor/config';
import {useBoard} from "@/modules/Board/BoardContext";
import {isEqual} from "@/helpers/dash";

const hash = config;
const addonsDef = {
  Side,
  Mention
}

interface IEditorProps {
  contentEditable?: true;
  custom?: Record<string, unknown>;
  portal?: unknown;
  value?: unknown[];
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  render?(args: { body: React.ReactNode, onEnter: any }): React.ReactNode;
  onEnter?(args: unknown, handlers: unknown): void;
  onChange?(args: unknown): void;
}

const span = (item, i) => {
  const data = {
    key: `${i}${item.id as string}`,
    contentEditable: false,
  };

  for (const key in item)
    data[`data-${key}`] = item[key];

  return <span {...data}/>
}

const defCustom = {};

const renderDef = args => args.body;

export const Editor: React.FC<IEditorProps> = props => {
  const { contentEditable, addons = addonsDef, custom = defCustom, portal, value, className, autoFocus, placeholder, render: _render = renderDef } = props;
  const { onEnter, onChange: onChangeProps, onClick } = props;

  const ref = useRef<HTMLDivElement>(null), focus = useRef<boolean>(false);

  const [nodes, setNodes] = useState([]);

  if (ref.current)
    ref.current.components = nodes;

  const component = useMemo(() => {
    const map = { ...hash, ...custom };

    return (item, node) => (
        createElement(map[item.type] || map.tag, { ...item, node, isLock: true })
    );
  }, [custom]);

  const getState = (value, key = null) => render(value, contentEditable ? span : component, key);

  const [{ key, children }, setState] = useState<any>(getState(value));

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
    if (!focus.current || !contentEditable)
      setState(getState(value));
  }, [autoFocus, value]);

  const onChange = useCallback((mutation) => {
    const res = domToValue(ref.current);

    if (!isEqual(res, value) && onChangeProps) onChangeProps(res);
  }, [onChangeProps]);

  useEffect(() => {
    if (!contentEditable || !ref.current) return;

    return listenMutation({ onChange, setNodes, ref });
  }, [children, contentEditable]);

  const enter = (e) => {
    e.preventDefault();
    onEnter(domToValue(ref.current), { setValue });
    ref.current.focus();
  }

  const setValue = useCallback((value) => { setState(render(value, component, Date.now())) }, []);

  const focused = useMemo(() => ({
    onKeyDown: e => {
      if (e.key === 'Enter') {
        if (e.shiftKey) return;

        if (!onEnter) return;

        enter(e);
      }
    },
    onFocus: () => {
      focus.current = true;
    },
    onBlur: () => {
      focus.current = false;
    },
  }), [onEnter]);

  // TODO: переделать
  const { task, id } = useBoard(['id', 'task']);
  const entities = [task?.id, id].filter(Boolean);

  const opt = { editor: ref, addBlocks: addBlocks(ref, entities), portal, focus };

  const body = (
    <>
      <div
        {...focused}
        key={key}
        data-type='editor'
        tabIndex={-1}
        className={styles.content}
        ref={ref}
        contentEditable={contentEditable}
        suppressContentEditableWarning
        onPaste={onPaste(opt.addBlocks)}
        children={children}
        spellCheck={false}
        onClick={onClick}
        style={{ '--placeholder': `"${placeholder}"` }}
      />
      {nodes.map((node, i) => <Component key={(node.dataset.id || node.dataset.group || '') + i} node={node} component={component} />)}
      {contentEditable && (
        <>
          {Object.values(addons).map((Component, i) => <Component key={i} {...opt} />)}
        </>
      )}
    </>
  );

  return (
    <div className={classNames(styles.root, className)}>
      {_render({ body, onEnter: enter })}
    </div>
  );
}