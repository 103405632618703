import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './Switch.css';

export const Switch = memo(({ label, value, options, onChange }) => (
  <>
    {label}
    <div className={styles.root}>
      {options.map(item => (
          <div
              className={classNames(styles.item, item.value === value && styles.current)}
              children={item.name}
              onClick={() => onChange(item.value)}
          />
      ))}
    </div>
  </>
))
