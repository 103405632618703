import { useEffect } from 'react';
import {v4 as genId} from "uuid";
import { pathStore, store } from '@/store/client';
import { setItem, setItems } from '@/store/app/actions';
import { messageType, sendMessage } from '@/components/Layout/LayoutMessages';
import {ErrorTypeEnum} from "@/types";

const { host, protocol } = window.location;

const token = pathStore(['token']);
export const userId = Object.keys(pathStore(['user'], {}))[0];

const handlers = new Map(), path = `ws${protocol === 'https:' ? 's' : ''}://${host}/ws?token=${token || userId}`;

handlers.set((item) => {
  const { errors: errorsItem = {} } = item;

  const { auth, ...errors } = errorsItem;

  for (const entity in errors) {
    for (const id in errors[entity]) {
      const item = errors[entity][id];
      sendMessage({ text: item.text, type: messageType.error })
    }
  }

  if (auth && auth?.type !== ErrorTypeEnum.userEmpty)
    sendMessage({ text: auth.text, type: messageType.error })
}, [])
handlers.set((item) => {
  console.group('UPDATE')
  console.log(item);
  console.groupEnd();
}, [])

let requests: any[] = [], timeout: NodeJS.Timeout;
export let ws: WebSocket;

export enum socketType {
  open = 'open',
  connect = 'connect',
  close = 'close',
}

export const pathConnection = ['app', 'connection'];

export const yieldToMain = () => (
  new Promise(resolve => {
    setTimeout(resolve, 0);
  })
)

const connect = () => {
  store.dispatch(setItem(pathConnection, socketType.open));

  ws = new WebSocket(path);

  ws.onopen = () => {
    store.dispatch(setItems(
      [pathConnection, socketType.connect]
    ));

    if (timeout) clearTimeout(timeout);

    for (const req of requests) onSend(req);

    requests = [];
  };

  ws.onclose = () => {
    store.dispatch(setItem(pathConnection, socketType.close));
    timeout = setTimeout(connect, 5000);
  };

  ws.onmessage = async evt => {
    await yieldToMain()
    const item = JSON.parse(evt.data);

    for (const handler of handlers.keys()) {
      await yieldToMain()
      handler(item);
    }

  };
}

window.onUpdate = async (a) => {
  for (const handler of handlers.keys()) {
    await yieldToMain()
    handler(a);
  }
}

if (token) connect();

export const onSend = (next) => {
  if (!ws) {
    connect();
  }

  if (!ws || ws.readyState !== ws.OPEN) return requests.push(next);

  console.group('SEND');
  console.log(...next);
  console.groupEnd();

  setTimeout(() => ws.send(JSON.stringify(next)), 500)
}

export const useSocket = ({ onUpdate: onUpdateHandle } = {}) => {

  useEffect(() => {
    const onUpdate = onUpdateHandle;

    if (typeof onUpdate !== 'function') return;

    handlers.set(onUpdate, []);

    return () => {
      handlers.delete(onUpdate);
    };
  }, [onUpdateHandle]);

  return onSend;
}