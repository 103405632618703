import { IStore } from '@/store';
import { pathTo } from '@/helpers/dash';
import { mutation } from '@/helpers/mutate';
import { mergeDeepReplace } from '@/helpers/mergeDeep';

type path = (string | number)[];

let base = { key: null };

export function selectItem<Result = unknown> (path: path = [], def?: unknown) {
  return (store: IStore = {}): Result => {
    const { next, ...other } = store;

    if (base.key !== store.key) {
      base = mutation(
        mergeDeepReplace({}, store),
        mergeDeepReplace({}, next)
      );
    }

    return pathTo(base, path, def);
  }
}
