import React from 'react';
import styles from './Download.css';
import Close from '@/ui/icons/close.svg';

let installPrompt;

// window.addEventListener('beforeinstallprompt', event => {
//   event.preventDefault();
//   installPrompt = event;
//   document.body.classList.add('installable');
// });

export const Download = () => {
  return null;

  const onClick = async () => {
    installPrompt.prompt();

    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      installPrompt = null;
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.popup}>
        <img className={styles.img} src='/favicon-dark-192.png' />
        <div className={styles.banner}>
          <div className={styles.title}>Джасп</div>
          <div className={styles.description}>Установите наше приложение бесплатно</div>
        </div>
        <button className={styles.btn} onClick={onClick}>Установить</button>
      </div>
    </div>
  )
}