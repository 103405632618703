import React from 'react';
import { selectItem } from '@/store/app/getters';
import { store } from '@/store/client';
import { messageType, sendMessage } from '@/components/Layout/LayoutMessages';
import { setItem } from '@/store/app/actions';
import { mergeDeepReplace } from '@/helpers/mergeDeep';

const def = [];

export const isEmpty = (value = []) => !value?.some(item => typeof item === 'string' && item?.length && item !== '\n');

export const render = (value: unknown = [], component = (arg, i) => {}, key?): any => {

  if (!Array.isArray(value) || !value.length) value = def;

  let last = [], ke = 0;
  const items = [last];

  for (const item of value) {
    if (item === '\n') {
      last = [];
      items.push(last);
    } else if (typeof item === 'string') {
      last.push(item);
    } else if (typeof item === 'object' && item) {
      item.key = ke++;
      last.push(component(item, {}));
    }
  }

  return {
    key: key || JSON.stringify(value),
    children: items.map((children, i) => <div key={i} children={children} />),
  };
};

export const domToValue = ({ childNodes } = {}) => {
  const res = [];

  for (const element of childNodes) {
    const { tagName, textContent, dataset } = element;

    if (dataset && Object.keys(dataset).length) {
      res.push({ ...dataset } );
      continue;
    }

    if (!tagName) {
      const text = textContent?.replace(/&nbsp;/g, '');

      if (text.length) res.push(text);

      continue;
    }

    for (const item of domToValue(element)) {
      if (typeof item !== 'string' || item === '\n') {
        res.push(item);
        continue;
      }

      res.push(tagName === 'B' ? `<b>${item}</b>` : item);
    }

    res.push('\n');
  }

  while (res[res.length - 1] === '\n') {
    res.pop();
  }

  return res;
};

// export const mutateToValue = (val, root, mutation) => {
//   const getPos = (node) => {
//     if (node === root) return [];
//
//     const { parentNode } = node;
//
//     return [...getPos(parentNode), ...Array(Array.from(parentNode.children).indexOf(node)).fill('\n')]
//   }
//
//   const { addedNodes, type, previousSibling, oldValue, nextSibling, removeNodes, target } = mutation;
//
//   if (type === 'childList') {
//     for (const node of Array.from(addedNodes)) {
//       const skip = getPos(node);
//
//       const index = val.findIndex((item) => {
//         if (!skip.length) return true;
//         if (item === skip[0]) skip.shift();
//       })
//
//       val.splice(index, 0 , '\\n')
//     }
//   }
//
//   if (type === 'characterData') {
//     console.log(oldValue, target.textContent);
//   }
//
//   console.log('pos', val);
// }

export const listenMutation = ({ ref, onChange, setNodes }) => {
  let observe = () => {};

  const mo = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      const { target } = mutation;

      if (target === ref.current && !target?.firstChild)
        target.appendChild(document.createElement('div'))

      if (target?.firstChild?.tagName === 'Br') {
        target.replaceChild(target?.firstChild, document.createTextNode(''))
      }

      onChange(mutation);
      for (const node of Array.from(mutation.addedNodes))
        observe(node);
    }
  })

  observe = (node) => {
    if (node?.tagName === 'BR' && node === node?.parentNode?.firstChild) {
      node.remove();
      return;
    }

    const isComponent = node.contentEditable === 'false';

    mo.observe(node, {
      characterData: true,
      characterDataOldValue: true,
      childList: !isComponent,
      attributes: isComponent,
    })

    if (isComponent) {
      setNodes(prev => {
        const set = new Set(prev)
        set.add(node);
        return Array.from(set);
      })
      return;
    }

    Array.from(node.childNodes).forEach(observe)
  }

  observe(ref.current);

  return () => {
    mo.disconnect();
  };
}

const urlR = /(https?:\/\/|www\.)[\w.\/\:\-\_\#]+/g

export const regexReplace = (str, urlR, get = (url) => ({ url })) => {
  let match, prev = 0, res = []
  while ((match = urlR.exec(str)) != null) {
    res.push(str.slice(prev, match.index), get(match[0]))

    prev = match.index + match[0].length;
  }
  if (prev !== str.length)
    res.push(str.slice(prev))

  return res;
}

export const onPaste = addBlocks => async e => {
  e.preventDefault();

  const { files, items } = e?.clipboardData;
  const res = [];

  console.log(items, files);
  if (files.length) {
    for (const file of files) {
      res.push(file);
    }
  } else {
    for (const item of items) {
      if (item.kind === 'file') {
        res.push(item.getAsFile())
      }
      if (item.kind === 'string') {
        const str = await (new Promise(res => { item.getAsString(str => res(str)); }));

        for (const item of str.split('\n')) {
          res.push(...regexReplace(item, urlR, (url) => ({ url, type: 'link' })));
        }
      }
    }
  }

  console.log('res', res)

  addBlocks(...res);
};

export const Media = (ref = {}, entities = []) => {
  const body = new FormData();

  return {
    add: (item) => {
      const id = Math.random();
      body.append(id, item);
      return { wait: id, type: 'media' };
    },
    resolve: async () => {
      if (!Array.from(body.keys()).length) return;

      const next = await fetch(`/api/media?${entities.map(val => `entities=${val}`).join('&')}`, {
        headers: {
          token: selectItem(['token'])(store.getState()),
        },
        method: 'POST',
        body
      })
        .then(async next => {
          const a = await next.json();

          if (next.status !== 200) throw a
          else return a
        })
        .catch(e => {
          sendMessage({ text: 'Картинка не сохранена', type: messageType.error });

          console.log(e);

          return;
        })

      const ids = Object.keys(next?.items || {}).reverse();

      if (ref.current) {
        body.forEach((val, key) => {
          const node = ref.current?.components.find(item => {
            return item.dataset.wait == key;
          });

          const id = ids.pop();

          if (id) {
            delete node.dataset.wait;
            node.dataset.id = id;
          } else {
            node.remove();
          }
        })
      }

      store.dispatch(setItem(['media'], prev => mergeDeepReplace(prev, next)));

      return ids;
    },
  }
}

export const addBlocks = (ref, entities) => (...items) => {
  const media = Media(ref, entities);

  window.getSelection()?.deleteFromDocument();

  const args = [];

  const range = window.getSelection()?.getRangeAt(0);

  const span = (base) => {
    const { type = 'tag' } = base;

    if (type !== 'tag')
      args.push(document.createElement('br'))

    const block = document.createElement('span');
    block.contentEditable = 'false';

    for (const key in base)
      block.dataset[key] = base[key];

    args.push(block);

    return [type !== 'tag' && document.createElement('br'), block].filter(Boolean);
  }

  const insert = document.createDocumentFragment();

  for (const item of items) {
    if ('File' in window && item instanceof File) {
      insert.append(
        ...span(media.add(item))
      )
    } else if (typeof item === 'object') {
      insert.append(
        ...span(item)
      )
    } else {
      insert.append(
        document.createTextNode(item),
        document.createElement('br')
      )
    }
  }

  void media.resolve();

  range?.insertNode?.(insert);
  if (insert.lastChild)
    range?.selectNode?.(insert.lastChild);
  range?.collapse?.(true);

  return args;
}

export const caret = {
  state: [],
  save: selection => {
    const state = [];

    for (let i = 0; i < selection.rangeCount; i++) {
      state.push(selection.getRangeAt(i).cloneRange())
    }

    caret.state = state;
  },
  restore: () => {
    const selection = window.getSelection();

    selection.removeAllRanges();
    for (const range of caret.state) {
      selection.addRange(range);
    }
  }
}