import React, {memo, useEffect, useState} from 'react';
import { createPortal } from 'react-dom';

const cb = new Map();

const mo = new MutationObserver((mutationList) => {
  for (const mutation of mutationList) {
    const item = mutation.target;

    const func = cb.get(item);

    if (func) func();
  }
})

export const Component = memo(({ component, node }) => {
  const [data, setData] = useState(node.dataset);

  useEffect(() => {
    cb.set(node, () => setData(node.dataset))

    mo.observe(node, { attributes: true });

    return () => {
      cb.delete(node);
    }
  }, []);

  const g = component(data, node);

  return createPortal(g, node);
})