export const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, ...sources) => {
  try {
    if (!sources.length) return target;

    // @ts-ignore
    if (!target) return mergeDeep(...sources);

    const source = sources.shift();

    if (source) {
      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) target[key] = {};
            mergeDeep(target[key], source[key]);
          } else if (Array.isArray(target[key]) && Array.isArray(source[key])) {
            target[key].push(...source[key]);
          } else {
            if (!target) target = {};
            target[key] = source[key];
          }
        }
      } else if (Array.isArray(target) && Array.isArray(source)) {
        target.push(...source);
      }
    }

    return mergeDeep(target, ...sources);
  } catch(e) {
    console.log('mergeDeep', e)
  }
}

export const mergeDeepReplace = (target, ...sources) => {
  try {
    if (!sources.length) return target;

    // @ts-ignore
    if (!target) return mergeDeepReplace(...sources);

    const source = sources.shift();

    if (source) {
      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) target[key] = {};
            mergeDeepReplace(target[key], source[key]);
          } else {
            if (!target) target = {};
            target[key] = source[key];
          }
        }
      } else if (Array.isArray(target) && Array.isArray(source)) {
        return source;
      }
    }

    return mergeDeepReplace(target, ...sources);
  } catch(e) {
    console.log('mergeDeep', e)
  }
}
