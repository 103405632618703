import React from 'react';
import {useApp} from "@/hooks/useApp";
import {Switch} from "@/components/Switch/Switch";

export const themeDef = [ {
  bg: '#0D0D0D',
  card: '#191919',
  primary: '#6757e6',
},
  {
  bg: '#202124',
  card: '#28282b',
  primary: '#746AC2',
}, {
  bg: '#f3f5fa',
  card: '#fefeff',
  primary: '#ffd89b',
},{
  bg: '#F9CE60',
  card: '#fad167',
  primary: '#daa825',
}, {
  bg: '#F1EDFB',
  card: 'fefeff',
  primary: '#F1EDFB',
}];

const render = ({ bg, card, primary } = {}) => (
  <div style={{ width: '3em', height: '4em', background: bg, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <div style={{ width: '2em', height: '1em', background: card, borderRadius: 'var(--border-radius-m) var(--border-radius-m) var(--border-radius-m) var(--border-radius-s)', margin: 2 }} />
    <div style={{ width: '2em', height: '1em', background: primary, borderRadius: 'var(--border-radius-m) var(--border-radius-m) var(--border-radius-s)', margin: 2 }} />
  </div>
)

export const Theme = () => {
  const [theme, setTheme] = useApp(['theme'], themeDef[0]);
  const [themes, setThemes] = useApp([], themeDef, 'themes');

  return (
    <Switch value={theme} onChange={setTheme} options={themes} children={render}/>
  )
}