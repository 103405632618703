const SLOT = '_';

const alpha = /[a-zA-Zа-яА-Я]/;
const numeric = /[0-9]/;

const checks = {
  '#': (char: string) => numeric.test(char),
  a: (char: string) => alpha.test(char),
};

const getPosForRemove = (mask = '', maskedValue = '', pos = 0): number => {
  for (let i = pos; i > 0; i--) {
    if (checks[mask[i - 1]] && maskedValue[i - 1] !== SLOT) return i;
  }

  return -1;
};

const getPosForInsert = (mask = '', shift = 0, pos = 0): number => {
  let index = 0;

  for (let i = pos; i < mask.length; i++) {
    if (checks[mask[i]]) {
      if (index >= shift) return i;
      index++;
    }
  }

  return mask.length;
};

const fill = (mask = '', pos = 0) => {
  let res = '';

  for (let i = pos; i < mask.length; i++) {
    res += checks[mask[i]] ? SLOT : mask[i];
  }

  return res;
};

type Inject = (props: {
  mask: string;
  value: string;
  valueToMask?: boolean;
  startCountPos?: number;
  endCountPos?: number;
}) => {
  masked: string;
  unmasked: string;
  placeholder: string;
  injected: number;
};

export const inject: Inject = ({
  mask,
  value,
  valueToMask,
  startCountPos = 0,
  endCountPos = value.length,
}) => {
  let index = 0;
  let masked = '';
  let unmasked = '';
  let injected = 0;
  const buffer = value.split('');

  for (const char of mask) {
    if (checks[char] && index >= value.length) break;

    if (checks[char]) {
      index = buffer.findIndex((v, i) => i >= index && checks[char](v));

      if (index === -1) break;

      if (index >= startCountPos && index < endCountPos) injected++;

      masked += buffer[index];
      unmasked += buffer[index];
      index++;
    } else {
      if (valueToMask && buffer[index] === char) index++;

      masked += char;
    }
  }

  const placeholder = fill(mask, masked.length);
  return { masked, unmasked, placeholder, injected };
};

type Change = (
  mask: string,
  prev: string,
) => (e) => string;

export const change: Change = (mask, prev) => (e) => {
  const { prevStart, prevEnd } = e.target;

  const current = (e.target.value || e.target.innerText) as string;
  if (!mask) return current;

  const prevs: string[] = [];
  let index = 0;

  const newStart = e.target.selectionStart;
  let start = prevStart;
  let end = prevEnd;

  const insertion = newStart > start && current.slice(start, newStart);

  if (!insertion && start === end) {
    end = getPosForRemove(mask, prev, start);
    start = end - 1;
  }

  for (let i = 0; i < prev.length; i++) {
    if (checks[mask[i]] && (i < start || i >= end)) prevs.push(prev[i]);
    if (i === start - 1) index = prevs.length;
  }

  const value = (
    insertion
      ? [...prevs.slice(0, index), ...insertion, ...prevs.slice(index)]
      : prevs
  ).join('');

  const { masked, unmasked, injected } = inject({
    mask,
    value,
    valueToMask: insertion?.length > 1,
    startCountPos: index,
    endCountPos: index + insertion?.length,
  });

  const pos = insertion ? getPosForInsert(mask, injected, start) : start;

  e.target.value = masked;
  e.target.setSelectionRange(pos, pos);

  return unmasked;
};
