type objectType = Record<string, unknown>;

const invert = (a, c, i) => { a[c] = i; return a; };

const change = (a, i, v) => { a[i] = (a[i] || 0) + v; }

// TODO: сделать оптимальный алгос с минимальными изменениями
export const differ = (from = [], to = []) => {
  const res = { delete: {}, create: {} }, byId = {};
  const next = to.reduce(invert, {});

  for (const i in from) {
    const index = Number(i)
    const value = from[index];

    if (index === next[value]) {
      delete next[value];
      continue;
    }

    res.delete[index] = value;

    if (value in next)
      res.create[next[value]] = value;
    else
      byId[value] = false;

    delete next[value];
  }

  for (const value in next) {
    const index = next[value];

    res.create[index] = value;
    byId[value] = true;
  }

  return [res, byId];
};

// differ(['a', 'b', 'c', 'd', 'e'], ['e', 'd', 'c', 'b', 'a']);
// differ(['a', 'b', 'c', 'd', 'e'], ['c', 'b', 'f', 'a', 'e']);
// differ(['a', 'b', 'c', 'd', 'g', 'e'] , ['a', 'b', 'c', 'e', 'f']);

export const difr = (prev = [], next = []) => {
  const res = {};

  for (const item of prev) {
    res[JSON.stringify(item)] = item;
  }

  for (const item of next) {
    delete res[JSON.stringify(item)];
  }

  return Object.values(res);
}

export const pathTo = (
  obj: unknown,
  path: (string | number)[] = [],
  def?: unknown
): unknown => {
  let res: unknown = obj;

  for (const pathElement of path) {
    if (typeof res !== 'object' || res === null || !(pathElement in res)) return def;

    res = (res as objectType | unknown[])[pathElement];
  }

  return res;
};

export const keyBy = (array, by, func = a => a) => {
  const res = {};

  for (const arg of array) {
    const item = func(arg);
    res[item[by]] = item;
  }

  return res;
}

export const setTo = (
  obj: objectType,
  path: (string | number)[],
  value: unknown
): unknown => {
  if (!path.length) return typeof value === 'function' ? value(obj) : value;

  const base = obj;

  let res: unknown = base;

  for (const pathElement of path.slice(0, -1)) {
    res = res[pathElement] = res[pathElement] || {};
  }

  const lastKey = path.slice(-1)[0];

  res[lastKey] = typeof value === 'function' ? value(res[lastKey]) : value;

  return base;
};

export const deleteTo = (
  obj: objectType,
  path: (string | number)[],
): unknown => {
  const base = obj;

  let res: unknown = base;

  for (const pathElement of path.slice(0, -1)) {
    res = res[pathElement] = res[pathElement] || {};
  }

  const lastKey = path.slice(-1)[0];

  delete res[lastKey];

  return base;
};

export const pickBy = (obj, keys) => {
  const next = {};

  if (!obj) return next;

  for (const key of keys) {
    if (key in obj)
      next[key] = obj[key];
  }

  return next;
}

export const genKey = (length = 4) => {
  // Use crypto.getRandomValues if available
  if (
    typeof crypto !== 'undefined'
    && typeof crypto.getRandomValues === 'function'
  ) {
    const tmp = new Uint8Array(Math.max((~~length)/2));
    crypto.getRandomValues(tmp);
    return Array.from(tmp)
      .map(n => ('0'+n.toString(16)).substr(-2))
      .join('')
      .substr(0,length);
  }

  // fallback to Math.getRandomValues
  let ret = "";
  while (ret.length < length) {
    ret += Math.random().toString(16).substring(2);
  }
  return ret.substring(0,length);
}

export const isEqual = (a, b) => {
  if (!a || !b) {
    return !a === !b;
  }

  if (a instanceof Element || b instanceof Element)
    return a === b;

  if (typeof a === 'object' && typeof b === 'object') {
    if (Object.keys(a).length !== Object.keys(b).length)
      return false;

    for (const key in a)
      if (!isEqual(a[key], b[key]))
        return false;

    return true;
  }

  return a === b;
}

global.isEqual = isEqual