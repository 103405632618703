import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as React from 'react';
import {shallowEqual, useSelector} from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import styles from './Task.css';
import { Editor } from '@/components/Editor/Editor';
import { actions } from '@/hooks/actions';
import classNames from 'classnames';
import { MessagesListContainer } from '@/components/Message/MessagesList';
import { handleKey } from '@/helpers/hooks';
import {useBoard} from "@/modules/Board/BoardContext";
import {usePrevious} from "@/hooks/usePrevious";
import {debounce} from "@/helpers/debounce";
import {useOverScroll} from "@/lib/Scroll";
import {pathStore} from "@/store/client";

export const convert = {
  to: (text = [], entities = []) => {
    const res = [];
    const tags = [...entities];

    for (const item of text) {
      if (item === null)
        res.push(tags.shift())
      else
        res.push(item);
    }

    return [res, tags];
  },
  from: (items, tags = []) => {
    const text = [], entities = [];

    for (const item of items) {
      if (typeof item === 'string') {
        text.push(item);
        continue;
      }
      text.push(null);
      entities.push(item);
    }

    return ({ text, entities: [...entities, ...tags] });
  }
}

const onChange = (data, base, tags) => debounce(300, (next) => {
  const item = { id: base.id, ...convert.from(next, tags), type: 'task' };

  actions.entities[!data ? 'create' : 'update'](item);
});

export const Task: React.FC = ({ style: styleBase, board, isOpen: isOpenProps, onClose, data, base }) => {
  const ref = useRef();
  const ref2 = useRef();
  const [isOpen, setOpen] = useState(isOpenProps);

  useEffect(() => {
    if (!ref2.current) return;

    ref2.current.style.transition = 'none';
    setTimeout(() => {
      ref2.current.style.transition = null;
      setOpen(isOpenProps)
    }, 0)
  }, [isOpenProps])

  const { id, text, entities } = data || base || {};

  const [value, hidden] = useMemo(() => convert.to(text, entities), [text, entities]);

  useOverScroll(ref, { onOpen: onClose });

  return (
    <>
      {isOpen ? (
        <style>
          {`[data-task="${id}"] > * { opacity: 0; }`}
          {`[data-name="groups"] { opacity: 0; }`}
          {`[data-name="board"] > div { transform: ${board.transform}; filter: blur(3px); opacity: 0; transform-origin: ${board.transformOrigin}; }`}
          {`[data-name="board"] > ymaps { filter: blur(3px); opacity: 0; }`}
        </style>
      ) : (
        <style>
          {`[data-name="task"] { opacity: 0; }`}
          {`#root { overflow: hidden; } `}
          {`[data-name="board"] > div { transform-origin: ${board.transformOrigin}; }`}
        </style>
      )}
      <div data-name='task' className={classNames(styles.roo, isOpen && styles.animate)} onClick={onClose}>
        <div ref={ref2} style={styleBase} className={styles.root} onClick={(e) => e.stopPropagation()}>
          <div ref={ref} className={styles.body}>
            {(
              <Editor
                key={id}
                autoFocus={!data && isOpen && !pathStore(['app', 'loader'], true)}
                value={value}
                className={classNames(styles.task, styles.tas)}
                placeholder='Заголовок'
                onChange={onChange(data, base, hidden)}
                contentEditable
              />
            )}
            {data && (
              <div className={styles.tabs}>
                <MessagesListContainer id={id} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const TaskContainer = () => {
  const { task, target, handleProject } = useBoard(['task', 'target', 'handleProject']);
  const { id } = task || {};

  const data = useSelector(selectItem(['entity', id], null), shallowEqual, [id]);

  const prev = usePrevious(data);

  const onClose = useCallback(() => handleProject({ task: null, style: null }), []);

  const { task: style, board, scale } = useMemo(() => {
    const tar = target

    const { top, left, width, height } = tar?.getBoundingClientRect() || { top: 0, left: 0, width: 100, height: 100 };
    const all = { width: window.innerWidth, height: window.innerHeight }

    const w = Math.min(680, all.width);
    const k = width / w;
    const l = w / width;

    const hei = `${height}px * ${w / width}`

    const translateY = `calc(${top}px - var(--header-h) - ${(1 - k) / 2} * ${hei})`
    const translateX = `calc(${left}px - 50vw - ${(1 - k) / 2} * ${w}px)`
    const scale = k;

    const cx = left + width /2;
    const cy = top + height / 2;
    const cxp = cx / all.width;
    const cyp = top / all.height;

    return {
      task: {
        height: `calc(${hei})`,
        opacity: 0,
        transform: `translateY(${translateY}) translateX(${translateX}) scale(${scale})`,
      },
      board: {
        transform: `translateX(${(0.495 - cxp) * 100}%) translateY(${(0.18 - cyp) * 100}%) scale(${l})`,
        transformOrigin: `${cx}px ${cy}px`
      },
    };
  }, [target]);

  useEffect(handleKey('Escape', onClose), [onClose]);

  return <Task style={style} board={board} data={data || prev} base={task} isOpen={!!id} onClose={onClose}/>
}