import React, { useEffect, useState } from 'react';
import ReactHelmet from 'react-helmet';
import { themeMode } from '@/data/types';
import config from 'config.json';
import classNames from 'classnames';
import {hexToHSL} from "@/helpers/color";

const calc = (name, color) => [99, 80, 65, 0].reduce((acc, percent) => {
  acc[name + `-${percent}`] = color + Math.round(255 / 100 * percent).toString(16).padStart(2, '0');
  return acc;
}, { [name]: color });


const text = (light) => ({
  '--text': light ? '#fff' : '#202124',
  '--text-secondary': light ? '#999' : '#a6a6a6',
  '--text-placeholder': light ? '#444' : '#d3d7db',
})

export const Helmet: React.FC = ({ id, theme, animation, title: titleProps, description }) => {
 const title = titleProps ? `${titleProps} | ${config.title}` : config.title;
 const { bg, card, primary } = theme;

 const style = {
   '--primary': primary,
   ...calc('--bg', bg),
   ...calc('--card', card),
   '--card-active': 'var(--bg-80)',
   '--card-hover': 'var(--bg-65)',
   ...text(hexToHSL(bg)[2] < 40),
 }

  return (
    <ReactHelmet bodyAttributes={{class: classNames(!animation && 'animation-disabled', navigator.deviceMemory <= 2 && 'slow')}}>
      <link rel="manifest" href={`/manifest.json?id=${id}&platform=${navigator.platform}`}/>
      <title>{title}</title>
      <script src="https://static.yoomoney.ru/checkout-js/v1/checkout.js"></script>

      <link rel="apple-touch-icon" sizes="192x192" href="/favicon-192.png"/>
      <style children={`:root { ${Object.entries(style).map(([key, value]) => `${key}: ${value};`).join('\n')} }`} />

      {[512, 192, 32, 16].map(size => (
        ['dark', 'light'].map(theme => (
          <link
            key={`${theme}-${size}`}
            rel="icon"
            type="image/png"
            media={`(prefers-color-scheme:${theme})`}
            sizes={`${size}x${size}`}
            href={`/favicon-${theme}-${size}.png`}
          />
        ))
      ))}

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="apple-mobile-web-app-status-bar-style"
              content={theme === themeMode.light ? 'default' : 'black-translucent'}/>
        <meta name="theme-color" content={bg}/>

        {[
          {width: 320, height: 568, ratio: 2},
          {width: 375, height: 667, ratio: 2},
          {width: 621, height: 1104, ratio: 3},
          {width: 375, height: 812, ratio: 3},
          {width: 414, height: 896, ratio: 2},
          {width: 414, height: 896, ratio: 3},
          {width: 768, height: 1024, ratio: 2},
          {width: 834, height: 1112, ratio: 2},
          {width: 834, height: 1194, ratio: 2},
          {width: 1024, height: 1366, ratio: 2}
        ].map(({width, height, ratio}) => (
          <link
            key={`${width}-${height}-${ratio}`}
            href={`/splashscreen.png?width=${width}&height=${height}&ratio=${ratio}`}
            media={`(device-width: ${width}px) and (device-height: ${height}px) and (-webkit-device-pixel-ratio: ${ratio})`}
            rel="apple-touch-startup-image"
          />
        ))}

        <meta name="description" content={description}/>
        <meta property="og:title" content={title}/>
    </ReactHelmet>
);
};
