import React, { useEffect, useMemo, useState } from 'react';
import {
  differenceInDays,
  differenceInMinutes,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { shallowEqual, useSelector } from '@/react-redux';
import { Virtualized, VirtualizedItems } from '@/lib/Virtualized';
import { getDates, getTimes } from './helpers';
import styles from './Calendar.css';
import { Time } from './Time';
import { Day } from '@/modules/BoardCalendar/Day';
import { Container, Card, Wrapper } from '@/modules/BoardCalendar/Card';
import { selectTaskOrderTime } from '@/store/selectors';
import {pickBy} from "@/helpers/dash";

export const CalendarContainer = ({ id: root, filters, group, ...props }) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const config = { offsetTop: 120, delta: 60, dayW: 300, minuteH: .8, minMin: 30, dayH: 60 };

  const { delta, dayH, dayW, minuteH } = config;

  const days = useMemo(getDates, [now]), firstDay = startOfMonth(days[0]);

  const [tasks, taskTimes] = useSelector(
    selectTaskOrderTime({ id: root, group,  filters, firstDay, ...config }),
    shallowEqual
  );

  const times = useMemo(() => getTimes(delta), [delta]);

  const position = useMemo(() => ({
    scrollLeft: differenceInDays(now, firstDay) * dayW - (window.innerWidth / 2) + dayW / 2,
    scrollTop: differenceInMinutes(now, startOfDay(now)) * minuteH - (window.innerHeight / 2),
  }), []);

  const base64 = window.btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" width="${dayW}" height="${minuteH * delta}">
      <rect x="0" y="0" width="100%" height="100%" stroke="#9999991a" fill="none" />
    </svg>
  `);

  const daysMap = day => ({ day, x: differenceInDays(day, firstDay) * dayW, y: 'current', h: dayH, w: dayW })

  const render = {
    days: ({ style, day }) => <Day key={day} day={day} style={style}  />,
    times: ({ times, height }) => <Time times={times} height={height} />,
    tasks: ({ id }) => {
      const Component = id ? Container : Card;

      if (!id) id = 'new';

      return (
        <Wrapper key={id} {...config} {...(taskTimes[id] || {})} firstDay={firstDay}>
          {props => <Component key={id} root={root} group={group} {...props} {...config} firstDay={firstDay} id={id} />}
        </Wrapper>
      )
    },
    timeline: ({ style }) => <div className={styles.timeline} style={style} />,
  }

  const data = {
    days: days.map(daysMap),
    times: [{ times, height: delta * minuteH, x: 'current', y: dayH, w: 1, h: times.length * delta * minuteH + dayH * 2 }],
    tasks: [...tasks, { w: '100%', h: '100%', y: 'current', x: 'current' }],
    timeline: [{
      x: differenceInDays(now, firstDay) * dayW,
      y: dayH + differenceInMinutes(now, startOfDay(now)) * minuteH,
      h: 1,
      w: dayW
    }],
  }

  const items = Object.keys(render)
    .reduce((acc, key) => (
      acc.concat(
        data[key]?.map(args => ({ ...args, render: render[key] }))
      )
    ), []);

  const child = item => item.render(item);

  const virti = pos => <VirtualizedItems {...pos} items={items} children={child} />;

  return (
    <Virtualized
      {...props}
      style={{ background: `url(data:image/svg+xml;base64,${base64})`, backgroundPositionY: `calc(var(--header-h) + 50px)` }}
      position={position}
      children={virti}
    />
  )
}