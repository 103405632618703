import React from 'react';
import Cam from '@/ui/icons/cam.svg'
import Audio from '@/ui/icons/mic.svg'
import Stop from '@/ui/icons/stop.svg'
import styles from './MessageStream.css';

export const MessageStream = ({ video, audio, update, close }) => {
  if (!(video || audio)) return null;

  return (
    <div className={styles.root}>
      <Cam onClick={update(() => video?.[video?.paused ? 'resume' : 'pause']?.())} />
      <Audio onClick={update(() => audio?.[audio?.paused ? 'resume' : 'pause']?.())} />
      <Stop onClick={update(() => { close(); return {} })} />
    </div>
  );
}