import React from 'react';
import classNames from 'classnames';
import styles from './Skeleton.css';

interface ISkeletonProps {
  count: number;
  duration?: number;
  width?: string;
  wrapper?: React.ReactNode;
  height?: string;
  circle?: boolean;
  className?: string;
}

export const Skeleton: React.FC<ISkeletonProps> = ({ count, width, height, circle, className, wrapper: Wrapper }) => {
  const elements = [];

  for (let i = 0; i < count; i++) {
    const style: React.CSSProperties = { width, height };

    if (circle) {
      style.borderRadius = '50%';
      style.height = style.width;
      style.display = 'inline-block';
    }

    elements.push(
      <span
        key={i}
        className={classNames(styles.item, className)}
        style={style}
        children='&zwnj;'
      />,
    );
  }

  return <>{elements}</>;
}

Skeleton.defaultProps = {
  count: 1,
  duration: 1.2,
  width: null,
  wrapper: null,
  height: null,
  circle: false,
}

export default Skeleton;