import { shallowEqual, useSelector } from '@/react-redux';
import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import { InputSimple } from '@/ui/Input/InputSimple';
import { Actions } from '@/components/Actions/Actions';
import { useForm } from '@/hooks/useForm';
import styles from './Tag.css';
import { compose } from '@/react-redux';
import { actions } from '@/hooks/actions';
import { selectTag } from '@/store/selectors';
import { groupType } from '../../../types/group';
import { format, prepare } from '@/components/Base/formatter';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { sendMessage } from '@/components/Layout/LayoutMessages';
import { selectItem } from '@/store/app/getters';
import { Thumb, ThumbPicker } from '@/modules/Thumb/Thumb';
import {useRole} from "@/components/Base/helpers";

const Def = ({ children, ...other }) => <span {...other} children={children()} />;

const d = () => null;

export const Tag: React.FC = ({ isLock, isSelect, data, role, className, group, onUpdate = d, onDelete, options = [], ...props }) => {

  const { Control, reset, handleSubmit } = useForm({ initialValue: data });

  const resetAll = useCallback(() => { reset(data); }, [data]);

  const Tag = isLock ? Def : Actions;

  let { thumb } = data;

  const style = {
    // backgroundImage: `linear-gradient(90deg, ${thumb.color}25 0%, ${thumb.color}15 100%)`,
    // color: thumb.color,
    opacity: isSelect ? .5 : 1
  }

  const icon = (
    <Control
      name='thumb'
      component={({ value }) => <Thumb type={data?.type} thumb={value} className={styles.icon}/>}
    />
  )

  const children = ({ isOpen } = {}) => (
    <>
      {icon}
      <Control
        name='title'
        component={InputSimple}
        props={{
          disable: !isOpen || isLock,
          onBlur: resetAll,
          className: classNames(styles.title),
          // style: { color: thumb.color },
          placeholder: data?.id ? 'Заголовок' : 'Добавить',
        }}
      />
    </>
  )

  return (
    <Tag
      resize
      onBlur={handleSubmit(compose(onUpdate, prepare))}
      options={[
        ...options,
        { title: <Control name='thumb' component={ThumbPicker} /> },
        [groupType.user, groupType.project].includes(data?.type) && role,
        ...(data?.type === 'invite' ? [{
          title: 'Скопировать приглашение',
          onClick: () => {
            copyToClipboard(window.location.origin + '/invite/' + data.id);
            sendMessage({ text: 'Приглашение скопировано' })
          }
        }, /*{ title: 'Выбрать проект' }*/ ] : []),
        { title: 'Удалить', onClick: onDelete }
      ].filter(Boolean)}
      className={classNames(styles.wrapper, className)}
      style={style}
      {...props}
      group={group}
      data-id={data?.id}
      children={children}
    />
  );
}

export const TagText = ({ group, id }) => {
  const data = useSelector(selectTag(group, id), shallowEqual);

  return data.title;
};

export const TagThumb = ({ stream, group, id, className, onClick }) => {
  const data = useSelector(selectTag(group, id), shallowEqual);

  return <Thumb stream={stream} alt={data?.title} type={data?.type} thumb={data?.thumb} onClick={onClick} className={className} />;
};

const isVisibleDef = (args) => true;

const Button = ({ data, current, onClick }) => (
  <div className={classNames(styles.button, current && styles.current)} onClick={onClick}>
    <Thumb type={data?.type} thumb={data?.thumb} className={styles.icon}/>
    <div className={classNames(styles.title)}>{data?.title}</div>
  </div>
);

const Select = ({ group, id: current, onUpdate }) => {
  const data = useSelector(selectItem(['group', group]));
  const { title, data: tags = {}, ...other } = data || {};

  const onChange = (id) => onUpdate({ id, group });

  return (
    <div className={styles.select}>
      <div className={styles.group}>{title}</div>
      <div className={styles.items}>
        {Object.keys(tags).map(id => <TagContainer Custom={Button} group={group} current={id === current} id={id} onClick={() => onChange(id)} />)}
      </div>
    </div>
  )
}

const views = {
  _: Tag,
  button: Select,
}

export const TagContainer = ({ id, group, isVisible = isVisibleDef, view, Custom, ...props }) => {
  const data = useSelector(selectTag(group, id), shallowEqual);
  const role = useRole(data?.id);

  const act = useMemo(() => ({
    onUpdate: data => actions.tags.update(group, id, data),
    onDelete: () => actions.tags.delete(group, id),
    data,
  }), [data])

  if (!isVisible(data)) return null;

  const Render = Custom || views[view] || views._;

  return <Render id={id} group={group} role={role} {...act} {...props} />;
}
