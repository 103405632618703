import React from 'react';
import { useSelector } from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import styles from './Header.css';
import { pathConnection, socketType } from '@/hooks/useSocket';
import Skeleton from '@/ui/Skeleton/Skeleton';
import { HelmetContainer } from '@/modules/Helmet/HelmetContainer';
import { HeaderRight } from './HeaderRight';
import { Download } from '@/modules/Header/Download';
import { useMeasure } from '@/hooks/useMeasure';
import {HeaderLeftContainer as HeaderLeft} from '@/modules/Header/HeaderLeft';
import {HeaderSearch, Search} from "@/modules/Header/Search";

const Loader = () => {
  const state = useSelector(selectItem(pathConnection));

  if (state === socketType.open)
    return (
      <div className={styles.loader}>
        <Skeleton count={1} width='100%' height='100%' />
      </div>
    )

  return null;
};

export const Header: React.FC = () => {
  const { ref, bounds } = useMeasure();

  return (
    <>
      <header id="header" className={styles.root}>
        <HelmetContainer />
        <Loader />
        <div ref={ref}>
          <Download />
          <div className={styles.row}>
            <HeaderLeft />
            <HeaderSearch />
            <HeaderRight />
          </div>
        </div>
      </header>
      <style children={`:root { --header-h: ${bounds.height}px }`} />
    </>
  );
}
