export const queryParser = (str: string = '', isMap = false) => {
  str = decodeURI(str);
  const query = {};
  const pairs = (str[0] === '?' ? str.slice(1) : str).split('&');

  for (const item of pairs) {
    const [key, value] = item.split('=');

    if (!value?.length) continue;

    const items = value.split(',');

    query[key] = isMap ? items.reduce((acc, item) => ({ ...acc, [item]: true }), {}) : items;
  }
  return query;
};

export const objToQuery = ({ ...obj } = {}) => {
  let string = '';

  for (const group in obj) {
    const items = Object.keys(obj[group]).filter(key => obj[group][key]);

    if (!items.length) continue;

    string += `${group}=${items.join(',')}&`;
  }

  return string.slice(0, string.length - 1);
};
