import classNames from 'classnames';
import styles from '@/pages/MainPage/MainPage.css';
import { groupType } from '../../../types/group';
import React from 'react';
import { init } from '@/hooks/actions';
import { userId } from '@/hooks/useSocket';
import { Button, size } from '@/ui/Button';
import { Tag, TagContainer } from '@/components/Base/Tag';
import {Project} from "@/components/Base/Project";
import {Thumb} from "@/modules/Thumb/Thumb";
import {Parallax} from "@/pages/MainPage/Parallax";
import {param} from "@/pages/MainPage/MainPage";
import {TaskCard} from "@/components/TaskCard";

export const around = (from, to, val, k = 1) => (Math.max(from, Math.min(to, val)) - from) / (to - from) * k
export const between = (from, to, val, k = 1) => (Math.max(from, Math.min(to, val)) - from) / (to - from) * k


export const blocks = [
  {
    classNameInner: styles.blockMain,
    other: (
      <Parallax
        className={styles.parallax}
        children={(from, to) => (
          <>
            {[{ emoji: "🌳" }, { emoji: "🎈" }, { emoji: "💥" }, { emoji: "👩‍💻" }, { emoji: "🏖" }, { emoji: "🎉" }].map((thumb, ii) => {
              const i = ii + 1;
              const percent = from / to
              const is2 = i % 2 === 0;
              const is3 = i % 3 === 0;

              const top = `calc(50% + ${percent * 100}% + ${is3 ? `(40% + ${i}0%) * ${1 - percent}` : '0%'} - ${i * 10 * (1 - percent)}%)`;
              const left = `calc(50% + (${1 - percent}) * ${i}0%)`;
              const scale = Math.max(i * 2 * (1 - percent), .9);
              const filter = `blur( calc(${i * 2}px * ${1 - percent}) )`

              return (
                <div style={{
                  transform: `translate(${is2 ? '-50' : '50'}%, -50%) scale(${scale})`,
                  top,
                  filter,
                  opacity: percent === 1 ? 0 : 1,
                  [is2 ? 'left' : 'right']: left,
                }} key={i} className={styles.project}>
                  <Thumb thumb={thumb} type={groupType.project}/>
                </div>
              )
            })}
            <div style={{
              transform: `translate(-50%, -50%)`,
              top: `calc(50% + ${100 * (from / to)}%)`,
              left: `50%`,
              opacity: (from / to) > 0.99 && (from / to) < 1 ? 1: 0
            }} className={styles.project}>
              <Thumb />
            </div>
          </>
        )}
      />
    ),
    children: [
      <h1 className={classNames(styles.title, styles.h1)}>Новая форма твоих дел</h1>,
      <p className={classNames(styles.desc, styles.desc1)}>Настрой свое пространство</p>,
    ]
  },
  {
    other: (
      <Parallax
        className={styles.parallax}
        children={(from, to) => {
          return (
            <>
              <div style={{
                transform: `translate(-50%, -50%) scale(${Math.max(1, (from / to) * 30)}) rotate(${(from / to) * 180}deg)`,
                top: `calc(50% + ${100 * (from / to)}%)`,
                left: `50%`,
                opacity: (from / to) > 0 && (from / to) < 1 ? 1: 0
              }} className={styles.project}>
                <Thumb />
              </div>
            </>
          )
        }}
      />
    )
  },
  {
    className: styles.b180,
    other: (
      <Parallax
        className={styles.parallax}
        style={{ height: '180vh' }}
        children={(from, to) => {
          return (
            <div className={styles.columns} style={{ opacity: from > 0 && (from / to) < .9 ? 1 : 0, transform: `translateY(${(from / to) * 20}%) scale(${Math.max(1, (from - (to /1.99)) / to  * 6)})` }}>
              {[1,2,3,4,5].map((_, i) => (
                <div className={styles.column} style={{ color:'red', transform: `translateY(${((i % 2 === 0) ? 9 : 0) + (from / to) * ((i % 2 === 0) ? -1 : 1) * (i % 2) * 12}%)` }}>
                  {[1,2,3,4,5,6,7].map(() => (
                    <TaskCard id={null} style={{ height: '40vh' }} />
                  ))}
                </div>
              ))}
            </div>
          )
        }}
      />
    )
  },
  {
    className: styles.b360,
    other: (
      <Parallax
        className={styles.parallax}
        style={{ height: '360vh' }}
        children={(from, to) => {

          const text = [
            "Тут можно творить и не только задачи",
            "Описывайте контекст ваших мыслей задач постов для социальных сетей. Все, что только может прийти к вам в голову, моментально фиксируйте тут"
          ]
          // const l = text.join(' ').split(' ').length;

          let i = 0;
          const get = () => {
            i+=1;

            const d = Math.min(
              Math.max((from - (i) * 60) / 60, 0),
              1
            );

            return {
              transform: `translateY(${10 - d * 10}%)`,
              filter: `blur(${10 - d * 10}px)`,
              opacity: d,
            }
          }

          return (
            <div className={styles.tusk} style={{ opacity: from > 0 && (from / to) < .9 ? 1 : 0 }}>
              <div>
                {text[0].split(' ').map((it) => <span style={get()}>{it}</span>)}
              </div>
              <div>
                {text[1].split(' ').map((it) => <span style={get()}>{it}</span>)}
              </div>
              <div style={{ height: 300, background: 'var(--card)', width: '100%', ...get() }}>

              </div>
              <div className={styles.message} style={get()}>
                Можем обсуждать детали в комментариях
              </div>
              <div className={classNames(styles.message, styles.my)} style={get()}>
                Да, прикольная идея, давай делать это чаще
              </div>
              <div className={styles.message} style={get()}>
                Договорились )
              </div>
            </div>
          )
        }}
      />
    )
  },
  {
    className: styles.b180,
    other: (
      <Parallax
        className={styles.parallax}
        style={{ height: '180vh' }}
        children={(from, to) => {
          return (
            <div className={styles.columns} style={{
              opacity: from > 0 && (from / to) < .9 ? 1 : 0,
              transform: `translateY(${(from / to) * 20}%) scale(${Math.max(1, 4 - ((from - (to /5)) / to) * 8)})`
            }}>
              {[1,2,3,4,5].map((_, i) => (
                <div className={styles.column} style={{
                  transform: `translateY(${((i % 2 === 0) ? 9 : 0) + (from / to) * ((i % 2 === 0) ? -1 : 1) * (i % 2) * 12}%)`
                }}>
                  {[1,2,3,4,5,6,7].map(() => (
                    <TaskCard id={null} style={{ height: '40vh' }} />
                  ))}
                </div>
              ))}
            </div>
          )
        }}
      />
    )
  },
  {
    className: styles.b180,
    other: (
      <Parallax
        className={styles.parallax}
        style={{ height: '180vh' }}
        children={(from, to) => {
          return (
            <div className={styles.rows} style={{
              opacity: from > 0 && (from / to) < .9 ? 1 : 0,
              transform: `translateX(${(from / to) * -100}vw) )`
            }}>
              {[1,2,3,4,5].map((_, i) => (
                <div className={styles.row} style={{
                  transform: `translateX(${((i % 2 === 0) ? 9 : 0) + (from / to) * ((i % 2 === 0) ? -1 : 1) * (i % 2) * 12}%)`
                }}>
                  {[1,2,3,4,5,6,7].map(() => (
                    <TaskCard id={null} style={{ height: '30vh' }} />
                  ))}
                </div>
              ))}
            </div>
          )
        }}
      />
    )
  },
  // {
  //   children: [
  //     <h2 className={styles.title}>Любое свойство задачи - это <TagContainer id={JSON.stringify({ type: groupType.tag, title: 'теги' })}/></h2>,
  //     <p className={styles.desc}>Перемещая задачу между колонками или изменяя содержимое задачи - вы изменяете тег</p>
  //   ]
  // },
  // {
  //   children: [
  //     <h2 className={styles.title}><TagContainer id={JSON.stringify({ type: groupType.tag, title: 'Теги' })}/> можно объединять в вектор</h2>,
  //     <p className={styles.desc}>Относительно него мы отобразим все задачи проекта. Снизу представлена навигация по ним</p>
  //   ]
  // },
  // {
  //   children: [
  //     <h2 className={styles.title}>Работайте с командой</h2>,
  //     <p className={styles.desc}>Чтобы пригласить команду просто создайте вектор пользователей. Роли будут в настройках тега.</p>
  //   ]
  // },
  // {
  //   children: [
  //     <h2 className={styles.title}>Никаких лишних кнопок</h2>,
  //     <p className={styles.desc}>Чтобы совершить какое-то действие просто нажмите пкм на тег, группу или задачу</p>
  //   ]
  // },
  // {
  //   children: [
  //     <h2 className={styles.title}>Попробуйте сами</h2>,
  //     <p className={styles.desc}>И убедитесь в том, что все действительно просто</p>
  //   ]
  // },
].map((item, i) => ({ ...item, key: '' + i }))