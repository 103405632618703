export function levenshteinDistance(a, b) {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function minLevenshteinSubstring(mainStr, subStr) {
  const mainLen = mainStr.length;
  const subLen = subStr.length;

  if (subLen > mainLen) return levenshteinDistance(mainStr, subStr);

  let minDistance = Infinity;

  for (let i = 0; i <= mainLen - subLen; i++) {
    const currentSub = mainStr.substring(i, i + subLen);
    const currentDistance = levenshteinDistance(currentSub, subStr);
    minDistance = Math.min(minDistance, currentDistance);
  }

  return minDistance;
}

export const isFuzzyMatch = (search, text, maxDistance = 2) => {
  if (!text) return false;

  const lowerSearch = search.toLowerCase();
  const lowerText = text.toLowerCase();

  return minLevenshteinSubstring(lowerText, lowerSearch) <= maxDistance;
}

window.isFuzzyMatch=isFuzzyMatch