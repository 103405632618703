import { selectGroupOrder, selectUser } from '@/store/selectors';
import { objToQuery, queryParser } from '@/helpers/queryParser';
import {useCallback, useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router';
import { useSelector } from '@/react-redux';
import {v4 as genId} from "uuid";
import {isEqual, pickBy} from '@/helpers/dash';
import { history } from '@/index';

const windowTask = JSON.parse(window.name || '{}');

const histo = {
  from: ({ hash, search }, params = {}) => {
    const prev = state;
    let task = hash?.slice(1);

    if (task === 'new') {
      task = genId()
      history.push({ hash: task });
    }

    if (task === prev.task?.id) {
      params.task = prev.task;
    }

    return {
      task: task ? Object.assign({ id: task, text: ['', '\n'], entities: [{ id: params.id }] }, windowTask) : null,
      ...params,
      filters: Object.freeze(queryParser(search, true))
    }
  },
  to: (args, ignore) => {
    const { type, id, group, filters, task } = args;

    if (!ignore)
      history.push({ pathname: '/' + [type, id, group].filter(Boolean).join('/'), search: objToQuery(filters), hash: task?.id });

    return args;
  }
}

export const stack = new Set();

export const state = {
  task: null,
  target: null,
  filters: {},
  style: null,
  handleProject: (args, ignore = state.ignore) => {
    const { target, task } = args;

    if (task && !target) args.target = document.querySelector(`[data-task="${task.id}"]`);

    const next = histo.to({ ...state, ...args }, ignore);

    for (const key in next)
      state[key] = next[key];

    state.revalidate()
  },
  revalidate: () => {
    for (const { setState, params } of Array.from(stack))
      setState(pickBy(state, params));
  }
}

export const context = {
  stack: (item) => {
    stack.add(item);
    return () => { stack.delete(item); }
  },
  state
};

export const Context = () => {
  const l = useLocation(), p = useParams();

  const ignore = !('type' in p);

  const user = useSelector(selectUser, isEqual)
  const order = useSelector(selectGroupOrder(p.id || user), isEqual);

  const params = histo.from(l, p);

  Object.assign(state, {
    id: user,
    type: 'user',
    ...params,
    ignore,
    group: params.group || order[0],
  })

  useEffect(state.revalidate, Object.values(state));

  return null;
}

/*
* Варианты того как можно сделать:
* - добавить параметр в группу, но тогда нельзя будет
* - вызывать это чере событие из браузера
* - добавить параметры в урл, но тогда непонятно как он будет машать основной выдаче
*
* */

export const useBoard = (params?, name?) => {
  const { state: init, stack } = context;

  if (!params) return init;

  const [state, setState] = useState(() => pickBy(init, params));

  useEffect(() => (
    stack({
      setState: (next) => {
        setState(prev => {
          if (name)
            console.log(name, next, prev);
          return isEqual(prev, next) ? prev : next;
        });
      },
      params
    })
  ), params);

  return state;
}