import React from 'react';
import styles from './Code.css';

export const Code = ({ lang, text }) => (
  <div className={styles.root}>
    <span>{lang}</span>
    <pre className={styles.pre}>
      <code className={styles.code}>
        {text}
      </code>
    </pre>
  </div>
)