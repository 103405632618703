import React from 'react';
import styles from './Bar.css';
import {useBoard} from "@/modules/Board/BoardContext";
import classNames from 'classnames';
import { Groups } from '@/modules/Bar/Groups';


export const swipe = {
  delta: 10,                             // min distance(px) before a swipe starts. *See Notes*
  // preventScrollOnSwipe: false,           // prevents scroll during swipe (*See Details*)
  trackTouch: true,                      // track touch input
  trackMouse: true,                     // track mouse input
  rotationAngle: 0,                      // set a rotation angle
  swipeDuration: Infinity,               // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true },  // options for touch listeners (*See Details*)
}

export const Bar: React.FC = ({ }) => {
  const { projects } = useBoard(['projects']);
  return (
    <>
      <div className={styles.bg}>powerd by Jusp</div>
      {!projects && (<div className={classNames(styles.root, styles.item)}>
        <Groups />
      </div>)}
    </>
  )
}