import { groupType } from '@/data/groups';

export const DEFAULT_COLUMN = '';
export const CREATE_COLUMN = 'create';

export const globalTags: { [name: string]: ITag } = {
  [DEFAULT_COLUMN]: {
    id: DEFAULT_COLUMN,
    title: 'Неотсортированное',
    slug: 'default',
    type: groupType.tag,
    // lock: true
  },
  [CREATE_COLUMN]: {
    id: CREATE_COLUMN,
  },
};
