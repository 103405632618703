import React, { useEffect, useRef, useState } from 'react';
import { Virtualized, VirtualizedItems } from '@/lib/Virtualized';
import styles from './Thumb.css';
import { caret, Media } from '@/components/Editor/helpers';
import IconImage from '@/ui/icons/image.svg';
import { groups } from '@/data/groups';
import classNames from 'classnames';
import { ImageContainer } from '@/components/Media/Media';
import {hexToHSL, HSLToHex, stringToColour} from '@/helpers/color';
import { useWindow } from '@/helpers/hooks';
import {useBoard} from "@/modules/Board/BoardContext";
import { sendMessage } from '@/components/Layout/LayoutMessages';
import {AutoSize} from "@/lib/Autosizer";
import {Lottie} from "@/lib/Lottie";
import Smile from '@/ui/icons/emoji.svg';
import Plus from '@/ui/icons/plus.svg';
import Img from '@/ui/icons/image.svg';
import imageCompression from 'browser-image-compression';


export const emojis = [
 null,
 "❤",
 "😒",
 "😳",
 "🥳",
 "😡",
 "😧",
 "😢",
 "😁",
 "😮",
 "💀",
 "🙄",
 "😍",
 "💋",
 "🧐",
 "😘",
 "💎",
 "🔐",
 "⌨️",
 "💸",
 "📝",
 "🙈",
 "👨‍🏫",
 "⏳",
 "📬",
 "😔",
 "💡",
 "👛",
 "💱",
 "🎄",
 "☃️",
 "⛄️",
 "🧡",
 "💛",
 "💚",
 "💙",
 "💜",
 "🖤",
 "🤍",
 "🤎",
 "😻",
 "🥰",
 "💌",
 "💘",
 "💔",
 "🧭",
 "💝",
 "📣",
 "🧮",
 "📁",
 "📂",
 "🦠",
 "🤒",
 "🤢",
 "🤕",
 "🤧",
 "🧼",
 "🚑",
 "🤮",
 "💊",
 "🧻",
 "👩‍⚕️",
 "👨‍⚕️",
 "🤷‍♀️",
 "🤷‍♂️",
 "🧽",
 "🤔",
 "😌",
 "😭",
 "🙊",
 "😜",
 "😋",
 "😝",
 "😉",
 "😏",
 "😚",
 "😞",
 "😕",
 "😟",
 "😱",
 "☺️",
 "😴",
 "😎",
 "😿",
 "😺",
 "😸",
 "🙀",
 "😹",
 "😽",
 "😼",
 "😾",
 "🎊",
 "👿",
 "😈",
 "🔥",
 "😀",
 "😃",
 "😆",
 "😅",
 "🤣",
 "🎂",
 "😄",
 "🇧🇾",
 "🤬",
 "😤",
 "🤪",
 "😛",
 "🥵",
 "🥶",
 "🤯",
 "🌕",
 "🌖",
 "🌗",
 "🌘",
 "🌑",
 "🌒",
 "🌓",
 "🌔",
 "🌞",
 "🌝",
 "😇",
 "😯",
 "😦",
 "🥱",
 "🚕",
 "😲",
 "😊",
 "🙂",
 "🙃",
 "😷",
 "🌚",
 "🌛",
 "🌜",
 "🧟‍♀️",
 "🧟",
 "🧛‍♀️",
 "🧛",
 "🧛‍♂️",
 "🕷",
 "🦇",
 "⚰️",
 "🕸",
 "😐",
 "👻",
 "🧟‍♂️",
 "🗿",
 "🎃",
 "🤗",
 "🥺",
 "🤠",
 "🥴",
 "☠️",
 "🔍",
 "🔎",
 "✨",
 "🤫",
 "💤",
 "🤑",
 "🤭",
 "⚡️",
 "⭐️",
 "🦾",
 "🎆",
 "🤥",
 "🎇",
 "❄️",
 "🎅",
 "🧑",
 "🤶",
 "🤡",
 "🚗",
 "🍾",
 "😗",
 "😙",
 "🌟",
 "💫",
 "💞",
 "💗",
 "💓",
 "❣️",
 "👮‍♀️",
 "🚓",
 "🎮",
 "💖",
 "💕",
 "🤩",
 "🥂",
 "💉",
 "🥲",
 "🤤",
 "🥸",
 "😬",
 "🤓",
 "😑",
 "❌",
 "💯",
 "❗️",
 "💢",
 "♨️",
 "💬",
 "💭",
 "🗯",
 "🤟",
 "❕",
 "❔",
 "❓",
 "🗂",
 "😶",
 "🦉",
 "🌱",
 "🕺",
 "🐣",
 "🌲",
 "🙁",
 "💃",
 "☹️",
 "🌺",
 "🌿",
 "🌵",
 "🌴",
 "🌳",
 "💥",
 "👑",
 "🏠",
 "😰",
 "😓",
 "👩‍💻",
 "🧑‍💻",
 "👨‍💻",
 "👵",
 "😪",
 "✍️",
 "😫",
 "🏖",
 "😨",
 "🌷",
 "💩",
 "🎉",
 "💟",
 "🎈",
 "😖",
 "😩",
 "🐥",
 "🤐",
 "😂",
 "🎨",
 "🌹",
 "🌸",
 "🤖",
 "💻",
 "☝️",
 "✊",
 "✋",
 "✌️",
 "👂",
 "👃",
 "👆",
 "👇",
 "👈",
 "👉",
 "👊",
 "👋",
 "👌",
 "👍",
 "👎",
 "👏",
 "👐",
 "💪",
 "🖐",
 "🖕",
 "🖖",
 "🤌",
 "🤏",
 "🤘",
 "🤙",
 "🤚",
 "🤛",
 "🤜",
 "🤝",
 "🤞",
 "🤲",
 "🦻",
 "🙌",
 "🙏",
 "🦶",
 "🏝",
 "📊",
 "🕊",
 "🦵",
 "🦿",
 "🍿",
 "🌭",
 "🌮",
 "🍮",
 "🍰",
 "🍕",
 "🍟",
 "🍔",
 "🍪",
 "🥤",
 "☎️",
 "📞",
 "🍦",
 "🍩",
 "🥞",
 "🍢",
 "🥧",
 "🎢",
 "❤️‍🔥",
 "❤️‍🩹",
 "🐳",
 "🍡",
]

const em = emojis.reduce((acc, item) => ({ ...acc, [item]: item && <Lottie key={item} src={`/emoji/${item}`} /> }), {})

export const Emoji = ({ emoji, ...props }) => <Lottie {...props} src={`/emoji/${emoji}`} />;

const Image = ({ media }) => <ImageContainer styles={styles} id={media} />

const Video = ({ style = {}, stream, parent }) => {
 const videoRef = useRef();
 const analyzerRef = useRef();
 const audioContextRef = useRef();

 useEffect(() => {
  // TODO: визуализатор звука
  const audios = () => {
   // Создаем аудио-контекст и через него извлекаем громкость
   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
   audioContextRef.current = audioContext;

   const source = audioContext.createMediaStreamSource(stream);
   const analyser = audioContext.createAnalyser();
   source.connect(analyser);
   analyzerRef.current = analyser;

   analyser.fftSize = 256;
   const bufferLength = analyser.frequencyBinCount;
   const dataArray = new Uint8Array(bufferLength);

   const update = () => {
    analyser.getByteFrequencyData(dataArray);

    // Рассчитываем средний уровень громкости
    const average = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;

    // Изменяем размер видео в зависимости от среднего значения громкости
    const scaleFactor = Math.min(Math.max(1, average - 4), 1.3); // Вы можете настроить делитель для контроля чувствительности
    parent.current.style.transform = `scale(${scaleFactor})`;

    if (stream)
     requestAnimationFrame(update);
   };

   update();
  }

  audios();

  if (videoRef.current && stream) {
   videoRef.current.srcObject = stream;

   videoRef.current.play();
  }
 }, [stream]);

 return (
   <video
     style={{height: '100%', width: '100%', objectFit: 'cover', ...style}}
     playsInline
     webkitPlaysinline
     ref={videoRef}
     autoPlay
   />
 );
}

export const Thumb = ({ alt, mode, type = null, title, thumb = {color: null, emoji: null, media: null}, className = '', onClick, ...other }) => {
 const ref = useRef();
 if (!thumb) thumb = {};

 let Icon = groups[type]?.icon || Plus;

  if (!thumb.color && title) thumb.color = stringToColour(title)

  if (thumb.emoji) Icon = Emoji;
  if (thumb.media) Icon = Image;
  if (other.stream && mode !== 'avatar') Icon = Video;

  return (
    <div ref={ref} alt={alt} className={classNames(className, styles[mode], styles.wrapper)} style={{ color: thumb.color }} onClick={onClick}>
      {Icon && <Icon {...thumb} {...other} parent={ref} />}
    </div>
  )
}

const Color = ({ value, onChange }) => {
  const ref = useRef();
  const move = useRef();

  const onPick = (event) => {
    if (!move.current) return;

    const { left, width } = ref.current.getBoundingClientRect();
    const to = event?.pageX || event?.touches[0]?.pageX;

    onChange(HSLToHex(((to - left) / width) * 360, 100, 50))
  }

  useWindow({
    mousemove: onPick,
    touchmove: onPick,
    mouseup: () => { move.current = false; },
    touchend: () => { move.current = false; },
  }, [onPick]);

  return (
    <div
      ref={ref}
      className={styles.color}
      onMouseUp={onPick}
      onMouseDown={() => { move.current = true; }}
      onTouchStart={() => { move.current = true; }}
      onMouseMove={onPick}
      onTouchMove={onPick}
    >
      <div className={styles.picker} style={{ left: `${hexToHSL(value)[2] * 100}%` }} />
    </div>
  )
}

export const Emojies = ({ onChange, w = 46, count = 6 }) => {

  const size = w * count;

  const list = emojis.map((item, i) => ({
    value: item,
    children: em[item],
    w, h: w, y: Math.floor(i / count) * w, x: w * (i % count)
  }));

  return (
    <Virtualized
      height={size / 2}
      width={w * count}
      wrapper={{style: {scrollbarGutter: 'unset'}}}
      children={pos => (
        <VirtualizedItems
          {...pos}
          items={list}
          children={item => {
           return (
             <span className={styles.item} onClick={() => onChange(item.value)} key={item.value} {...item} />
           );
          }}
        />
      )}
    />
  )
}
const def = 'emoji';

export const ThumbPicker = ({ value = {} as any, onChange = (args: any) => {}, ...other }) => {
  const [state, setState] = useState(def);

  const { id: project } = useBoard(['id']);
  const media = Media({}, ['public', project]);

  const byType = {
    emoji: (
        <AutoSize
            children={({ width }) => (
                <Emojies w={width / 7} count={7} onChange={(emoji) => onChange({  ...value, emoji })} />
            )}
        />
    ),
   color: <Color value={value.color} onChange={(color) => onChange({ ...value, color })}/>,
   media: (
       <label className={styles.btn}>
        {value.media ? (
            <div onClick={() => onChange({ ...value, media: null })}>
             Удалить изображение
            </div>
        ) : (
            <>
             <input
                 className={styles.input}
                 type='file'
                 accept='.svg,.png,.jpeg,.jpg'
                 onChange={async e => {
                  const originFile = e.target.files[0];

                  const file = await imageCompression(originFile, {
                   maxSizeMB: 0.1,
                   maxWidthOrHeight: 320,
                  });

                  console.log(file.size, 100000)

                  media.add(file);
                  const [id] = await media.resolve();

                  onChange({ ...value, media: id });
                 }}
             />
             <IconImage /> <span>Загрузить</span>
            </>
        )}
       </label>
   )
  };

  const icons = {
   emoji: <Smile />,
   color:  <div className={styles.card} style={{ background: value.color }}/>,
   media: <Img />,
  }

  return (
    <div>
     {Object.keys(icons).map(key => <span className={classNames(styles.option, state === key && styles.active)} onClick={() => setState(state === key ? def : key)}>{icons[key]}</span>)}
     {byType[state]}
    </div>
  );
}
const all = {};