import React, { useEffect, useRef } from 'react';
import { param } from '@/pages/MainPage/MainPage';
import {throttle} from "@/helpers/thtrottle";

export const Transition = ({ container = window, classNameInner, className, children, other }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handle = () => {
      if (!ref.current || !container) return;

      const { top, height } = ref.current.getBoundingClientRect();
      const { height: heightContent } = inner.current.getBoundingClientRect();

      const max = height - heightContent;

      const y = Math.min(Math.max(-1 * top, 0), max);

      const style = {
        position: y > 0 && y !== max ? 'fixed' : 'absolute',
        top: y > 0 && y !== max ? 0 + 'px' : 'inherit',
        bottom: y === max ? 0 + 'px' : 'inherit',
      }

      Object.assign(inner.current.style, style);

      const children = inner.current?.childNodes;

      for (let i = 0; i < children.length; i++) {

        const opacity = 1 - param(0, heightContent - heightContent / children.length * i, top);

        const style = { opacity, transform: `matrix(1, 0, 0, 1, 0, ${40 * (1 - opacity)})` };

        Object.assign(children[i].style, style);
      }
    }

    handle();

    const htrottle = throttle(handle, 20);

    container?.addEventListener('scroll', htrottle);

    return () => {
      container?.removeEventListener('scroll', htrottle);
    }
  }, [])

  return (
    <div ref={ref} className={className}>
      {other}
      <div ref={inner} className={classNameInner} children={children} />
    </div>
  )
};