import { stream } from '@/lib/record';

const text = (text, selector) => (
  new Array(text.length).fill(text).map((a, i) => ({
    innerText: a.slice(0, i + 1),
    selectors: [selector],
    timeout: 50,
  }))
)

const tag = (name) => {
  const selector = '[data-name="board"] [data-name="new"] [data-name="title"]';
  return [
    { type: 'mousedown', selectors: [selector] },
    { type: 'mouseup', selectors: [selector], timeout: 500, },
    ...text(name, selector),
    { type: 'input', selectors: [selector] },
    { type: 'change', selectors: [selector] },
    { type: 'keydown', key: 'Enter', code: 'Enter',  charCode: 13, keyCode: 13, selectors: [selector] },
  ]
}
const select = (text, selector = '[data-name="task"] [data-type="editor"] > *') => {
  return [
    { type: 'selectstart', selectors: [selector] },
    { type: 'selection', text, selectors: [selector] },
    { type: 'selectionchange', selectors: [selector] },
    { type: 'mousedown', selectors: ['[data-type="mention"] [data-name="title"]'] },
    { type: 'mouseup', selectors: ['[data-type="mention"] [data-name="title"]'] },
  ]
}

export default {
  basic: [
    'Создаем проект',

    'Создаем вектор',
    { type: 'mousedown', selectors: ['[data-name="groups"] [data-name="title"]'], timeout: 1000, },
    { type: 'mouseup', selectors: ['[data-name="groups"] [data-name="title"]'], timeout: 1000, },
    text('Состояния', '#outside-provider [data-name="title"]'),
    { type: 'input', selectors: ['#outside-provider [data-name="title"]'] },
    { type: 'change', selectors: ['#outside-provider [data-name="title"]'] },
    { type: 'keydown', key: 'Enter', code: 'Enter',  charCode: 13, keyCode: 13, selectors: ['#outside-provider [data-name="title"]'] },

    'Добавляем теги',
    tag('Нужно'),
    tag('В процессе'),
    tag('Готово'),

    'Создаем задачу',
    { type: 'mousedown', selectors: ['[data-name="board"] [data-name="task-new"]'], timeout: 1000, },
    { type: 'click', selectors: ['[data-name="board"] [data-name="task-new"]'], timeout: 1000, },
    { timeout: 600, },
    text('Задача', '[data-name="task"] [data-type="editor"] > *'),
    { type: 'keydown', key: 'Escape', code: 'Escape', selectors: ['[data-name="board"]'] },
    { type: 'mousedown', selectors: ['[data-task]'], timeout: 1500, },
    { type: 'mouseup', selectors: ['[data-task]'] },

    'Назначаем тег',
    text('Задача в процессе', '[data-name="task"] [data-type="editor"] > *'),
    select('в процессе', '[data-name="task"] [data-type="editor"] > *'),
    { type: 'input', selectors: ['[data-name="task"] [data-type="editor"] > *'] },
    { type: 'change', selectors: ['[data-name="task"] [data-type="editor"] > *'] },
    { type: 'keydown', key: 'Escape', code: 'Escape', selectors: ['[data-name="board"]'], timeout: 1000 },

    'Задача переместилась',
    { type: 'focus', selectors: ['[data-task]'], timeout: 1000, },
    { type: 'mousedown', selectors: ['[data-task]'], timeout: 2000, },

    'Быстрые действия',
    { timeout: 2000 }
  ].flat(3)
}