import { useEffect, useMemo, useRef } from 'react';

const isTouchEvent = event => {
  return "touches" in event;
};

export const getPos = e => isTouchEvent(e)
  ? { x: e.touches[0].clientX, y: e.touches[0].clientY }
  : { x: e.clientX, y: e.clientY };

const { delay = 430 } = {};

const listen = (handlers, target, is?) => (
  target && Object.entries(handlers)
    .forEach(args => {
      target[is ? 'addEventListener' : 'removeEventListener'](...args, { passive: true });
    })
)

export const useLongPress = (ref, { onStart = (e) => null, onClick = () => null, onLongPress = null, onMove = (e) => null, onEnd = (e) => null }) => {
  const move = useRef(false);
  const timeout = useRef(null);
  const longPress = useRef(false);
  const pos = useRef(null);

  const actions = useRef({});

  actions.current.onClick = onClick;
  actions.current.onEnd = onEnd;
  actions.current.onLongPress = onLongPress;

  useEffect(() => {
    if (!ref.current) return;

    const handlers = {
      mousedown: e => {
        pos.current = getPos(e);
        onStart(pos.current);

        if (!actions.current.onLongPress) return;

        timeout.current = setTimeout(() => {
          longPress.current = true;
          if ('vibrate' in navigator)
            navigator.vibrate(5);
          actions.current.onLongPress(e);
        }, delay);
      },
      mouseup: e => {
        if (!pos.current) return;

        timeout.current && clearTimeout(timeout.current);

        if (!move.current) {
          if (!longPress.current) {
            actions.current.onClick(e);
          }
        }

        actions.current.onEnd(e);

        longPress.current = false;
        move.current = false;
        pos.current = null;
      },
      touchstart: e => handlers.mousedown(e),
      touchend: e => handlers.mouseup(e),
    };

    const all = {
      mousemove: e => {
        if (!pos.current) return;

        const current = getPos(e);

        const is = Object.keys(current).some(key => Math.abs(pos.current[key] - current[key]) > 5);

        if (!is) return;

        if (!longPress.current) {
          onMove(
            Object.keys(current)
              .reduce((acc, key) => { acc[key] = pos.current[key] - current[key]; return acc; }, {})
          );
        }

        timeout.current && clearTimeout(timeout.current);
        move.current = true;
      },
      touchmove: e => all.mousemove(e),
    }

    listen(handlers, ref.current, true);
    listen(all, window, true);

    return () => {
      listen(handlers, ref.current);
      listen(all, window);
    }
  }, []);

  return actions.current;
};