import { mergeDeep, mergeDeepReplace } from '@/helpers/mergeDeep';
import { pathStore, store } from '@/store/client';
import { setItem, setItems } from '@/store/app/actions';
import {v4 as genId} from 'uuid';
import {differ, isEqual, setTo} from '@/helpers/dash';
import { DEFAULT_COLUMN } from '@/data/tags';
import { groupType } from '@/data/groups';
import { order } from '@/modules/Board/BoardContainer';
import { messageType, sendMessage } from '@/components/Layout/LayoutMessages';
import { selectAuth, selectTag, selectUser } from '@/store/selectors';
import {isDate, isNumber, isUuid} from '@/helpers/isUuid';
import { debounce } from '@/helpers/debounce';
import { selectItem } from '@/store/app/getters';
import { copyToClipboard } from '@/helpers/copyToClipboard';

const dispatch = next => store.dispatch(
  setItem(['next'], prev => mergeDeep(prev, next))
);

const set = data => {
  const res = {};
  for (const key in data) {
    res[key] = Array.isArray(data[key]) ? [{ $set: data[key] }] : data[key];
  }

  return res;
}

export const init = (data, state = store?.getState()) => {
  const createdAt = (new Date()).toISOString();

  return Object.assign({
    id: genId(),
    createdAt: createdAt,
    updatedAt: createdAt,
    createdBy: selectUser(state),
    $create: true,
  }, set(data))
};

export const actions = {
  tags: {
    create: (g, data) => {
      console.log('data', data)
      if (!data.title) return;

      // TODO: validate tag
      if (groupType.numb === data?.type && isNumber(data?.id || data?.title)) {
        data.id = data.title;
        return data;
      }
      if (groupType.date === data?.type && isDate(data?.id || data?.title)) {
        data.id = data.title;
        return data;
      }

      const title = (data?.title)
        .toUpperCase().trim()
        .replace(/\s\s+/g, ' ');

      // if already exist tag

      const id = data?.id || JSON.stringify({ title, type: data?.type });

      if (title === DEFAULT_COLUMN) {
        sendMessage({ text: 'Нельзя создать такой тег', type: messageType.error })
        return;
      }
      const res = {};

      setTo(res, ['group', g, 'data', id], data);

      setTo(res, ['group', g, 'order'], [{ $push: id, $uniq: true }]);


      dispatch(res);

      return { id, ...data };
    },
    update: (g, id, data) => {
      const prev = selectTag(g, id)(store.getState());

      if (prev.type !== 'invite' && data.title.toUpperCase().trim() !== prev.title.toUpperCase()) {
        // sendMessage({ text: 'Пока что нельзя изменять тег', type: messageType.error })
        // return;
        data.tasks = selectItem(['group', g, 'data', id, 'tasks'], [])(store.getState());

        const next = actions.tags.create(g, data);
        actions.tags.delete(g, id, next.id);
      }

      dispatch(setTo({}, ['group', g, 'data', id], data));
    },
    delete: (g, id, replaceId) => {
      const invite = pathStore(['invite', id]);
      dispatch({
        group: {
          [g]: {
            data: {
              [id]: { $delete: true },
            },
            order: [{ $find: id, $body: replaceId, $else: { $push: replaceId }, $delete: !replaceId }]
          }
        },
        entity: {
          [JSON.stringify({ entities: { $contains: [{ group: g, id }] } })]: {
            entities: [{
              $find: { group: g, id },
              $body: replaceId ? { group: g, id: replaceId } : null,
              $else: { $push: { group: g, id: replaceId } },
              $delete: !replaceId
            }]
          }
        },
        ...(invite ? { invite: { [id]: { $delete: true } } } : {})
      })
    }
  },
  members: {
    create: (...create) => {
      if (!create.length) return;

      const member = {};

      for (const next of create) {
        const item = init(next);

        member[item.id] = item;
      }

      dispatch({ member });
    },
    update: (...updates) => {
      if (!updates.length) return;

      const member = {}, creates = [], deletes = [];

      for (const item of updates) {
        if (!item.id) {
          creates.push(item);
          continue;
        }
        if (item.role === null) {
          deletes.push(item.id);
          continue;
        }
        member[item.id] = item;
      }

      actions.members.create(...creates);
      actions.members.delete(...deletes);
      dispatch({ member });
    },
    delete: (...deletes) => {
      if (!deletes.length) return;

      const next = {};

      for (const id of deletes)
        setTo(next, ['member', id], { $delete: true });

      dispatch(next);
    },
  },
  groups: {
    create: ({ groups = [], members = [] }) => {
      const member = {};
      const group = {};

      for (const next of groups) {
        next.title = next.title.trim();
        const item = init({ data: {}, ...next });

        group[item.id] = item;
      }

      for (const next of members) {
        for (const groupId in group) {
          const item = init({ ...next, groupId });

          member[item.id] = item;
        }
      }

      dispatch({ member, group });

      return group;
    },
    update: (...updates) => {
      const group = {};

      for (const item of updates) {
        if (item.title)
          item.title = item.title.trim();

        if (item.id)
          group[item.id] = item;
      }
      // TODO: clear from order projects

      dispatch({ group });
    },
    relocate: ({ id: group, tasks = {} }) => {
      const prev = order, next = {}, diff = {}, actions = [];

      if (!group) return

      if (!isEqual(Object.keys(prev), Object.keys(tasks))) {
        const [_, ...norder] = Object.keys(tasks);

        actions.push(
          [['next', 'group', group], prev => mergeDeep(prev, set({ order: norder }))]
        )
      }

      for (const tag in tasks) {
        if (tag === DEFAULT_COLUMN) continue;

        next[tag] = {};

        const [difference, t] = differ(prev[tag], tasks[tag]);

        const order = [
          ...Object.values(difference.delete).reverse().map(id => ({ $pull: id })),
          ...Object.keys(difference.create).map(i => ({ $push: difference.create[i], $index: i }))
        ]

        if (order.length)
          setTo(next, [tag, 'tasks'], (prev = []) => [...prev, ...order]);

        mergeDeep(diff, { [tag]: t });
      }


      // ENTITIES
      const entities = {};

      for (const tag in diff) {
        for (const task in diff[tag]) {
          const isCreate = diff[tag][task];

          setTo(
            entities,
            [task, isCreate ? 'create' : 'delete'],
            (prev = []) => [...prev, tag]
          );
        }
      }

      for (const id in entities) {
        const { delete: deleted = [], create = [] } = entities[id];
        const items = [];

        for (const prev of deleted) {
          const next = create.shift();
          items.push(Object.assign( { $find: { group, id: prev } }, next ? { $body: { id: next } } : { $delete: true }));
        }

        for (const next of create)
          items.push({ $push: { group, id: next } });

        actions.push(
          [['next', 'entity', id, 'entities'], prev => mergeDeep(prev, items)]
        )
      }

      if (Object.keys(next).length)
        actions.push(
          [['next', 'group', group, 'data'], prev => mergeDeep(prev, next)]
        )

      console.log('actions', actions);

      // TODO: заменить на обычный диспатч
      store.dispatch(setItems(...actions));
    },
    delete: (...ids) => {
      const group = {}, member = {}, entity = {}, project = {};

      for (const id of ids) {
        group[id] = { $delete: true };

        member[JSON.stringify({ groupId: id })] = { $delete: true };

        project[JSON.stringify({ groups: { $contains: [id]  } })] = { groups: { $pull: id } };

        const req = JSON.stringify({ entities: { $contains: [{ group: id }] } });
        entity[req] = { entities: [{ $find: { group: id }, $delete: true }] }
      }

      dispatch({ group, member, entity, project });
    }
  },
  entities: {
    create: (...create) => {
      const entity = {};

      for (const next of create) {
        if (!next?.text.some(item => typeof item === 'string' && (item.replace(/\s/g, '')).length)) continue;

        for (const item of next.entities) {
          const { type = 'tag', id, group } = item;

          if (group && type === 'tag' && !isUuid(id)) {
            const tag = actions.tags.create(group, JSON.parse(id));

            item.id = tag.id;
          }
        }

        if (!next.type) next.type = 'task';

        const item = init(next);

        entity[item.id] = item;
      }

      dispatch({ entity });

      return entity;
    },
    update: (...updates) => {
      const entity = {};

      for (const next of updates) {
        const { id, text, entities } = next;
        if (!text.some(item => typeof item === 'string')) continue;

        for (const { type = 'tag', id, group } of next.entities) {
          if (group && type === 'tag' && !isUuid(id)) {
            actions.tags.create(group, JSON.parse(id))
          }
        }

        entity[next.id] = set(next);
      }

      // TODO: переписать на диспатч
      store.dispatch(setItem(['next'], p => mergeDeepReplace(p, { entity })));
    },
    delete: (...deletes) => {
      const next = {};

      for (const id of deletes)
        setTo(next, ['entity', id], { $delete: true });

      // TODO: delete messages
      console.log('delete', next);

      dispatch(next);
    },
  },
  users: {
    create: () => {},
    update: ({ id, title, clients, slug, phone }) => {
      const next = {};

      const args = { user: { slug, phone, clients }, tag: { title } };

      for (const name in args)
        for (const key in args[name])
          if (args[name][key])
            setTo(next, ['user', id, key], args[name][key]);

      dispatch(next);
    }
  },
  projects: {
    create: ({ id = genId(), title, members }) => {
      actions.members.create(
        ...members.map(item => ({ ...item, tagId: id }))
      );

      const next = {
        project: { [id]: init({ id }) },
        tag: { [id]: init({ id, type: groupType.project, title: title.trim() }) },
      };

      dispatch(next);
    },
    update: ({ id, groups, ...other }) => {
      const next = {};

      if (Object.keys(other).length > 0)
        setTo(next, ['tag', id], set(other));

      if (groups)
        setTo(next, ['project', id], set({ groups }));

      dispatch(next);
    },
    remove: id => {
      const userId = selectAuth(store.getState());

      const next = {
        member: {
          [JSON.stringify({ memberId: userId, tagId: id })]: { $delete: true },
        },
      };

      dispatch(next);
    },
    delete: id => {
      const next = {
        entity: {
          // TODO: мб удалять не все что связано с проектом
          [JSON.stringify({ entities: { $contains: [{ id }] } })]: { $delete: true },
        },
        member: {
          [JSON.stringify({ $or: [{ memberId: id }, { tagId: id }] })]: { $delete: true },
        },
        project: {
          [id]: { $delete: true },
        },
        tag: {
          [id]: { $delete: true }
        }
      };

      dispatch(next);
    },
  },
  invites: {
    create: ({ fromId, role, groupId, infinity }) => {
      const item = init({ fromId, role, groupId, infinity });

      const res = {
        invite: { [item.id]: item },
      }

      if (groupId) {
        Object.assign(res, {
          group: { [groupId]: { data: { [item.id]: { type: 'invite' } } } }
        })
      }

      copyToClipboard(window.location.origin + '/invite/' + item.id);
      sendMessage({ text: 'Приглашение скопировано' })

      dispatch(res);
    },
    update: ({ id, role }) => {
      if (role === null) return actions.invites.delete(id);

      const res = {
        invite: { [id]: { role } },
      }

      dispatch(res);
    },
    approve: ({ invite, to }) => {
      const next = {}, { id, infinity, fromId, role, groupId, createdBy } = invite;

      // TODO: костыль чтобы были права на инвайт
      setTo(next, ['invite', id], { id })

      if (!infinity)
        setTo(next, ['invite', id], { $delete: true })

      if (fromId) {
        const member = init({ memberId: to, role, tagId: fromId, createdBy });

        setTo(next, ['member', member.id], member)
      }

      if (groupId) {
        setTo(next, ['group', groupId, 'data'], { $rekeys: { [id]: to }, [to]: { type: null } })

        setTo(
          next,
          ['entity', JSON.stringify({ entities: { $contains: [{ group: groupId, id }] } }), 'entities'],
          [{ $find: { group: groupId, id }, $body: { group: groupId, id: to } }]
        );
      }

      dispatch(next);
    },
    delete: id => {
      const res = { invite: { [id]: { $delete: true } }, };

      const { groupId } = selectItem(['invite', id], {})(store.getState());
      // TODO: remove from group

      if (groupId) {
        Object.assign(res, {
          group: { [groupId]: { [id]: { $delete: true } } },
          entity: { [JSON.stringify({ entities: { $contains: [{ group: groupId, id }] } })]: {
              entities: [{ $find: { group: groupId, id }, $body: { group: null, id: null } }] }
          }
        })
      }

      dispatch(res);
    }
  }
}

// global.actions = actions;