import React from 'react';
import {selectAuth, selectTag} from "@/store/selectors";
import {v4 as genId} from "uuid";
import {useSelector} from "@/react-redux";
import styles from './Project.css';
import {Thumb} from "@/modules/Thumb/Thumb";
import {actions} from "@/hooks/actions";
import {store} from "@/store/client";
import {groupType} from "../../../types/group";
import {isEqual} from "@/helpers/dash";

export const Project = ({ id: idProps, onClick }) => {
  const id = idProps || genId();

  let { title, thumb = {  }, type } = useSelector(selectTag(null, id), isEqual, [idProps]);

  if (!idProps) {
    title = 'Новый проект';
    type = groupType.project;
  }

  return (
    <div data-project={idProps || 'new'} className={styles.root} onClick={() => {
      if (!idProps)
        actions.projects.create({
          id,
          title,
          members: [{ memberId: selectAuth(store.getState()), role: 'admin' }]
        });

      onClick({ type, id });
    }}>
      {idProps ? <Thumb thumb={thumb} type={type} className={styles.thumb} /> : <div className={styles.thumb} children='+' />}
      <div className={styles.title}>{title}</div>
    </div>
  );
}