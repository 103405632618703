import { useLocation, useNavigate, useParams } from 'react-router';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from '@/react-redux';
import { selectItem } from '@/store/app/getters';
import styles from './InvitePage.css';
import { HelmetContainer } from '@/modules/Helmet/HelmetContainer';
import { selectAuth, selectProjectsOrder } from '@/store/selectors';
import { groupType } from '../../../types/group';
import { actions } from '@/hooks/actions';
import { Menu } from '@/components/Actions/Actions';
import { Thumb } from '@/modules/Thumb/Thumb';
import {Projects} from '@/modules/Header/HeaderLeft';
import { TagContainer } from '@/components/Base/Tag';
import Close from '@/ui/icons/close.svg';
import { history } from '@/index';

export const InvitePage = () => {
  const userId = useSelector(selectAuth);

  const history = useNavigate();
  const location = useLocation();
  const { invite: id } = useParams();

  const invite = useSelector(selectItem(['invite', id]));

  console.log(id, invite);

  const onSubmit = to => {
    console.log('onSubmit');
    actions.invites.approve({ invite, to });
    setTimeout(() => {
      history(`/${invite.from.type}/${invite.from.id}`)
    }, 2000);
  }

  useEffect(() => {
    if (!userId) {
      history('/auth#' + location.pathname);
      return;
    }

    if (invite?.group?.type === groupType.user) onSubmit(userId);

  }, [userId, invite]);

  return (
    <div className={styles.wrapper}>
      <Close onClick={() => history('/')} />
      <div className={styles.root}>
        <HelmetContainer />
        <div className={styles.header}>
          <Thumb {...invite?.from} className={styles.preview} />
          <p className={styles.title}>{invite?.from?.title}</p>
          <p className={styles.descr}>Выберите проект, который будет приглашен</p>
        </div>
        <div className={styles.prjcts}>
          <Projects onClick={args => onSubmit(args.id)} />
        </div>
      </div>
    </div>
  );
}
