import React, {useEffect, useState} from "react";
import {Video} from "@/components/Player/Video";
import {mergeDeep} from "@/helpers/dash/mergeDeep";
import styles from './Link.css';
import Skeleton from "@/ui/Skeleton/Skeleton";

const render = {
  video: ({ url: src }) => <Video src={src} />,
  site: ({ url, title, description, image }) => (
    <a target='_blank' href={url} className={styles.view}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      {image && <img className={styles.image} src={image}/>}
    </a>
  ),
  image: ({ url }) => <img className={styles.view} src={url} />,
  loader: () => <Skeleton width='100%' height='3em'/>
}

const regImg = /<meta .*name="twitter:image" .*content="(?<image>\S+)"/
const regDesc = /<meta .*name="description" .*content="(?<description>[^"]+)"/
const regTitle = /<title.*>(?<title>.+)<\/title>/

const get = (body) => {
  return {
    image: body.match(regImg)?.groups?.image,
    description: body.match(regDesc)?.groups?.description,
    title: body.match(regTitle)?.groups?.title,
  }
}

export const Link = ({ url, view = 'loader', onUpdate, ...other }) => {

  useEffect(() => {
    if (view !== 'loader') return;
    fetch(url)
      .then(async res => {
        const args = {};

        const type = res.headers.get('Content-Type');

        if (type.includes('video')) {
          args.view = 'video';
        }
        if (type.includes('image')) {
          args.view = 'image';
        }
        if (type.includes('html')) {
          args.view = 'site';
          mergeDeep(args, get(await res.text()))
        }

        onUpdate(args);
      })
  }, [url, view])

  return (
    <>
      <a target='_blank' className={styles.root}>{url}</a>
      {render[view]({ url, view, ...other })}
    </>
  )
}