import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {useBoard} from "@/modules/Board/BoardContext";
import {v4 as genId} from 'uuid';
import { groupType } from '../../../types/group';
import { DEFAULT_COLUMN } from '@/data/tags';
import { getRect, isSimilar } from '@/modules/BoardMap/helpers';
import {selectItem} from "@/store/app/getters";
import {themeMode} from "@/data/types";
import {theme, themePath} from "@/modules/Helmet/HelmetContainer";
import {useSelector} from "@/react-redux";
import styles from './BoardMap.css';
import {themeDef} from "@/modules/Theme/Theme";
import {isEqual} from "@/helpers/dash";
import {gen,} from "@/modules/BoardMap/genTheme";

const options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};

const lib = {};

export const toUUID = (coords) => coords.join('-').split('.').join('-');
export const fromUUID = (str) => {
  const [a,b,c,d] = str.split('-');

  return [parseFloat(`${a}.${b}`), parseFloat(`${c}.${d}`)]
}

const Mark = ({ ...other }) => {

  return (
    <Placemark
      {...other}

      options={{
        iconLayout: 'default#image',
        iconImageHref: `/placemark.svg`,
        iconImageSize: [18, 18],
        iconImageOffset: [-9, -9],
      }}
    />
  )
}

export const BoardMap = ({ id: root, theme, order, group }) => {
  const { YMap, YMapListener, YMapDefaultFeaturesLayer, YMapDefaultSchemeLayer, YMapMarker, YMapDefaultMarker } = lib;

  const ref = useRef(null);
  const { handleProject } = useBoard();
  const [current, setCurrent] = useState({ coords: null });

  delete order[DEFAULT_COLUMN];

  useEffect(() => {

    if (!current.coords) return;

    const a = Object.keys(order)
      .filter(item => (
        isSimilar([current.coords.latitude, current.coords.longitude], fromUUID(item), 50)
      ))

  }, [order, current])

  useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(next => setCurrent(prev => isEqual(prev, next) ? prev : next), () => {}, options);

    return () => {
      navigator.geolocation.clearWatch(watchID);
    }
  }, []);

  const onCreate = async (e, { coordinates: coords }) => {
    const handle = e?.entity?._props?.onClick;

    if (handle) {
      handle();
      return;
    }

    const data = {
      id: genId(),
      text: [
        '',
        '\n',
        null,
        null,
        null,
      ],
      // TODO: нужно поменять указатель на текущие теги
      entities: [
        { group: null, id: root },
        { group, id: toUUID(coords) }
      ]
    };

    handleProject({ task: data });
  }

  const location = useMemo(() => ({ center: [current.coords?.longitude, current.coords?.latitude], zoom: 15 }), [!current.coords]);

  const custom = useMemo(() => gen(theme.bg), [theme.bg]);

  if (!current.coords) return null;

  return (
    <YMap
      ref={(ymaps) => {ref.current = ymaps;}}
      location={location}
    >
      <YMapDefaultSchemeLayer customization={custom} />
      <YMapDefaultFeaturesLayer />
      <YMapListener layer='any' onClick={onCreate} />
      <YMapMarker
        color='yellow'
        coordinates={[current.coords.longitude, current.coords.latitude]}
      />
      {Object.keys(order).map(tag => order[tag].map(task => (
        <YMapDefaultMarker onClick={() => handleProject({ task: { id: task } })} coordinates={fromUUID(tag)} />
      )))}
    </YMap>
  )
}
export const BoardMapContainer = (props) => {
  const [loaded, setLoaded] = useState();
  const theme = useSelector(selectItem(themePath, themeDef[0]));

  useEffect(() => {
        Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.import('@yandex/ymaps3-markers@0.0.1'), ymaps3.ready])
          .then(([ymaps3React, markers]) => {
            const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);
            Object.assign(lib, reactify.module(ymaps3), reactify.module(markers))
            setLoaded(true);
          })
  }, []);

  if (!loaded) return null;

  return <BoardMap {...props} theme={theme} />
}