import { RefObject, EffectCallback } from 'react';
import { debounce } from '@/helpers/debounce';

const options = {
  root: null,
  threshold: 0,
};

const callbacksMap = new Map();
let intersectionObserver,
  resizeObserver = null;

if (
  typeof ResizeObserver !== 'undefined' &&
  typeof IntersectionObserver !== 'undefined'
) {
  resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((item) => {
      if (callbacksMap.get(item?.target)) callbacksMap.get(item.target)();
    });
  });

  intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((item) => {
      if (callbacksMap.get(item?.target)) callbacksMap.get(item.target)();
    });
  }, options);
}

export const observers: (
  detectSize: () => void,
  ref: RefObject<HTMLElement>
) => EffectCallback = (detectSize, ref) => () => {
  const curr = ref.current;
  if (!curr) return;

  const resizeEvent = debounce(300, detectSize);

  callbacksMap.set(curr, resizeEvent);
  resizeObserver.observe(curr);

  return () => {
    resizeObserver.unobserve(curr);
    callbacksMap.delete(curr);
  };
};

export const getMediaPath = (
  { width, id, type },
  cdnPath: string,
  max: number = null,
  sizes: Record<string, number> = {}
): string => {
  let sizeKey = 'src';

  if (typeof max === 'number' && typeof width === 'number') {
    const sorted = Object.keys(sizes)
      .sort((a, b) => sizes[a] - sizes[b])
      .map((key) => `${key}-${sizes[key]}`);

    for (const part of sorted) {
      const [key, size] = part.split('-');

      if (+size >= max && width >= +size) {
        sizeKey = key;
        break;
      }
    }
  }

  return `${cdnPath}/${id}/${sizeKey}.${['png', 'jpg', 'jpeg'].includes(type) ? 'webp' : type}`;
};