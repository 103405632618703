import React, {useState, useRef, useEffect} from 'react';
import {useWindow} from "@/helpers/hooks";
import {assign} from "@/helpers/assign";
import {isMobile} from "@/components/Actions/Actions";

/*
* Компонент для драг-н-дроп манипуляций
*
* placeholder - занимается логикой страта мува и конца
* Draggable
* */

const contains = (parent, target) => parent === target || parent?.contains?.(target);

const items = new Map();
const delay = isMobile ? 160: 0;

let timeout = null;
let target = null;

const placeholder = {
  state: [],
  init: {},
  rect: null,
  current: null,

  start: (x, y, props) => {
    placeholder.init = { x, y };

    timeout = setTimeout(() => {
      const [_, setState] = placeholder.state;
      placeholder.current = props;
      placeholder.rect = props.ref.current.getBoundingClientRect();
      placeholder.current.setIsDragging(true);
      // TODO: stop scroll

      setState({ x: 0, y: 0 });
    }, delay);
  },
  move: (x, y) => {
    const { init, state } = placeholder;

    if (!init.x) return;

    const [_, setState] = state;
    const delta = { x: x - init.x, y: y - init.y };

    if (Math.abs(delta.x) > 5 || Math.abs(delta.y) > 5) {
      if (timeout) {
        clearTimeout(timeout);
      }
    }

    if (placeholder.rect)
      setState(prev => ({ ...prev, ...delta }));
  },
  end: () => {
    const { state } = placeholder;
    const [_, setState] = state;

    setState({});
    placeholder.current?.setIsDragging?.(false);
    placeholder.init = {};
    placeholder.current = null;
    placeholder.rect = null;
    target = null;
  },
}

let last = null;

const move = (target) => {
  if (last === target) return;
  last = target;

  return true;
}

export function Draggable({ type, disable, data, isSame, onMove, onDrop, children, ...props }) {
  const [isDragging, setIsDragging] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      items.set(ref.current, { data, type });
    }
    return () => {
      items.delete(ref.current);
    }
  }, [data]);

  const handleMouseDown = (event) => {
    if (disable) return;
    if (event.button !== 0 || event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) return; // Only respond to left clicks
    placeholder.start(event.clientX, event.clientY, { ...props, type, ref, data, children, setIsDragging })
    event.preventDefault();
  };

  const handleTouchStart = (event) => {
    if (disable) return;
    if (event.touches.length !== 1) return; // Only single touch
    const touch = event.touches[0];

    placeholder.start(touch.clientX, touch.clientY, { ...props, type, ref, data, children, setIsDragging })
  };

  const over = () => {
    const from = items.get(placeholder.current?.ref?.current);
    const to = items.get(ref.current);

    if (onMove && from && to && from.type === to.type) {
      if (isSame(from.data, to.data)) return;

      target = to;
      onMove(from.data, to.data, type)
    }
  }

  const end = () => {
    if (isDragging) {
      const from = placeholder.current;
      const to = target;

      if (onDrop && to && from.type === to.type)
        onDrop(from.data, to.data, type);
      placeholder.end();
    }
  };

  useWindow({
    mouseup: end,
    touchend: end,
    mousemove: event => {
      if (contains(ref.current, event.target))
        if (move(ref.current))
          over();
    },
    touchmove: event => {
      if (event.touches.length === 1) {
        const touch = event.touches[0];
        const target = document.elementFromPoint(touch.clientX, touch.clientY);
        if (contains(ref.current, target))
          if (move(ref.current))
            over()
      }
    }
  }, [isDragging, onMove]);


  return (
    <div
      ref={ref}
      {...props}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      style={assign({ opacity: Number(!isDragging) }, props.style)}
      children={children}
    />
  );
}

export const Placeholder = () => {
  placeholder.state = useState();
  const [state] = placeholder.state;

  useWindow({
    scroll: (event) => {
      if (placeholder.current) {
        event.stopPropagation();
      }
    },
    touchmove: (event) => {
      if (placeholder.current)
        event.preventDefault()

      if (event.touches.length === 1) {
        const touch = event.touches[0];

        placeholder.move(touch.clientX, touch.clientY);
      }
    },
    mousemove: (event) => {
      placeholder.move(event.clientX, event.clientY);
    },
    touchend: () => {
      clearTimeout(timeout);
    },
    mouseup: () => {
      clearTimeout(timeout);
    }
  }, [], { settings: { passive: false } })

  if (!placeholder.rect) return null;

  return (
    <div
      className={placeholder.current.className}
      style={{
        position: 'fixed',
        touchAction: 'none',
        pointerEvents: 'none',
        width: placeholder.rect.width,
        top: placeholder.rect.y,
        left: placeholder.rect.x,
        transform: `translate(${state.x}px, ${state.y}px)`,
        transition: '0s all'
      }}
    >
      {placeholder.current.children}
    </div>
  );
}
