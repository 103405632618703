import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { DEFAULT_COLUMN } from '@/data/tags';
import { TagContainer } from '@/components/Base/Tag';
import { TagCreateContainer } from '@/components/Base/TagCreate';
import styles from './Board.css';
import { InviteContainer } from '@/components/Base/Invite';
import { TaskCard, Create } from '@/components/TaskCard';
import { actions } from '@/hooks/actions';
import { Drag } from '@/components/Dnd/Drag';
import classNames from 'classnames';
import { Virtualized, VirtualizedItems } from '@/lib/Virtualized';
import { ViewType } from '@/types';
import {isEqual, pickBy} from "@/helpers/dash";
import {assign} from "@/helpers/assign";
import {Draggable} from "@/components/Dnd/Dragg";
import {mergeDeep} from "@/helpers/mergeDeep";

const columnes = {
  tags: {
    [DEFAULT_COLUMN]: () => 'Неотсортировано',
    null: TagCreateContainer,
    _: TagContainer
  },
  invite: {
    _: InviteContainer
  },
}

const getOrder = ({ ...columns }) => {

  delete columns[DEFAULT_COLUMN];

  return [DEFAULT_COLUMN, ...Object.keys(columns), null]
}

interface IBoardProps {
  id: string;
  groupId: string;
  invites: string[];
  order: Record<string, string[]>;
}

const byType = {
  [ViewType.table]: (columns, height, render) => {
    const res = [];

    return res;
  },
  [ViewType.default]: (columns, heights, render) => {
    const prev = {}, w = 250, s = 20;

    return getOrder(columns).reduce((acc, tag, i) => {
      const x = (w + s) * i;
      let counter = {};

      prev[x] = 50;

      const props = { id: tag, type: `column` };
      const key = `column-${tag === null ? '-create' : tag}`;

      acc.push({ x, y: 'current', w, h: '100%', key, props, children: render.column(props) });

      if (tag !== null)
        [...(columns[tag] || []), null].forEach((id, index) => {
          let y = prev[x], h = (heights[id] || 50) + s;
          prev[x] += h;

          const props = { id, tag, index, type: 'task' };
          const key = `task-${id || tag}`;

          acc.push({ x, y, w, h, key, ymax: !id, style: { height: id ? heights[id] : '100%' }, props, children: render.task(props) })
        })

      return acc;
    }, [])
  },
  [ViewType.list]: (columns, heights, render) => {
    const w = '100%', s = 20, x = 'current';
    let counter = {};
    let y = 0;

    console.log('columns', columns)

    return getOrder(columns).reduce((acc, tag) => {

      const props = { id: tag, type: `column` };
      const key = `column-${tag === null ? '-create' : tag}`;

      const column = { x, y, w, h: 50, props, key, children: render.column(props) };

      acc.push(column)

      y += 50;

      if (tag !== null)
        [...(columns[tag] || []), null].forEach((id, index) => {
          const h = (heights[id] || 50) + s;

          const props = { id, tag, index, type: 'task' };

          const key = `task-${id || tag}`;
          console.log('key', id)

          acc.push({ x, y, w, h, key, props, children: render.task(props) })

          y += h;
        })

      column.h += y + 50 + s;

      return acc;
    }, [])
  }
}

const move = {
  tags: (from, to, prev) => {
      const order = Object.keys(prev);

      const i1 = order.findIndex(i => i === from.id);
      const i2 = order.findIndex(i => i === to.id);

      order.splice(i1, 0, ...order.splice(i2, 1));

      return order.reduce((acc, key) => {
        acc[key] = prev[key];
        return acc;
      }, {});
  },
  task: (from, to, prev) => {
    if (!from.id || from.id === to.id) return prev;

    const { ...next } = prev;

    next[from.tag] = (next[from.tag] || []).filter(i => i !== from.id);

    next[to.tag] = assign([], next[to.tag]);

    next[to.tag].splice(to.index, 0, from.id);

    return next;
  },
}

const sizer = ({ height, ...other }) => ({ height: `calc(${height}px + 1.2 * var(--header-h))`, ...other });

export const Board: React.FC<IBoardProps> = (props) => {
  const { id: project, view, group, invites = {}, top, order: orderProps, onSize, heights, ...other } = props;

  const [order, setOrder] = useState(mergeDeep({}, orderProps));

  useEffect(() => { setOrder(mergeDeep({}, orderProps)); }, [orderProps]);

  const onMove = (from, to, type) => {
    setOrder(prev => move[type](from, to, prev));
  }
  const onDrop = (from, to, type) => {
    const prev = orderProps;
    const next = move[type](from, to, prev);

    actions.groups.relocate({ id: group, tasks: next });
  };

  const isSame = (f, t) => f.id === t.id && f.type === t.type;

  const render = {
    task: ({ id, tag }) => (
      id
        ? <TaskCard id={id} onSize={onSize} />
        : <Create tags={[tag !== DEFAULT_COLUMN && { group, id: tag }].filter(Boolean)} />
    ),
    column: ({ id }) => {
      const base = columnes[id in invites ? 'invite' : 'tags'];
      const Component = base[id] || base._;
      return <Component className={styles.tag} id={id} group={group} />
    }
  }

  const columns = order;

  const items = byType[view](columns, heights, render).sort((a, b) => a.key > b.key ? -1 : 1);

  const children = ({ props: data, key, children, style }, i) => {
    let { type, id, tag } = data;

    const className = classNames(styles.drag, styles[type], styles[`view-${view}-${type}${id ? '' : '-create'}`])
    const disable = !id;

    if (type === 'column')
      type = id in invites ? 'invite' : 'tags';

    if (type === 'task' && id && !heights[id])
      style.opacity = 0;

    const args = { key, data, className, isSame, style, children, disable, type, onMove, onDrop };

    return <Draggable {...args} />
  }

  const virtItems = ({ w, h,...other }) => (
    <>
      <div className={styles.items} data-name="columns">
        <VirtualizedItems w={w} h={h} {...other} items={items} children={children}/>
      </div>
    </>
  );

  const a = pickBy(props, ['width', 'height']);

  return (
    <Virtualized
      sizer={sizer}
      className={classNames(styles.container, styles[`container-${view}`])}
      {...a}
      {...other}
      children={virtItems}
    />
  )
}