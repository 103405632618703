import {isEqual} from "@/helpers/dash";
import React, {useContext, useEffect, useState} from "react";
import {debounce} from "@/helpers/debounce";
import actions from './actions';
import {mergeDeep} from "@/helpers/mergeDeep";

const yieldToMain = () => (
  new Promise(resolve => {
    setTimeout(resolve, 0);
  })
)

const defEq = (prev, next) => prev === next;

export const genStore = (def = {}) => {
  const store = { state: def }, subscribers = new Set();

  const revalidate = debounce(10, async () => {

    for (let listner of subscribers) {
        await yieldToMain()
        listner()
    }
  });

  return {
    dispatch: async (action) => {
      store.state = action(store.state);
      revalidate();
    },
    subscribe: (listner) => {
      subscribers.add(listner);
    },
    getState: () => store.state,
    selector: (handle) => {
      subscribers.add(handle)
      return () => {
        subscribers.delete(handle)
      }
    },
    revalidate,
    actions,
  }
}

const Context = React.createContext({});

export const Provider = ({ store, children }) => <Context.Provider value={store} children={children} />

export const shallowEqual = (prev, next) => isEqual(prev, next);
export const useDispatch = () => {
  const { dispatch } = useContext(Context);

  return dispatch;
}
export const useSelector = (selector, eq = defEq, defs, log?) => {
  const { selector: effect, getState } = useContext(Context);
  const state = useState(selector(getState()));

  useEffect(() => {
    const set = () => state[1](prev => {
      const next = selector(getState());

      if (log)
        log(prev, next);

      return eq(prev, next) ? prev : next;
    })

    set();

    return effect(set);
  }, defs || [selector])

  return state[0];
}

export const compose = (...fns) => (arg) => fns.reduceRight((composed, f) => f(composed), arg)

export default genStore;