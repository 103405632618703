import { actions } from '@/hooks/actions';
import { pathStore, store } from '@/store/client';
import { selectUser, selectUserData } from '@/store/selectors';
import { useEffect, useState } from 'react';
import {isEqual} from "@/helpers/dash";

export const triggerSubscribe = async (): any => {
  const pushKey = pathStore(['pushKey']);

  console.log('state', pushKey);

  if (!pushKey) {
    return;
  }

  if (Notification.permission !== 'granted') {
    const req = await Notification.requestPermission();

    if (req === 'denied') {
      return;
    }
  }
  try {
    const sw = await navigator.serviceWorker.ready;

    const key = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: pushKey,
    });

    return key;
  } catch (e) {
  }
}
let func = () => {};

window.addEventListener("load", func);

export const useSubscribe = (items) => {
  let [state, setState] = useState(null);

  useEffect(() => {
    func = () => {
      if (!("Notification" in window)) return;

      if (Notification.permission !== 'granted') return;

      triggerSubscribe()
        .then(item => setState(item?.toJSON()))
    };
    func();
  }, []);

  const isSubscribed = items?.some(({ userAgent, ...item }) => isEqual(item, state));

  const onSubscribe = async () => {
    const user = selectUserData(store.getState());

    if (Notification.permission === 'denied') {
      alert('У вас в настройках запрещены уведомления, разрешите их');
      return;
    }

    if (!state) {
      state = await triggerSubscribe().then(item => item?.toJSON());
      setState(state);
    }

    if (isSubscribed) {
      actions.users.update({
        id: user.id,
        clients: [{ $pull: { endpoint: state.endpoint } }]
      })
      return;
    }

    if (state) {
      actions.users.update({
        id: user.id,
        clients: [{ $push: { ...state, userAgent: navigator.userAgent }}]
      })
    }
  }

  return [isSubscribed, onSubscribe];
}
