import {blocks, getStateByStep} from "@/pages/MainPage/data";
import {store} from "@/store/client";
import {setItem} from "@/store/app/actions";
import React, {useEffect, useRef, useState} from "react";

export const param = (from, to, val, k = 1) => (Math.max(from, Math.min(to, val)) - from) / (to - from) * k

export const Parallax = ({ className, style, children = () => null }) => {
  const ref = useRef();
  const [step, setStep] = useState([]);

  useEffect(() => {

    const handle = () => {
      const { top, height } = ref.current.getBoundingClientRect();

      setStep([Math.min(-1 * Math.min(0, top), height), height]);
    };

    handle();

    window.addEventListener('scroll', handle);

    return () => {
      window.removeEventListener('scroll', handle);
    }
  }, []);

  return <div ref={ref} style={style} className={className}>{children(...step)}</div>;
}