import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Button.css';

export enum mode {
  link = 'link',
  btn = 'btn',
  outline = 'outline',
}

export enum size {
  big = 'big',
  small = 'small',
}

export interface IButton {
  to?: any;
  mode?: mode;
  size?: size;
  onClick?(): void;
  isFull?: boolean;
}

export const Button: React.FC<IButton> = memo(props => {
  const { to, mode, size, color, isFull, className, noPadding, disabled, ...other} = props;

  const Tag = to ? Link : 'button';

  const cn = classNames(
    styles[mode],
    styles[size],
    noPadding && styles.noPadding,
    isFull && styles.full,
    disabled && styles.disabled,
    className,
  );

  return <Tag to={to} className={cn} disabled={disabled} {...other} />;
})

Button.defaultProps = {
  mode: mode.btn,
}
