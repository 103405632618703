import { format, fromUnixTime, getTime } from 'date-fns';
import { ru } from 'date-fns/locale';

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
}

export const dateToUnix = date => getTime(date) / 1000;

export const formatDate = date => {
  try {
    return format(date, 'd MMM YYY HH:mm', { locale: ru })
  } catch (e) {
    console.error(e);
    return 'Ошибка даты'
  }
};

export const formatDateOf = date => {

  const time = format(date, 'd MMM в HH:mm', { locale: ru });

  return time;
}

const times = [30 * 24 * 60, 24 * 60, 60];
const names = ['мес', 'д', 'ч', 'мин'];

export const formatDuration = time => {
  let res = '', i = 0, minutes = time;

  for (i = 0; i < times.length; i++) {
    const d = times[i];

    if (minutes / d >= 1) {
      res += Math.floor(minutes / d) + ' ' + names[i] + ' ';
      minutes %= d;
    }
  }

  if (minutes !== 0)
    res += minutes + ' ' + names[i];

  return res;
}
